<template>
  <v-card tile flat>
    <v-card-text class="pl-0">
      <v-alert type="success" v-text="message" class="p-0" />
    </v-card-text>
    <v-card-actions>
      <v-btn text :to="{ name: 'home' }">Return to home page</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      required: true
    }
  },
  computed: {
    message() {
      if (this.code === "confirm") {
        return "Thank you for registering! An email has been sent with a link to confirm your email. Click the confirmation link to set your password and complete your account.";
      } else if (this.code === "complete") {
        return "Your new account has been successfully completed. Please use your email and password to login.";
      } else {
        return "";
      }
    }
  },
  mounted() {
    if (!this.code) {
      this.$router.push({
        name: "home"
      });
    }
  }
};
</script>

<style scoped></style>
