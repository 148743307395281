<template>
  <div>
    <v-toolbar color="primary d-print-none" dark flat dense>
      <v-toolbar-title>Print Application</v-toolbar-title>
      <v-spacer />
      <v-btn-toggle group>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              exact
              :to="{ name: 'applicationEdit', params: { id: service.id } }"
              v-if="service.can_edit"
              v-on="on"
            >
              <v-icon small>$vuetify.icons.edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Application</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="print" v-on="on">
              <v-icon small>$vuetify.icons.print</v-icon>
            </v-btn>
          </template>
          <span>Print Application</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-toolbar>
    <v-card outlined tile>
      <Identity :disabled="true" :show-title="true" />
      <Format :disabled="true" :show-title="true" ref="format" />
      <Type :disabled="true" :show-title="true" ref="type" />
      <Staff :disabled="true" :show-title="true" ref="staff" />
      <Relationships :disabled="true" :show-title="true" ref="relationships" />
      <Promotion :disabled="true" :show-title="true" ref="promotion" />
      <Uploads :disabled="true" :show-title="true" ref="uploads" />
      <Submit :disabled="true" :show-title="true" ref="submit" />
    </v-card>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-scroll="onScroll"
          v-show="showPrintPopup"
          class="d-print-none"
          fab
          fixed
          bottom
          right
          color="primary"
          @click="print"
          v-on="on"
        >
          <v-icon>$vuetify.icons.print</v-icon>
        </v-btn>
      </template>
      <span>Print Application</span>
    </v-tooltip>
  </div>
</template>

<script>
import Identity from "./Identity.vue";
import Format from "./Format.vue";
import Type from "./Type.vue";
import Staff from "./Staff.vue";
import Relationships from "./Relationships.vue";
import Promotion from "./Promotion.vue";
import Uploads from "./Uploads.vue";
import Submit from "./Submit.vue";
import { mapState } from "vuex";

export default {
  name: "Print",
  components: {
    Identity,
    Format,
    Type,
    Staff,
    Relationships,
    Promotion,
    Uploads,
    Submit
  },
  data() {
    return {
      showPrintPopup: false
    };
  },
  computed: {
    ...mapState("service", ["service"])
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.showPrintPopup = top > 220;
    },
    print() {
      window.print();
    }
  },
  mounted() {
    this.$refs.format.initialize();
    this.$refs.type.initialize();
    this.$refs.staff.initialize();
    this.$refs.relationships.initialize();
    this.$refs.promotion.initialize();
    this.$refs.uploads.initialize();
    this.$refs.submit.initialize();
  }
};
</script>
