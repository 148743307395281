<template>
  <v-list-item>
    <v-list-item-content v-if="isEditable">
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row dense>
          <v-col cols="12" v-if="showCheatsheet">
            <v-select
              label="Cheat Sheet"
              v-model="selectedCheatsheet"
              :items="cheatsheets"
              item-value="text"
              item-text="title"
              clearable
              @change="appendCheatsheet"
            />
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              label="Comment"
              v-model="item.comment"
              :counter="1000"
              :outlined="false"
              :rows="1"
            />
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="item.conversation_files"
              small-chips
              multiple
              label="Upload Files"
              :accept="acceptTypes"
              :rules="conversationFilesValid"
              hint="Valid file types include (5MB max): PDF, Images, MS Office Suite, Apple iWork Suite"
            />
          </v-col>
          <v-col cols="12">
            <v-chip
              v-for="(file, i) in item.conversation_files_uploaded"
              :key="i"
              small
              class="mr-2 mb-2"
              :href="file.file_url"
              target="_blank"
              close
              @click:close="
                $emit('on-delete-file', {
                  conversationId: item.id,
                  fileId: file.id
                })
              "
              >{{ file.file_name }}</v-chip
            >
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" @click="save">Save</v-btn>
            <v-btn color="default" @click="cancel">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-list-item-content>
    <v-list-item-content v-else>
      <v-list-item-title v-html="itemTitle"></v-list-item-title>
      <v-list-item-subtitle v-text="item.comment"></v-list-item-subtitle>
      <v-list-item-subtitle style="line-height: 1.8;">
        <span
          v-for="file in item.conversation_files_uploaded"
          :key="file.id"
          class="pr-2"
        >
          <v-chip small :href="file.file_url" target="_blank">{{
            file.file_name
          }}</v-chip>
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn-toggle v-show="!isEditable">
        <v-btn fab x-small @click="$emit('on-reply')" v-if="item.can_reply">
          <v-icon color="success">$vuetify.icons.reply</v-icon>
        </v-btn>
        <v-btn x-small fab @click="edit = true" v-if="item.can_edit">
          <v-icon color="primary">$vuetify.icons.edit</v-icon>
        </v-btn>
        <v-btn
          x-small
          fab
          @click="$emit('on-delete', item)"
          v-if="item.can_delete"
        >
          <v-icon color="error">$vuetify.icons.delete</v-icon>
        </v-btn>
        <v-btn
          x-small
          fab
          @click="$emit('on-close', item)"
          v-if="item.can_close"
        >
          <v-icon color="secondary">$vuetify.icons.close</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import TextAreaInput from "../form/TextAreaInput.vue";
import { acceptTypes } from "../../utils/helpers";

export default {
  components: { TextAreaInput },
  props: {
    value: {
      type: Object,
      required: true
    },
    formId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedCheatsheet: "",
      edit: false,
      isFormValid: true,
      acceptTypes: acceptTypes
    };
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    isEditable() {
      return !this.item.id || this.edit;
    },
    cheatsheets() {
      return this.$store.getters["admin/formCheatsheets"](this.formId);
    },
    showCheatsheet() {
      return !this.item.service_conversation_id;
    },
    itemTitle() {
      let subtext = `<small class="grey--text text--lighten-1 ml-2">${this.item.comment_date}`;

      if (this.item.closed) {
        subtext += " (Closed)";
      }

      subtext += "</small>";
      return this.item.user + subtext;
    },
    conversationFilesValid() {
      let sizeValid = true;
      this.item.conversation_files.forEach(file => {
        if (file.size > 5000000) {
          sizeValid = false;
        }
      });
      return [
        this.item.conversation_files.length <= 10 ||
          "A maximum of 10 files may be uploaded",
        sizeValid || "Files may not exceed 5MB in size"
      ];
    }
  },
  methods: {
    cancel() {
      if (!this.item.id) {
        this.$emit("on-delete", this.item);
      } else {
        this.$refs.form.resetValidation();
        this.item = this.value;
        this.edit = false;
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("on-save", this.item);
        this.edit = false;
      }
    },
    appendCheatsheet() {
      if (this.selectedCheatsheet) {
        if (this.item.comment.trim().length > 0) {
          this.item.comment += "\n";
        }
        this.item.comment += this.selectedCheatsheet;
      }
    }
  }
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  white-space: normal;
}
</style>
