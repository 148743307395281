<template>
  <div>
    <ApprovedList v-if="loggedIn && (isMember || isStaffNoAccount)" />
    <DefaultHome v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultHome from "../components/DefaultHome.vue";
import ApprovedList from "../components/ApprovedList.vue";

export default {
  name: "Home",
  components: { DefaultHome, ApprovedList },
  computed: {
    successMessage() {
      return this.$route.params.successMessage;
    },
    ...mapGetters("user", ["loggedIn", "isMember", "isStaffNoAccount"]),
  },
  mounted() {
    if (this.successMessage) {
      this.$notify.success(this.successMessage);
    }
  },
};
</script>
