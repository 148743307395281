<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-icon class="pr-2">$vuetify.icons.email</v-icon>
      <v-toolbar-title>Inbox</v-toolbar-title>
      <v-spacer />
      <v-btn color="success" small dark fab @click.stop="openMessageDialog()">
        <v-icon>$vuetify.icons.plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      class="inbox_table"
      :headers="headers"
      :items="messages"
      :loading="inboxLoading"
      :loading-text="loadingText"
      sort-by="message_date"
      sort-desc
      hide-default-header
      :search="search"
    >
      <template v-slot:top>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              label="Search"
              hide-details
              append-icon="fa-search"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <em>Inbox is empty!</em>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <span
              :class="{ 'font-weight-bold': unread(item) }"
              v-text="fromText(item)"
            ></span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip
                  v-if="item.replies.length > 0"
                  small
                  color="grey"
                  text-color="white"
                  class="ml-2"
                  v-text="item.replies.length"
                  v-on="on"
                >
                </v-chip>
              </template>
              <span>{{ item.replies.length }} replies</span>
            </v-tooltip>
          </td>
          <td class="inbox-text" style="width: 60%;">
            <span
              :class="{ 'font-weight-bold': unread(item) }"
              v-text="item.subject"
            ></span>
            <span class=""> - {{ item.text }}</span>
          </td>
          <td class="text-end">
            <span
              :class="{ 'font-weight-bold': unread(item) }"
              v-text="item.message_date"
            ></span>
          </td>
          <td class="text-end" style="width: 5%">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  color="primary"
                  icon
                  v-on="on"
                  @click="openMessageDialog(item)"
                >
                  <v-icon small>$vuetify.icons.emailOpen</v-icon>
                </v-btn>
              </template>
              <span>Open Message</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <InboxMessageDialog v-model="messageDialog" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { INBOX_STAFF_ID, loadingTextForTable } from "../utils/helpers";
import InboxMessageDialog from "../components/InboxMessageDialog.vue";

export default {
  components: { InboxMessageDialog },
  data() {
    return {
      search: "",
      loadingText: loadingTextForTable,
      messageDialog: false,
      message: this.defaultMessage,
      defaultMessage: {
        to_id: null,
        subject: "",
        text: "",
        read: false
      },
      headers: [
        {
          text: "Name",
          align: "start",
          value: "from_user"
        },
        { text: "Subject", value: "subject_text" },
        { text: "Date", value: "message_date", align: "end" },
        {
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("inbox", ["messages", "inboxLoading"]),
    ...mapGetters("user", ["isAdmin"])
  },
  methods: {
    ...mapActions("inbox", ["fetchMessages", "setMessage", "readMessage"]),
    openMessageDialog(item) {
      if (item) {
        this.setMessage({ ...item });
        this.readMessage();
      } else {
        this.setMessage({ ...this.defaultMessage });
      }

      this.messageDialog = true;
    },
    close() {
      this.message = { ...this.defaultMessage };
      this.messageDialog = false;
    },
    fromText(item) {
      if (item.from_id === this.user.id || (this.isAdmin && item.staff)) {
        return `To:${item.to_user}`;
      } else {
        return item.from_user;
      }
    },
    unread(item) {
      const queryTo = this.isAdmin ? INBOX_STAFF_ID : this.user.id;
      if (!item.read && item.to_id === queryTo) {
        return true;
      } else if (item.replies.length > 0) {
        const unreadReplies = item.replies.filter(
          reply => !reply.read && reply.to_id === queryTo
        );

        return unreadReplies.length > 0;
      } else {
        return false;
      }
    }
  },
  async mounted() {
    await this.fetchMessages();
  }
};
</script>

<style>
.inbox-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inbox_table table {
  table-layout: fixed;
}
</style>
