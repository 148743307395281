import httpClient from "./httpClient";

const END_POINT = "/admin/status_emails";

const getStatusEmails = () => httpClient.get(END_POINT);
const createStatusEmail = data => {
  return httpClient.post(`${END_POINT}`, data);
};

const updateStatusEmail = data => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.put(`${END_POINT}/${id}`, form);
};

const deleteStatusEmail = id => {
  return httpClient.delete(`${END_POINT}/${id}`);
};

export {
  getStatusEmails,
  createStatusEmail,
  updateStatusEmail,
  deleteStatusEmail
};
