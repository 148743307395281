<template>
  <v-alert
    v-if="isImpersonating"
    dark
    dense
    icon="fa-user-secret"
    tile
    type="warning"
  >
    Impersonating a Servicer
    <template v-slot:append>
      <v-btn
        :x-small="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.mdAndUp"
        outlined
        :to="{ name: 'logout' }"
      >
        Quit
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Impersonate",
  computed: {
    ...mapGetters("user", ["isImpersonating"])
  }
};
</script>
