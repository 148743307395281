import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import Urls from "../urls";
import store from "../store/store";
import { printPageName } from "../utils/helpers";

import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import NotFound from "../views/errors/NotFound.vue";
import NetworkIssue from "../views/errors/NetworkIssue.vue";
import Forbidden from "../views/errors/Forbidden.vue";
import Application from "../views/Application.vue";
import Years from "../views/admin/Years.vue";
import ServicersList from "../views/ServicersList.vue";
import Statuses from "../views/admin/Statuses.vue";
import Cheatsheets from "../views/admin/Cheatsheets.vue";
import Inbox from "../views/Inbox.vue";
import ApprovedListView from "../views/ApprovedListView.vue";
import Attestations from "../views/admin/Attestations.vue";
import RegConfirm from "../views/RegConfirm.vue";
import RegComplete from "../views/RegComplete.vue";
import Print from "../components/service/Print.vue";
import StatusEmails from "../views/admin/StatusEmails.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.routerBase,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      // fake route that just redirects to server
      path: "/login",
      name: "login",
      beforeEnter: (to, from, next) => {
        location.assign(Urls.login);
      }
    },
    {
      // fake route that just redirects to server
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        location.assign(Urls.logout);
      }
    },
    {
      // fake route that just redirects to server
      path: "/imitate_login",
      name: "imitateLogin",
      props: true,
      beforeEnter: (to, from, next) => {
        location.assign(Urls.imitateLoginUrl(to.params.email));
      }
    },
    {
      path: "/confirm",
      name: "confirm",
      props: true,
      component: RegConfirm
    },
    {
      path: "/complete",
      name: "complete",
      props: true,
      component: RegComplete
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/profile",
      name: "profile",
      component: Register,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.state.user.user.servicer_id) {
          store
            .dispatch(
              "servicer/fetchServicer",
              store.state.user.user.servicer_id
            )
            .then(servicer => {
              to.params.servicer = servicer;
              next();
            })
            .catch(() => next());
        } else {
          next();
        }
      }
    },
    {
      path: "/applications",
      name: "applications",
      component: () => {
        if (store.state.user.user.is_staff) {
          return import("../views/ApplicationListAdmin.vue");
        } else {
          return import("../views/ApplicationList.vue");
        }
      },
      meta: { requiresAuth: true }
    },
    {
      path: "/servicers",
      name: "servicers",
      component: ServicersList,
      meta: { requiresAuth: true }
    },
    {
      path: "/approved",
      name: "approved",
      component: ApprovedListView,
      meta: { requiresAuth: true }
    },
    {
      path: "/applications/new",
      name: "applicationNew",
      component: Application,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        store
          .dispatch("service/resetService")
          .then(() => {
            next();
          })
          .catch(() => next());
      }
    },
    {
      path: "/applications/:id",
      name: "applicationEdit",
      component: Application,
      meta: { requiresAuth: true },
      props: true,
      beforeEnter: (to, from, next) => {
        store
          .dispatch("service/fetchService", to.params.id)
          .then(() => {
            next();
          })
          .catch(() => next());
      }
    },
    {
      path: "/applications/:id/print",
      name: printPageName,
      component: Print,
      meta: { requiresAuth: true },
      props: true,
      beforeEnter: (to, from, next) => {
        store
          .dispatch("service/fetchService", to.params.id)
          .then(() => {
            next();
          })
          .catch(() => next());
      }
    },
    {
      path: "/servicers/:id",
      name: "servicerEdit",
      component: Register,
      props: true,
      beforeEnter: (to, from, next) => {
        store
          .dispatch("servicer/fetchServicer", to.params.id)
          .then(servicer => {
            to.params.servicer = servicer;
            next();
          })
          .catch(() => next());
      }
    },
    {
      path: "/inbox",
      name: "inbox",
      component: Inbox,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/years",
      name: "years",
      component: Years,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/statuses",
      name: "statuses",
      component: Statuses,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/cheatsheets",
      name: "cheatsheets",
      component: Cheatsheets,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/attestations",
      name: "attestations",
      component: Attestations,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/statusEmails",
      name: "statusEmails",
      component: StatusEmails,
      meta: { requiresAuth: true }
    },
    {
      path: "/network-issue",
      name: "network-issue",
      component: NetworkIssue
    },
    {
      path: "/404",
      name: "404",
      component: NotFound,
      props: true
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: Forbidden
    },
    {
      path: "*",
      redirect: { name: "404", params: { resource: "page" } }
    }
  ]
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  const loggedIn = sessionStorage.getItem("user");

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next({ name: "home" });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
