const emailRegex = RegExp(
  /[A-Za-z0-9._%+-]{1,64}@(?:[A-Za-z0-9-]{2,63}\.){1,8}[A-Za-z]{2,63}$/
);

export const required = v =>
  (!!v && typeof v === "string" && v.trim().length > 0) ||
  (!!v && typeof v !== "string") ||
  "Required";
export const selectOne = v => v.length > 0 || "Please select at least one";
export const email = v => emailRegex.test(v) || "Invalid email format";
export const zeroOrGreater = v =>
  parseFloat(v) >= 0 || "Must be greater than or equal to 0";
export const year = v =>
  (!!v && typeof v === "string" && v.trim().length === 4) ||
  (!!v && typeof v === "number" && v <= 9999) ||
  "Please enter a valid 4-digit year";
