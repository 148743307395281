import httpClient from "./httpClient";
import { convertArraysToStrings } from "../utils/helpers";

const END_POINT = "/services";
const TYPE_END_POINT = "type";

const getServiceType = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${TYPE_END_POINT}`);

const updateServiceType = data => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${TYPE_END_POINT}`,
    prepareFormData(form),
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const createServiceType = data => {
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${TYPE_END_POINT}`,
    prepareFormData(data),
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const deleteServiceTypeFile = (serviceId, fileId) => {
  return httpClient.delete(
    `${END_POINT}/${serviceId}/${TYPE_END_POINT}?attachment_id=${fileId}`
  );
};

function prepareFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    const keyName = `service_type[${key}]`;
    if (
      data[key] !== null &&
      (key === "geo_countries" || key === "geo_states")
    ) {
      formData.append(keyName, convertArraysToStrings(data[key]));
    } else if (
      data[key] !== null &&
      (key === "contract_files" || key === "contract_conversation_files")
    ) {
      for (let i = 0; i < data[key].length; i++) {
        const file = data[key][i];

        formData.append(keyName + "[]", file);
      }
    } else if (
      data[key] !== null &&
      (key === "price_sheet_files" || key === "price_conversation_files")
    ) {
      for (let i = 0; i < data[key].length; i++) {
        const file = data[key][i];

        formData.append(keyName + "[]", file);
      }
    } else if (
      data[key] !== null &&
      key === "service_price_packages_attributes"
    ) {
      const packages = data[key];
      packages.forEach(item => {
        for (const itemKey in item) {
          formData.append(keyName + "[][" + itemKey + "]", item[itemKey]);
        }
      });
    } else if (data[key] !== null && key !== "contract_files_uploaded") {
      formData.append(keyName, data[key]);
    } else if (data[key] !== null && key !== "price_sheet_files_uploaded") {
      formData.append(keyName, data[key]);
    } else if (data[key] === null) {
      formData.append(keyName, "");
    }
  }

  return formData;
}

export {
  getServiceType,
  updateServiceType,
  createServiceType,
  deleteServiceTypeFile
};
