<template>
  <v-overlay :value="loading" :z-index="1000">
    <v-progress-circular indeterminate size="50" />
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Loading",
  computed: {
    ...mapGetters(["loading"])
  }
};
</script>
