<template>
  <div>
    <v-form
      ref="form"
      v-model="isFormValid"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-card class="mx-auto mb-2" tile flat outlined width="400px">
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title>Create Password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert type="info">
            Thank you for confirming your email address. Please create a
            password to complete the registration.
          </v-alert>
          <v-text-field disabled label="Email" v-model="email" />
          <v-text-field
            label="Password"
            type="password"
            v-model="formData.password"
            :rules="passwordRules"
          >
            <template v-slot:append>
              <Tooltip
                text="Password must be at least 8 characters long and contain at least 1 lowercase, 1 uppercase, 1 numeric, and 1 special character"
              />
            </template>
          </v-text-field>
          <v-text-field
            label="Confirm Password"
            type="password"
            v-model="formData.confirm_password"
            :rules="[rules.required, passwordsMustMatchRule]"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :to="{ name: 'home' }">
            Cancel
          </v-btn>
          <v-btn color="primary" type="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { required } from "../utils/rules";
import Tooltip from "./form/Tooltip.vue";
import { completeServicer } from "../api/servicers.api";

export default {
  components: { Tooltip },
  props: {
    servicerId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isFormValid: true,
      email: this.$route.query.email,
      formData: {
        password: "",
        confirm_password: ""
      },
      rules: {
        required
      }
    };
  },
  computed: {
    passwordsMustMatchRule() {
      return () =>
        this.formData.password === this.formData.confirm_password ||
        "Passwords must match";
    },
    passwordRules() {
      return [
        this.rules.required,
        v =>
          /(?=.{8,})/.test(v) || "Password must be at least 8 characters long.",
        v =>
          /(?=.*[a-z])/.test(v) ||
          "Password must be contain at least 1 lowercase letter",
        v =>
          /(?=.*[A-Z])/.test(v) ||
          "Password must be contain at least 1 uppercase letter",
        v =>
          /(?=.*[0-9])/.test(v) || "Password must be contain at least 1 number",
        v =>
          /(?=.*[!@#$%^&*])/.test(v) ||
          "Password must be contain at least 1 special character (!@#$%^&*)"
      ];
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$loading();
        completeServicer(this.servicerId, this.formData)
          .then(response => {
            this.$router.push({
              name: "complete",
              params: {
                code: "complete"
              }
            });
          })
          .finally(() => this.$hideLoading());
      }
    }
  }
};
</script>

<style scoped></style>
