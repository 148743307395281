<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Attestation Maintenance</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="attestations"
        :loading="loadingTable"
        :loading-text="loadingText"
        sort-by="sort_order"
      >
        <template v-slot:no-data>
          <em>No records yet!</em>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-dialog v-model="dialogForm" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on">Add Attestation</v-btn>
              </template>
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <TextAreaInput
                            label="Description"
                            v-model="editedItem.description"
                            :outlined="false"
                            :rows="1"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Sort Order"
                            type="number"
                            v-model="editedItem.sort_order"
                            :rules="[rules.required]"
                            max="100"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-switch
                            v-model="editedItem.active"
                            label="Active"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.active="{ item }">
          {{ formatBoolean(item.active) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)" color="primary">
            $vuetify.icons.edit
          </v-icon>
          <v-icon
            :disabled="!item.can_delete"
            small
            @click="openDeleteDialog(item)"
            color="error"
          >
            $vuetify.icons.delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Delete Attestation?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteItem">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { loadingTextForTable } from "../../utils/helpers";
import { required } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import {
  createAttestation,
  deleteAttestation,
  updateAttestation
} from "../../api/attestations.api";
import TextAreaInput from "../../components/form/TextAreaInput.vue";

export default {
  components: { TextAreaInput },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      headers: [
        { text: "Sort Order", value: "sort_order" },
        { text: "Description", value: "description" },
        { text: "Active", value: "active" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        description: "",
        sort_order: null,
        active: true
      },
      defaultItem: {
        description: "",
        sort_order: null,
        active: true
      },
      rules: {
        required
      }
    };
  },
  computed: {
    ...mapState("admin", ["attestations"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Attestation" : "Edit Attestation";
    }
  },
  methods: {
    ...mapActions("admin", [
      "fetchAttestations",
      "addAttestation",
      "updateAttestation",
      "removeAttestation"
    ]),
    editItem(item) {
      this.editedIndex = this.attestations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.attestations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createAttestation(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addAttestation(response.data);
            this.$notify.success("Attestation added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateAttestation(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateAttestation(response.data);
            this.$notify.success("Attestation updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteAttestation(this.editedItem.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeAttestation(this.editedIndex);
            this.$notify.success("Attestation removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    formatBoolean(flag) {
      return flag ? "Yes" : "No";
    }
  },
  mounted() {
    this.loadingTable = true;
    this.fetchAttestations().then(attestations => {
      this.loadingTable = false;
    });
  }
};
</script>
