import httpClient from "./httpClient";

const END_POINT = "/services";
const STATUS_END_POINT = "status";

const getServiceStatusLogs = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${STATUS_END_POINT}`);

const createServiceStatusLog = (serviceId, data) => {
  const payload = {
    service_status_log: data
  };
  return httpClient.post(
    `${END_POINT}/${serviceId}/${STATUS_END_POINT}`,
    payload
  );
};

const updateServiceStatusLog = (serviceId, data) => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_status_log: form };
  return httpClient.put(
    `${END_POINT}/${serviceId}/${STATUS_END_POINT}/${id}`,
    payload
  );
};

const createChangeRequest = (serviceId, data) => {
  const payload = {
    service_status_log: data
  };
  return httpClient.post(
    `${END_POINT}/${serviceId}/${STATUS_END_POINT}/change_request`,
    payload
  );
};

export {
  getServiceStatusLogs,
  createServiceStatusLog,
  updateServiceStatusLog,
  createChangeRequest
};
