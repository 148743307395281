<template>
  <v-list-item>
    <v-list-item-content v-if="isEditable">
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row dense>
          <v-col cols="12" v-if="isAdmin && !isReply">
            <v-autocomplete
              label="To"
              v-model="item.to_id"
              :items="servicers"
              item-value="user_id"
              item-text="full_name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" v-if="!isReply">
            <v-text-field
              label="Subject"
              v-model="item.subject"
              :rules="[rules.required]"
              maxlength="255"
            />
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              label="Message"
              v-model="item.text"
              :counter="4000"
              :outlined="false"
              :rows="1"
            />
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" @click="save">Send</v-btn>
            <v-btn color="default" @click="cancel">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-list-item-content>
    <v-list-item-content v-else>
      <v-list-item-title v-html="itemTitle"></v-list-item-title>
      <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="!isEditable">
      <v-btn x-small icon @click="edit = true" v-if="item.can_edit">
        <v-icon color="primary">$vuetify.icons.edit</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import TextAreaInput from "./form/TextAreaInput.vue";
import { required } from "../utils/rules";
import HttpStatus from "http-status";
import { createMessage, updateMessage } from "../api/inboxMessages.api";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: { TextAreaInput },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      isFormValid: true,
      rules: {
        required
      }
    };
  },
  computed: {
    ...mapGetters("user", ["isAdmin"]),
    ...mapState("servicer", ["servicers"]),
    item: {
      get() {
        return this.value;
      },
      set(val) {
        if (val.id) {
          this.$emit("input", val);
        }
      }
    },
    isReply() {
      return this.item.inbox_message_id;
    },
    isEditable() {
      return !this.item.id || this.edit;
    },
    itemTitle() {
      const subtext = `<small class="grey--text text--lighten-1 ml-2">${this.item.message_date}</small>`;

      return this.item.from_user + subtext;
    }
  },
  watch: {
    edit(newValue, oldValue) {
      this.$emit("onEdit", newValue);
    },
    value(newValue, oldValue) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    }
  },
  methods: {
    ...mapActions("inbox", ["updateMessage"]),
    cancel() {
      this.$refs.form.resetValidation();
      this.item = this.value;
      this.edit = false;
      if (!this.item.inbox_message_id && !this.item.id) {
        this.$emit("close");
      } else {
        this.$emit("cancel");
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.edit = true;
        this.$loading();
        !this.item.id ? this.createItem(this.item) : this.updateItem(this.item);
      }
    },
    createItem(item) {
      return createMessage(item)
        .then(response => {
          this.processSaveResponse(response, HttpStatus.CREATED);
        })
        .finally(() => this.$hideLoading());
    },
    updateItem(item) {
      return updateMessage(item)
        .then(response => this.processSaveResponse(response, HttpStatus.OK))
        .finally(() => this.$hideLoading());
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.edit = false;
        this.$notify.success("Message sent successfully");
        this.updateMessage(response.data);
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  white-space: normal;
}
</style>
