<template>
  <div>
    <v-alert
      type="error"
      v-if="response.code === 'error'"
      v-text="response.message"
    />
    <Password v-if="response.code === 'password'" :servicer-id="response.id" />
  </div>
</template>

<script>
import { confirmServicer } from "../api/servicers.api";
import Password from "../components/Password.vue";

export default {
  components: { Password },
  data() {
    return {
      response: {}
    };
  },
  methods: {
    confirm() {
      this.$loading();
      confirmServicer(this.$route.query.email, this.$route.query.token)
        .then(response => {
          this.response = response.data;
        })
        .finally(() => this.$hideLoading());
    }
  },
  mounted() {
    this.confirm();
  }
};
</script>

<style scoped></style>
