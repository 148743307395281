<template>
  <v-textarea
    :auto-grow="autoGrow"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :hint="hint"
    :label="label"
    :maxlength="counter"
    :outlined="outlined"
    :persistent-hint="persistentHint"
    :rows="rows"
    :rules="required ? [localRules.required] : rules"
    :value="localValue"
    @input="bindInput($event)"
  />
</template>

<script>
import { required } from "../../utils/rules";

export default {
  name: "TextAreaInput",
  props: {
    autoGrow: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    counter: {
      type: Number,
      default: 1000
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Please specify"
    },
    hint: {
      type: String,
      default: ""
    },
    outlined: {
      type: Boolean,
      default: true
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 3
    },
    value: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localRules: {
        required
      }
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    bindInput(event) {
      this.localValue = event;
    }
  }
};
</script>
