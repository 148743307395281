<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle"
      >Post-Execution Review Form (PERF)</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-label
              >Please select all institutions who subscribed to your
              service:</v-label
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-autocomplete
              v-model="subscribers"
              :items="institutionOptions"
              multiple
              :menu-props="{ allowOverflow: true }"
              :disabled="disabled"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ subscribers.length - 1 }} others)</span
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="6">
            <v-list dense tile outlined>
              <v-subheader
                >Selected Subscribers (Scrollable)
                <v-spacer />
                {{ subscribers.length }} Selected
              </v-subheader>
              <div class="scrollable">
                <v-list-item
                  dense
                  v-for="(subscriber, index) in subscribers"
                  :key="subscriber"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn
                        v-if="!disabled"
                        icon
                        x-small
                        @click="removeSubscriber(index)"
                      >
                        <v-icon dense color="error" x-small
                          >$vuetify.icons.delete</v-icon
                        >
                      </v-btn>
                      {{ orgLabel(subscriber) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label>Please upload copies of your published reports:</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="ml-0 pl-0">
            <v-col cols="12">
              <v-select
                v-model="upload.sport_code"
                :items="sports"
                label="Sport"
                :rules="upload.report_files.length > 0 ? [rules.required] : []"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="upload.report_files"
                small-chips
                multiple
                label="Reports"
                :accept="acceptTypes"
                :rules="uploadsValid"
                hint="Valid file types include (5MB max): PDF, Images, MS Office Suite, Apple iWork Suite"
                :disabled="disabled"
              ></v-file-input>
            </v-col>
          </v-col>
          <v-col cols="12" sm="6" class="mt-0">
            <v-list dense tile outlined>
              <v-subheader
                >Uploaded Files (Scrollable)<v-spacer />{{
                  service.service_perf_reports.length
                }}
                Uploads</v-subheader
              >
              <div class="scrollable">
                <v-list-item
                  v-for="(link, i) in service.service_perf_reports"
                  :key="i"
                  dense
                >
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title>
                      <v-btn
                        v-if="!disabled"
                        x-small
                        icon
                        @click="deleteFile(link.id)"
                      >
                        <v-icon dense color="error" x-small
                          >$vuetify.icons.delete</v-icon
                        >
                      </v-btn>
                      {{ upperCase(link.sport_code) }}
                      <a :href="link.report_file.file_url">{{
                        link.report_file.file_name
                      }}</a>
                      <span class="grey--text text--lighten-1"
                        >({{ link.report_file.file_size }})</span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Vue from "vue";
import HttpStatus from "http-status";
import { cloneDeep, isEqual } from "lodash";
import { deletePerfReport, updateServicePerf } from "../../api/servicePerf.api";
import { required } from "../../utils/rules";
import { acceptTypes } from "../../utils/helpers";

export default {
  data() {
    return {
      subscribers: [],
      isFormValid: true,
      upload: {
        sport_code: "",
        report_files: [],
      },
      defaultUpload: {
        sport_code: "",
        report_files: [],
      },
      rules: {
        required,
      },
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("listOfValues", ["sports"]),
    ...mapGetters({
      institutionOptions: "listOfValues/institutionD1Options",
    }),
    isDirty() {
      return (
        !isEqual(this.subscribers, this.service.subscribers) ||
        !isEqual(this.upload, this.defaultUpload)
      );
    },
    acceptTypes() {
      return acceptTypes;
    },
    uploadsValid() {
      let sizeValid = true;
      this.upload.report_files.forEach((file) => {
        if (file.size > 5000000) {
          sizeValid = false;
        }
      });
      return [
        !this.upload.sport_code ||
          this.upload.report_files.length > 0 ||
          "Required",
        sizeValid || "Files may not exceed 5MB in size",
      ];
    },
    prepareSave() {
      return {
        id: this.service.id,
        subscribers: this.subscribers,
        sport_code: this.upload.sport_code,
        report_files: this.upload.report_files,
      };
    },
  },
  methods: {
    ...mapActions("service", ["updateServicePerf"]),
    initialize() {
      this.reset();
    },
    reset() {
      this.subscribers = cloneDeep(this.service.subscribers);
      this.upload = cloneDeep(this.defaultUpload);
      this.$refs.form.resetValidation();
    },
    orgLabel(value) {
      return this.$store.getters["listOfValues/orgLabel"](value);
    },
    removeSubscriber(index) {
      this.subscribers.splice(index, 1);
    },
    isValid() {
      return this.$refs.form.validate();
    },
    save() {
      if (this.isValid()) {
        Vue.prototype.$loading();
        return updateServicePerf(this.prepareSave)
          .then((response) => {
            if (response.status === HttpStatus.OK) {
              // Update vuex store
              this.updateServicePerf(response.data);
              this.$notify.success("PERF form saved successfully.");
              this.reset();
              return true;
            } else {
              return false;
            }
          })
          .finally(() => Vue.prototype.$hideLoading());
      }
    },
    deleteFile(linkId) {
      this.$loading();
      return deletePerfReport(linkId, this.service.id)
        .then((response) => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateServicePerf(response.data);
            this.reset();
            this.$notify.success("File deleted successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    upperCase(str) {
      return str ? str.toUpperCase() : str;
    },
  },
};
</script>

<style scoped>
.scrollable {
  height: 200px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
