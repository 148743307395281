<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle"
      >Promotion</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row v-if="!videoOnlyService">
          <v-col cols="12">
            <v-label
              >How often does the service update and publish information about
              prospects?</v-label
            >
          </v-col>
          <v-col
            cols="12"
            class="d-print-none"
            :class="{ 'd-none': isPrintPage }"
          >
            <v-alert type="info"
              >NCAA approval requires that the service provide coach subscribers
              additional athlete profiles and updates to existing profiles a
              minimum of four times per calendar year.</v-alert
            >
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="service_promotion.publish_info_frequency"
              class="align-center"
              label="times per year"
              :disabled="disabled"
              :max="publish_info_frequency_max"
              :min="publish_info_frequency_min"
              thumb-label="always"
              :thumb-size="24"
              hide-details
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model="service_promotion.publish_info_frequency"
                  class="mt-0 pt-0"
                  single-line
                  type="number"
                  :disabled="disabled"
                  :max="publish_info_frequency_max"
                  :min="publish_info_frequency_min"
                  :rules="[rules.publishInfoFrequency]"
                />
              </template>
            </v-slider>
          </v-col>
          <v-col cols="12"
            ><TextAreaInput
              label="Explanation"
              v-model="service_promotion.publish_info_explanation"
              :outlined="false"
              :required="frequencyExplanationRequired"
              :rows="1"
              :hint="frequencyExplanationRequired ? '' : 'optional'"
              persistent-hint
              :disabled="disabled"
          /></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Will you market your service to Division I programs for sports
              other than basketball and football?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_promotion.additional_marketing"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row :class="indent" v-if="service_promotion.additional_marketing">
          <v-col cols="12">
            <v-alert type="warning"
              >This Recruiting/Scouting Service approval process is specific to
              basketball and football; so, NCAA approval applies only to those
              sports. Each NCAA compliance office must independently evaluate
              services in other sports and determine if their coaches may
              subscribe to those services. Therefore, when you market your
              service, you are not permitted to say or imply that the entire
              service is NCAA approved.</v-alert
            >
          </v-col>
          <v-col cols="12">
            <v-label>Do you understand and agree to comply?</v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_promotion.additional_marketing_compliant"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Do any NCAA athletics staff members serve as a consultant or
              participate on an advisory panel for your recruiting/scouting
              service?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_promotion.ncaa_staff_conflict"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row :class="indent" v-if="service_promotion.ncaa_staff_conflict">
          <v-col cols="12">
            <v-label>Use the button below to add each member.</v-label>
          </v-col>
          <v-col cols="12">
            <Associates
              :type-id="getServiceAssociationIdByName('promotion_employees')"
              :disabled="disabled"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Does your website and/or social media account(s) include
              evaluations or ratings of prospects from NCAA athletics staff
              members?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              :class="spaceVertically"
              v-model="service_promotion.has_eval_from_ncaa_ad_staff"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row :class="indent" v-if="isNcaaViolation">
          <v-col cols="12">
            <v-alert type="warning"
              >Use of these items would be considered either 1) assisting the
              institution to recruit; or 2) an endorsement of the service by the
              institution; both are NCAA violations. ECAG requirements do not
              address the use of these items; so, it will not impact the
              service's eligibility for approval, but it is advisable that you
              discontinue using these items to ensure that the institution(s)
              and coach(es) are not penalized.</v-alert
            >
          </v-col>
          <v-col cols="12">
            <v-label
              >Do you understand that continued use of these items may result in
              NCAA violations for the involved institution(s) and its
              staff?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_promotion.ncaa_violations_compliant"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >If NCAA approval is granted, the service may use the following
              language to promote the NCAA approval:</v-label
            >
          </v-col>
          <v-col cols="12">
            <blockquote class="blockquote">
              <v-icon small>fa-quote-left</v-icon>
              <span class="mx-2"
                >This recruiting/scouting service has been approved in
                accordance with NCAA bylaws, policies, and procedures. NCAA
                Division I football and/or basketball coaches are permitted to
                subscribe to this recruiting/scouting service.</span
              >
              <v-icon small>fa-quote-right</v-icon>
            </blockquote>
          </v-col>
          <v-col cols="12">
            <v-label
              >Do you understand the limitations around how you can promote the
              NCAA approval of your service?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_promotion.understand_promote_limitations"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  createServicePromotion,
  updateServicePromotion,
} from "../../api/servicePromotion.api";
import HttpStatus from "http-status";
import Associates from "./Associates.vue";
import TextAreaInput from "../form/TextAreaInput.vue";
import YesNoButtonGroup from "../form/YesNoButtonGroup.vue";
import { cloneDeep, isEqual } from "lodash";
import { printPageName } from "../../utils/helpers";

export default {
  name: "Promotion",
  components: {
    Associates,
    TextAreaInput,
    YesNoButtonGroup,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFormValid: true,
      publish_info_frequency_min: 0,
      publish_info_frequency_max: 100,
      rules: {
        publishInfoFrequency: (v) =>
          (v >= this.publish_info_frequency_min &&
            v <= this.publish_info_frequency_max) ||
          "Must be between 0 and 100",
      },
      service_promotion: {
        id: null,
        service_id: null,
        publish_info_frequency: null,
        publish_info_explanation: "",
        additional_marketing: null,
        additional_marketing_compliant: null,
        ncaa_staff_conflict: null,
        has_eval_from_ncaa_ad_staff: null,
        ncaa_violations_compliant: null,
        understand_promote_limitations: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getServiceAssociationIdByName: "listOfValues/serviceAssociationIdByName",
    }),
    ...mapState("service", ["service"]),
    isDirty() {
      return (
        this.service_promotion.id &&
        !isEqual(this.service_promotion, this.service.service_promotion)
      );
    },
    isNew() {
      return (
        this.service_promotion.id === null ||
        this.service_promotion.id === undefined
      );
    },
    indent() {
      return "ml-4";
    },
    spaceVertically() {
      return "mt-5";
    },
    isNcaaViolation() {
      return this.service_promotion.has_eval_from_ncaa_ad_staff;
    },
    frequencyExplanationRequired() {
      return this.service_promotion.publish_info_frequency < 4;
    },
    isPrintPage() {
      return this.$router.currentRoute.name === printPageName;
    },
    videoOnlyService() {
      return this.service.service_format.video_only;
    },
  },
  methods: {
    ...mapActions("service", ["setServicePromotion"]),
    initialize() {
      if (!this.service_promotion.id) {
        this.reset();
      }
    },
    reset() {
      this.service_promotion = cloneDeep(this.service.service_promotion);
      this.$refs.form.resetValidation();
    },
    isValid() {
      return this.$refs.form.validate();
    },
    save() {
      if (this.isValid()) {
        this.service_promotion.service_id = this.service.id;
        this.$loading();
        if (this.service_promotion.id) {
          return this.update();
        } else {
          return this.create();
        }
      }
    },
    create() {
      return createServicePromotion(this.service_promotion)
        .then((response) => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.setServicePromotion(response.data);
            this.service_promotion = cloneDeep(response.data);
            this.$notify.success("Service Promotion form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    update() {
      return updateServicePromotion(this.service_promotion)
        .then((response) => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.setServicePromotion(response.data);
            this.service_promotion = cloneDeep(response.data);
            this.$notify.success("Service Promotion form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
  },
};
</script>
