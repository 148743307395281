<template>
  <div
    class="mb-2 d-print-none"
    v-if="accountComplete && (isAdmin || isServicer)"
  >
    <v-app-bar color="primary" floating flat dense width="100%">
      <v-app-bar-nav-icon
        class="hidden-md-and-up ml-0"
        color="white"
        @click="showDrawer = true"
      />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn exact icon :to="{ name: 'home' }">
          <v-icon color="white">fa-home</v-icon>
        </v-btn>
        <v-btn v-if="isAdmin || isServicer" text :to="{ name: 'applications' }">
          Applications
        </v-btn>
        <v-btn v-if="isAdmin" text :to="{ name: 'servicers' }">
          Servicers
        </v-btn>
        <v-btn v-if="isAdmin" text :to="{ name: 'approved' }">
          Approved Services
        </v-btn>
        <v-btn v-if="isAdmin || isServicer" text :to="{ name: 'inbox' }">
          Inbox
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip
                small
                color="grey"
                text-color="white"
                class="ml-2"
                v-on="on"
              >
                {{ unreadMessageCount }}
              </v-chip>
            </template>
            <span>{{ unreadMessageCount }} unread messages</span>
          </v-tooltip>
        </v-btn>
        <v-menu offset-y v-if="isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn dark text v-on="on">
              Admin
            </v-btn>
          </template>
          <v-list>
            <v-list-item key="attestation" :to="{ name: 'attestations' }">
              <v-list-item-title>Attestations</v-list-item-title>
            </v-list-item>
            <v-list-item key="cheatsheet" :to="{ name: 'cheatsheets' }">
              <v-list-item-title>Cheat Sheets</v-list-item-title>
            </v-list-item>
            <v-list-item key="statusEmails" :to="{ name: 'statusEmails' }">
              <v-list-item-title>Status Emails</v-list-item-title>
            </v-list-item>
            <v-list-item key="status" :to="{ name: 'statuses' }">
              <v-list-item-title>Statuses</v-list-item-title>
            </v-list-item>
            <v-list-item key="year" :to="{ name: 'years' }">
              <v-list-item-title>Years</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer
      app
      class="hidden-md-and-up"
      color="primary"
      dark
      height="100%"
      hide-overlay
      v-model="showDrawer"
    >
      <v-list-item>
        <v-list-item-avatar class="avatar-border">
          <v-icon v-if="user.is_servicer" x-small>
            fa-user
          </v-icon>
          <img
            v-else
            :src="
              user.is_staff
                ? ncaaLogo
                : user.org_logo_url
            "
            alt="Logo"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="user.is_servicer">
            <v-btn light x-small :to="{ name: 'profile' }">Profile</v-btn>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            <small>{{ user.org_name }}</small>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav dense>
        <v-list-item :to="{ name: 'home' }" exact>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isAdmin || isServicer"
          :to="{ name: 'applications' }"
        >
          <v-list-item-title>Applications</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAdmin" :to="{ name: 'servicers' }">
          <v-list-item-title>Servicers</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAdmin" :to="{ name: 'approved' }">
          <v-list-item-title>Approved Services</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAdmin || isServicer" :to="{ name: 'inbox' }">
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item>
        <v-list-group v-if="isAdmin" color="white">
          <template v-slot:activator>
            <v-list-item class="pl-0">
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'attestations' }" class="pl-4">
            <v-list-item-title>Attestations</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'cheatsheets' }" class="pl-4">
            <v-list-item-title>Cheat Sheets</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'statusEmails' }" class="pl-4">
            <v-list-item-title>Status Emails</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'statuses' }" class="pl-4">
            <v-list-item-title>Statuses</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'years' }" class="pl-4">
            <v-list-item-title>Years</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block light :to="{ name: 'logout' }" class="red--text">
            <v-icon left>
              $vuetify.icons.logout
            </v-icon>
            Log Out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ncaaLogo from "../../images/NCAA_flat_sm.png";
export default {
  name: "Nav",
  data() {
    return {
      showDrawer: false,
      ncaaLogo
    };
  },
  computed: {
    ...mapState("user", {
      user: state => state.user
    }),
    ...mapGetters("user", ["accountComplete", "isAdmin", "isServicer"]),
    ...mapGetters("inbox", ["unreadMessageCount"])
  },
  methods: {
    ...mapActions("inbox", ["fetchMessages"])
  },
  async mounted() {
    if (this.isAdmin || this.isServicer) {
      await this.fetchMessages();
    }
  }
};
</script>

<style>
.avatar-border {
  border: 1px solid white;
}
header.v-app-bar > .v-toolbar__content {
  padding: 0 !important;
}
.v-toolbar__items > button {
  font-weight: 700 !important;
}
</style>
