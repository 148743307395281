<template>
  <div>
    <v-card outlined>
      <v-toolbar color="grey" dark dense>
        <v-icon class="pr-2">$vuetify.icons.comment</v-icon>
        <v-toolbar-title>Conversation Thread</v-toolbar-title>
        <v-progress-linear
          :active="conversationsLoading"
          :indeterminate="conversationsLoading"
          absolute
          bottom
          color="primary accent-4"
        ></v-progress-linear>

        <v-spacer />
        <v-btn
          v-if="isAdmin"
          color="info"
          small
          dark
          fab
          @click.stop="newConversation"
        >
          <v-icon>$vuetify.icons.plus</v-icon>
        </v-btn>
      </v-toolbar>
      <p
        class="text-center grey--text text--lighten-1 mt-2"
        v-if="conversations.length === 0 && !conversationsLoading"
      >
        <em>The conversation thread is empty!</em>
      </p>
      <v-list two-line v-if="conversations.length > 0">
        <template v-for="(item, index) in conversations">
          <ConversationItem
            :value="item"
            :key="index"
            :form-id="formId"
            v-on:on-save="save($event)"
            v-on:on-reply="newReply(item)"
            v-on:on-delete="openDeleteDialog($event, index)"
            v-on:on-close="openCloseDialog(item)"
            v-on:on-delete-file="deleteFile($event)"
          />
          <v-list two-line :key="'replies_' + index" class="ml-4">
            <template v-for="(reply, rIndex) in item.replies">
              <ConversationItem
                :value="reply"
                :key="item.id + '_' + rIndex"
                :form-id="formId"
                v-on:on-save="save($event)"
                v-on:on-reply="newReply(item)"
                v-on:on-delete="openDeleteDialog($event, index, rIndex)"
              />
            </template>
          </v-list>
          <v-divider
            v-if="index + 1 < conversations.length"
            :key="'d_' + index"
          ></v-divider>
        </template>
      </v-list>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            {{ deleteTitle }}
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteConversation">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogClose" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Close Conversation?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="secondary" @click="closeConversation">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  createServiceConversation,
  deleteServiceConversation,
  deleteServiceConversationFile,
  updateServiceConversation
} from "../../api/serviceConversations.api";
import HttpStatus from "http-status";
import { mapActions, mapGetters, mapState } from "vuex";
import ConversationItem from "./ConversationItem.vue";

export default {
  components: { ConversationItem },
  props: {
    formId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialogDelete: false,
      dialogClose: false,
      defaultItem: {
        form_id: this.formId,
        comment: "",
        conversation_files: []
      },
      closeItem: {},
      deleteItem: {},
      deleteIndex: -1,
      deleteReplyIndex: -1
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("serviceReview", ["conversationsLoading"]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isAdmin", "isServicer"]),
    conversations() {
      return this.$store.getters["serviceReview/formConversations"](
        this.formId
      );
    },
    deleteTitle() {
      return this.deleteReplyIndex !== null &&
        this.deleteReplyIndex !== undefined &&
        this.deleteReplyIndex > -1
        ? "Delete Reply?"
        : "Delete Conversation?";
    }
  },
  methods: {
    ...mapActions("serviceReview", [
      "addConversation",
      "updateConversation",
      "removeConversation"
    ]),
    close() {
      this.dialogClose = false;
      this.dialogDelete = false;
      this.closeItem = {};
      this.deleteItem = {};
      this.deleteIndex = -1;
      this.deleteReplyIndex = -1;
    },
    newConversation() {
      this.addConversation(Object.assign({}, this.defaultItem));
    },
    newReply(parent) {
      const reply = {
        ...this.defaultItem,
        service_conversation_id: parent.id
      };

      parent.replies.push(reply);
    },
    save(item) {
      this.$loading();
      !item.id ? this.createItem(item) : this.updateItem(item);
    },
    createItem(item) {
      return createServiceConversation(this.service.id, item)
        .then(response =>
          this.processSaveResponse(response, HttpStatus.CREATED)
        )
        .finally(() => this.$hideLoading());
    },
    updateItem(item) {
      return updateServiceConversation(this.service.id, item)
        .then(response => this.processSaveResponse(response, HttpStatus.OK))
        .finally(() => this.$hideLoading());
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.updateConversation(response.data);
        return true;
      } else {
        return false;
      }
    },
    openDeleteDialog(item, index, replyIndex) {
      this.deleteItem = item;
      this.deleteIndex = index;
      this.deleteReplyIndex = replyIndex;
      this.dialogDelete = true;
    },
    openCloseDialog(item) {
      this.closeItem = item;
      this.dialogClose = true;
    },
    deleteConversation() {
      if (this.deleteItem.id) {
        this.$loading();
        this.dialogDelete = false;
        return deleteServiceConversation(this.deleteItem.id, this.service.id)
          .then(response => {
            if (response.status === HttpStatus.NO_CONTENT) {
              // Update vuex store
              this.removeConversation({
                parentIndex: this.deleteIndex,
                replyIndex:
                  this.deleteReplyIndex > -1 ? this.deleteReplyIndex : null
              });
              this.close();
              return true;
            } else if (response.status === HttpStatus.OK) {
              this.updateConversation(response.data);
              this.close();
              return true;
            } else {
              return false;
            }
          })
          .finally(() => this.$hideLoading());
      } else {
        this.removeConversation({
          parentIndex: this.deleteIndex,
          replyIndex: this.deleteReplyIndex > -1 ? this.deleteReplyIndex : null
        });
        this.close();
      }
    },
    deleteFile(payload) {
      this.$loading();
      return deleteServiceConversationFile(
        this.service.id,
        payload.conversationId,
        payload.fileId
      )
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateConversation(response.data);
            this.$notify.success("File deleted successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    closeConversation() {
      this.$loading();
      this.dialogClose = false;
      this.closeItem.closed = true;
      this.updateItem(this.closeItem).then(this.close());
    }
  }
};
</script>

<style scoped></style>
