import httpClient from "./httpClient";

const END_POINT = "/services";

const getServices = () => httpClient.get(END_POINT);
const getService = id => httpClient.get(`${END_POINT}/${id}`);
const deleteService = serviceId => {
  return httpClient.delete(`${END_POINT}/${serviceId}`);
};

const submitService = data => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.post(`${END_POINT}/${id}/submit`, prepareSubmit(form));
};

const flagService = id => {
  return httpClient.post(`${END_POINT}/${id}/flag`);
};

const getYear = () => httpClient.get(`${END_POINT}/year`);
const getYears = () => httpClient.get(`${END_POINT}/years`);
const getApprovedServices = () => httpClient.get(`${END_POINT}/approved`);
const getAttestations = () => httpClient.get(`${END_POINT}/attestations`);

function prepareSubmit(data) {
  return {
    service: {
      submitter_comment: data.submitter_comment,
      submitter_signature: data.submitter_signature,
      selected_attestations: data.selected_attestations
    }
  };
}

export {
  getServices,
  getService,
  submitService,
  deleteService,
  getYear,
  getYears,
  getApprovedServices,
  getAttestations,
  flagService
};
