import Vue from "vue";
import Vuex from "vuex";

import listOfValues from "./modules/listOfValues";
import servicer from "./modules/servicer";
import service from "./modules/service";
import servicePayment from "./modules/servicePayment";
import user from "./modules/user";
import admin from "./modules/admin";
import serviceReview from "./modules/serviceReview";
import inbox from "./modules/inbox";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    listOfValues,
    servicer,
    user,
    service,
    servicePayment,
    admin,
    serviceReview,
    inbox
  },
  state: {
    loading: false
  },
  getters: {
    loading: state => state.loading
  },
  mutations: {
    SHOW_LOADING: state => (state.loading = true),
    HIDE_LOADING: state => (state.loading = false)
  },
  actions: {}
});
