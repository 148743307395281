<template>
  <v-card tile outlined class="p-2">
    <v-toolbar color="grey" dark dense>
      <v-icon class="pr-2">$vuetify.icons.history</v-icon>
      <v-toolbar-title>Application History</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom :disabled="!(isServicer && !repliedAllConversations)">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
              v-if="isAdmin || isInfoRequested"
              :disabled="isServicer && !repliedAllConversations"
              color="info"
              small
              @click.stop="openDialogForm"
              v-text="buttonText"
            />
          </span>
        </template>
        <span
          >Please reply to all requests for information prior to resubmitting
          application.</span
        >
      </v-tooltip>
      <ChangeRequest
        v-if="isServicer && !service.can_edit && !isInfoRequested"
      />
    </v-toolbar>
    <v-data-table
      :headers="computedHeaders"
      :items="status_logs"
      dense
      disable-pagination
      disable-sort
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:item.due_date="{ item }">
        {{ formatDate(item.due_date) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" color="primary">
          $vuetify.icons.edit
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogForm" max-width="500px">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="saveStatus"
      >
        <v-card>
          <v-card-title>
            <span class="headline" v-text="dialogTitle"></span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-if="isAdmin">
                  <v-select
                    :disabled="isEditing"
                    v-model="status_log.status_id"
                    :items="getActiveStatuses"
                    item-text="description"
                    item-value="id"
                    label="Status"
                    :rules="[rules.required, sameStatus]"
                  />
                </v-col>
                <v-alert type="info" cols="12" v-if="isInfoRequested">
                  Resubmit the application to the NCAA. Please make sure you've
                  answered any and all questions.
                </v-alert>
                <v-col cols="12" v-if="isAdmin && !isEditing">
                  <v-select
                    label="Cheat Sheet"
                    v-model="selectedCheatsheet"
                    :items="cheatsheets"
                    item-value="text"
                    item-text="title"
                    clearable
                    @change="appendCheatsheet"
                  />
                </v-col>
                <v-col cols="12">
                  <TextAreaInput
                    v-model="status_log.comment"
                    :outlined="false"
                    label="Comment"
                    :rows="1"
                    :required="false"
                    hint="Optional"
                    :persistent-hint="true"
                  />
                </v-col>
                <v-col cols="12" v-if="isAdmin">
                  <Datepicker v-model="status_log.due_date" label="Due Date" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit" v-text="dialogBtnText" />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TextAreaInput from "../form/TextAreaInput.vue";
import Datepicker from "../form/Datepicker.vue";
import HttpStatus from "http-status";
import {
  createServiceStatusLog,
  updateServiceStatusLog
} from "../../api/serviceStatusLog.api";
import { required } from "../../utils/rules";
import { formatDate, status } from "../../utils/helpers";
import ChangeRequest from "./ChangeRequest.vue";

export default {
  name: "StatusLogs",
  components: { ChangeRequest, Datepicker, TextAreaInput },
  data() {
    return {
      dialogForm: false,
      headers: [
        { text: "Status Date", value: "status_date" },
        { text: "Status", value: "status" },
        { text: "Message", value: "comment" },
        { text: "Due Date", value: "due_date" },
        { text: "User", value: "user" },
        {
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
          isAdmin: true
        }
      ],
      loading: false,
      isFormValid: true,
      defaultItem: {
        status_id: null,
        comment: "",
        due_date: null
      },
      status_log: {
        status_id: null,
        comment: "",
        due_date: null
      },
      rules: {
        required
      },
      selectedCheatsheet: ""
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("serviceReview", ["status_logs"]),
    ...mapGetters("admin", ["getActiveStatuses"]),
    ...mapGetters("user", ["isAdmin", "isServicer"]),
    ...mapGetters("serviceReview", ["openConversations"]),
    computedHeaders() {
      return this.headers.filter(
        header => !header.isAdmin || header.isAdmin === this.isAdmin
      );
    },
    sameStatus() {
      return (
        this.status_log.id ||
        this.service.status_id !== this.status_log.status_id ||
        "The application is already in this status."
      );
    },
    cheatsheets() {
      return this.$store.getters["admin/formCheatsheets"](100);
    },
    buttonText() {
      return this.isAdmin
        ? "Change Status"
        : this.isInfoRequested
        ? "Resubmit"
        : "";
    },
    dialogTitle() {
      if (this.isAdmin && !this.isEditing) {
        return "Change Status";
      } else if (this.isAdmin && this.isEditing) {
        return "Edit Status";
      } else if (this.isInfoRequested) {
        return "Resubmit";
      } else {
        return "";
      }
    },
    dialogBtnText() {
      return this.isEditing ? "Save" : this.buttonText;
    },
    isInfoRequested() {
      return (
        this.isServicer && this.service.status_id === status.INFO_REQUESTED
      );
    },
    isEditing() {
      return !!this.status_log.id;
    },
    repliedAllConversations() {
      const noReplies = this.openConversations.filter(conversation => {
        return (
          conversation.replies.length === 0 ||
          !conversation.replies[conversation.replies.length - 1].id ||
          conversation.replies[conversation.replies.length - 1].staff
        );
      });

      return noReplies.length === 0;
    }
  },
  methods: {
    ...mapActions("admin", ["fetchStatuses"]),
    ...mapActions("serviceReview", [
      "fetchStatusLogs",
      "addStatusLog",
      "updateStatusLog"
    ]),
    isValid() {
      return this.$refs.form.validate();
    },
    editItem(item) {
      this.status_log = { ...item };
      this.dialogForm = true;
    },
    openDialogForm() {
      if (this.isInfoRequested) {
        this.status_log.status_id = status.APPLICANT_RESPONDED;
      }

      this.dialogForm = true;
    },
    close() {
      this.dialogForm = false;
      this.selectedPredefined = "";
      this.status_log = { ...this.defaultItem };
      this.$refs.form.resetValidation();
    },
    saveStatus() {
      if (this.isValid()) {
        this.dialogForm = false;
        this.$loading();
        !this.status_log.id ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      return createServiceStatusLog(this.service.id, this.status_log)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.close();
            if (this.isServicer) {
              this.$notify.success("Application resubmitted successfully.");
              this.$router.push({ name: "applications" });
            } else {
              this.$notify.success("Status change saved successfully.");
              this.addStatusLog(response.data);
            }
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      return updateServiceStatusLog(this.service.id, this.status_log)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            this.close();
            this.$notify.success("Status change updated successfully.");
            this.updateStatusLog(response.data);
          }
        })
        .finally(() => this.$hideLoading());
    },
    formatDate(date) {
      return formatDate(date);
    },
    appendCheatsheet() {
      if (this.selectedCheatsheet) {
        if (this.status_log.comment.trim().length > 0) {
          this.status_log.comment += "\n";
        }
        this.status_log.comment += this.selectedCheatsheet;
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchStatusLogs(this.service.id);
    this.loading = false;
    if (this.isAdmin) {
      await this.fetchStatuses();
    }
  }
};
</script>

<style scoped></style>
