<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Servicers</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="servicers"
        :loading="loading"
        :loading-text="loadingText"
        :search="search"
        show-expand
        ref="servicersTable"
      >
        <template v-slot:top>
          <v-row dense>
            <v-btn class="mt-2" tile @click="downloadCsv">CSV</v-btn>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                append-icon="fa-search"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.business_address_html="{ item }">
          <span v-html="item.business_address_html" />
        </template>
        <template v-slot:item.services="{ item }">
          <v-chip>{{ item.services.length }}</v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            :class="{ 'text-center': item.services.length === 0 }"
          >
            <v-simple-table v-if="item.services.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Service Name</th>
                    <th>Status</th>
                    <th>Website</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="service in item.services" v-bind:key="service.id">
                    <td>{{ service.year }}</td>
                    <td>{{ service.name }}</td>
                    <td>{{ service.status }}</td>
                    <td>{{ service.website }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <span v-else>No services</span>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="primary"
                icon
                :to="{ name: 'servicerEdit', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon small>$vuetify.icons.edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Servicer</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="info"
                icon
                :to="{ name: 'imitateLogin', params: { email: item.email } }"
                v-on="on"
              >
                <v-icon small>$vuetify.icons.user</v-icon>
              </v-btn>
            </template>
            <span>Login As Servicer</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!item.can_delete"
                small
                color="error"
                icon
                @click="openDeleteDialog(item)"
                v-on="on"
              >
                <v-icon small>$vuetify.icons.delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Servicer</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                :color="isSuspended(item) ? 'warning' : 'grey'"
                icon
                @click="openSuspendDialog(item)"
                v-on="on"
              >
                <v-icon small>$vuetify.icons.suspend</v-icon>
              </v-btn>
            </template>
            <span>{{ suspendTooltip(item) }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Delete Servicer?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialogDelete = false">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteApp">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSuspend" max-width="500px">
        <v-form ref="form">
          <v-card>
            <v-card-title>
              <span class="headline">Suspend Account</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <Datepicker
                      label="Suspend End Date"
                      v-model="suspendItem.suspend_end_date"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="close">Cancel</v-btn>
              <v-btn color="primary" @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { loadingTextForTable, formatDate } from "../utils/helpers";
import HttpStatus from "http-status";
import { deleteServicer, updateServicerSuspension } from "../api/servicers.api";
import Datepicker from "../components/form/Datepicker.vue";

import moment from "moment";
import { Parser, transforms } from "json2csv";
const { unwind } = transforms;

export default {
  components: { Datepicker },
  data() {
    return {
      loading: false,
      loadingText: loadingTextForTable,
      dialogDelete: false,
      deleteIndex: null,
      deleteId: null,
      dialogSuspend: false,
      suspendItem: {
        id: null,
        suspend_end_date: null
      },
      defaultItem: {
        id: null,
        suspend_end_date: null
      },
      search: "",
      headers: [
        { text: "Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Business Address", value: "business_address_html" },
        { text: "Services", value: "services" },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ]
    };
  },
  computed: {
    filteredItems() {
      return this.$refs.servicersTable.$children[0].filteredItems;
    },
    servicers() {
      return this.$store.state.servicer.servicers;
    }
  },
  methods: {
    ...mapActions("servicer", [
      "fetchServicers",
      "removeServicer",
      "updateServicer"
    ]),
    openDeleteDialog(item) {
      this.deleteIndex = this.servicers.indexOf(item);
      this.deleteId = item.id;
      this.dialogDelete = true;
    },
    openSuspendDialog(item) {
      this.suspendItem = Object.assign({}, item);
      this.dialogSuspend = true;
    },
    deleteApp() {
      this.$loading();
      this.dialogDelete = false;
      return deleteServicer(this.deleteId)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeServicer(this.deleteIndex);
            this.$notify.success("Servicer deleted successfully");
          }
        })
        .finally(() => this.$hideLoading());
    },
    isSuspended(item) {
      let suspended = false;
      if (item.suspend_end_date) {
        const suspendDate = moment(item.suspend_end_date, "YYYY-MM-DD");
        if (suspendDate.isAfter(moment())) {
          suspended = true;
        }
      }
      return suspended;
    },
    suspendTooltip(item) {
      if (this.isSuspended(item)) {
        return `Account suspended until ${formatDate(item.suspend_end_date)}`;
      } else {
        return "Suspend Account";
      }
    },
    close() {
      this.dialogSuspend = false;
      this.suspendItem = Object.assign({}, this.defaultItem);
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.dialogSuspend = false;
        return updateServicerSuspension(this.suspendItem)
          .then(response => {
            if (response.status === HttpStatus.OK) {
              // Update vuex store
              this.updateServicer(response.data);
              this.$notify.success("Account suspension updated successfully");
              this.close();
              return true;
            } else {
              return false;
            }
          })
          .finally(() => this.$hideLoading());
      }
    },
    parseCsv() {
      const fields = [
        { label: "Name", value: "full_name" },
        { label: "Email", value: "email" },
        { label: "Phone", value: "phone" },
        { label: "Business Address", value: "business_address" },
        { label: "Service Year", value: "services.year" },
        { label: "Service Name", value: "services.name" },
        { label: "Service Status", value: "services.status" },
        { label: "Service Website", value: "services.website" }
      ];
      const transforms = [unwind({ paths: ["services"] })];
      const json2csvParser = new Parser({ fields, transforms });
      return json2csvParser.parse(this.filteredItems);
    },
    downloadCsv() {
      const csv = this.parseCsv();
      this.$papa.download(csv, "Servicers");
    }
  },
  mounted() {
    this.loading = true;
    this.fetchServicers().then(servicers => {
      this.loading = false;
    });
  }
};
</script>

<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
