import httpClient from "./httpClient";

const END_POINT = "/services";
const IDENTITY_END_POINT = "/identity";

const getServiceIdentity = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}${IDENTITY_END_POINT}`);
const updateServiceIdentity = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_identity: form };
  return httpClient.put(
    `${END_POINT}/${data.service_id}${IDENTITY_END_POINT}`,
    payload
  );
};

const createServiceIdentity = data => {
  const payload = {
    service_identity: data
  };
  return httpClient.post(`${END_POINT}${IDENTITY_END_POINT}`, payload);
};

export { getServiceIdentity, updateServiceIdentity, createServiceIdentity };
