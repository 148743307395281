<template>
  <v-card tile flat outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Welcome!</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>
        Welcome to the NCAA {{ siteNameWithAbbr }}. NCAA Division I basketball
        and football coaches are only permitted to subscribe to
        recruiting/scouting services (RSS) that have obtained NCAA approval from
        the Enforcement Certification and Approvals Group (ECAG). ECAG approval
        is an indication that at the time when the application was processed,
        the service was material compliant with NCAA legislation and
        requirements.
      </p>
      <p>
        A RSS wanting to request NCAA approval must apply annually in the
        {{ siteAbbr }}. Applications are only accepted in the months identified
        in the application fees information. Applications submitted after 11:59
        pm Eastern on the last day of the month are considered to be submitted
        the following month. Deadlines are strictly enforced.
      </p>

      <h4>Application Fees</h4>
      <p>
        As of 1/1/2021, an application fee paid by America Express, Discover,
        Mastercard or Visa is required according to the pricing structure
        outlined below. No other forms of payment are accepted.
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="6">
            <div v-if="!currentYear">
              <v-progress-circular
                indeterminate
                class="mr-1"
                color="primary"
                :size="15"
                :width="2"
              />
              Gathering application fees...
            </div>
            <div v-else>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Applications submitted on or after</th>
                      <th class="text-right">Application Fee</th>
                      <th class="text-right">Approval Fee</th>
                      <th class="text-right">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01/01/{{ currentYear.year }}</td>
                      <td class="text-right">
                        {{
                          formatCurrency(
                            parseFloat(
                              currentYear.fee_attributes.application_fee_amount
                            )
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          formatCurrency(
                            parseFloat(
                              currentYear.fee_attributes.standard_amount
                            )
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          formatCurrency(
                            parseFloat(
                              currentYear.fee_attributes.standard_amount
                            ) +
                              parseFloat(
                                currentYear.fee_attributes
                                  .application_fee_amount
                              )
                          )
                        }}
                      </td>
                    </tr>
                    <tr
                      v-for="fee_structure in currentYear.fee_attributes
                        .fee_structures_attributes"
                      :key="fee_structure.id"
                    >
                      <td>{{ formatDate(fee_structure.applied_at) }}</td>
                      <td class="text-right">
                        {{
                          formatCurrency(
                            parseFloat(
                              currentYear.fee_attributes.application_fee_amount
                            )
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          formatCurrency(
                            parseFloat(fee_structure.fee_amount) +
                              parseFloat(
                                currentYear.fee_attributes.standard_amount
                              )
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          formatCurrency(
                            parseFloat(fee_structure.fee_amount) +
                              parseFloat(
                                currentYear.fee_attributes.standard_amount
                              ) +
                              parseFloat(
                                currentYear.fee_attributes
                                  .application_fee_amount
                              )
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </p>

      <h4>Information Needed</h4>
      <p>
        As part of the application, you will need to provide details about all
        fee rates charged to NCAA institutions including any tiered pricing
        options, social media accounts associated with the service, relationship
        history with NCAA institutions and/or prospective student-athletes, an
        exemplar/mock-up of the complete product or report that would be
        provided to an institution with a full cross-section of the product(s)
        offered by the service, and if the service is online, provide the NCAA
        with a username and password to login.
      </p>

      <h4>Post Approval Changes</h4>
      <p>
        Applications are approved based on the information included in the
        submission; any change (name of service, web address, fee rates, etc.)
        made by the service operator post-approval must be reported to ECAG via
        the Change Request feature in the {{ siteAbbr }}. Failure to report
        changes by the deadline may render the RSS ineligible for future
        approval.
      </p>

      <h4>Required Review Form Submission</h4>
      <p>
        Beginning in 2021, any RSS granted NCAA approval must also submit a
        post-execution review form (PERF). The PERF includes, but is not limited
        to, a list of subscribers and a minimum of four (no maximum) RSS reports
        provided to NCAA coaches as part of their subscription. If the service
        is online and data is updated regularly instead of publishing reports,
        the service should submit a data report identifying the data/time when
        edits were made. The required reports/data may be submitted as they are
        published and provided to coaches during the year or all at once at the
        end of the approval season. All PERF information must be submitted and
        meet NCAA requirements <strong>BEFORE</strong> that application for the
        next year is processed.
      </p>

      <h4>Additional Help and Information</h4>
      <p>
        If you have questions, please visit the
        <a :href="linkUrlByName(linkNames.ECAG_FAQ)" target="_blank"
          >ECAG FAQ</a
        >
        page or you may contact the ECAG staff by calling 844/562-6201 and
        selecting option 3 or by using the
        <a :href="linkUrlByName(linkNames.EMAIL_CONTACT_FORM)" target="_blank"
          >Email Contact Form</a
        >.
      </p>
    </v-card-text>
    <v-card-actions v-show="!loggedIn">
      <v-btn text :to="{ name: 'login' }">Login</v-btn>
      <v-btn text :to="{ name: 'register' }">Register</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { formatCurrency, formatDate, linkNames } from "../utils/helpers";

export default {
  name: "DefaultHome",
  data() {
    return {
      linkNames: linkNames,
      siteAbbr: "RSSP",
      siteName: "Recruiting/Scouting Service Portal",
    };
  },
  methods: {
    formatCurrency(value) {
      return formatCurrency(value);
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
  computed: {
    ...mapActions("listOfValues", ["fetchCurrentYear"]),
    ...mapGetters("listOfValues", ["linkUrlByName"]),
    ...mapGetters("user", ["loggedIn"]),
    ...mapState("listOfValues", ["currentYear"]),
    siteNameWithAbbr() {
      return `${this.siteName} (${this.siteAbbr})`;
    },
  },
  async mounted() {
    await this.fetchCurrentYear;
  },
};
</script>

<style scoped>
/* match the color of text within the Graduated Fees table with that of a <p> tag */
table > tbody > tr > td {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
