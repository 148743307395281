<!-- A view component for display when page or api data cannot be found -->
<template>
  <v-alert prominent type="error">
    <v-row>
      <v-col class="grow">{{ message }}</v-col>
      <v-col class="shrink"
        ><v-btn link :to="{ name: 'home' }">Return to home page</v-btn></v-col
      >
    </v-row>
  </v-alert>
</template>

<script>
export default {
  computed: {
    message() {
      return this.$route.params.message;
    }
  }
};
</script>
