import httpClient from "./httpClient";
import Vue from "vue";

const CHILD_API_ENDPOINTS = {
  FETCH_USER: "/fetch_user"
};

const fetchUser = () => {
  Vue.prototype.$loading();
  return httpClient
    .get(CHILD_API_ENDPOINTS.FETCH_USER)
    .finally(() => Vue.prototype.$hideLoading());
};

export { fetchUser };
