<template>
  <div>
    <v-stepper-step
      :complete="complete"
      :editable="editable"
      :step="step"
      :edit-icon="editIcon"
      :color="editColor"
      :rules="[() => !showValidAlert]"
      v-on:click.native="$emit('clickStep')"
    >
      {{ label }}
      <small class="error--text" v-if="showValidAlert"
        >Validation Errors
      </small>
      <small class="deep-orange--text" v-else-if="showUnsavedAlert"
        >Unsaved Changes
      </small>
      <small class="warning--text" v-else-if="openConversation"
        >Information Requested
      </small>
    </v-stepper-step>
    <v-stepper-content :step="step" style="height: auto;">
      <slot name="content"></slot>
    </v-stepper-content>
  </div>
</template>

<script>
export default {
  name: "ApplicationStep",
  props: {
    step: {
      type: Number,
      required: true
    },
    complete: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    openConversation: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    componentRef: {
      type: Object,
      default: null
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  computed: {
    showUnsavedAlert() {
      return (
        this.componentRef &&
        this.componentRef.isDirty &&
        this.step !== this.currentStep
      );
    },
    showValidAlert() {
      return this.componentRef && !this.componentRef.isFormValid;
    },
    editIcon() {
      return this.showUnsavedAlert
        ? "fa-exclamation"
        : this.openConversation
        ? "fa-info"
        : "fa-pen";
    },
    editColor() {
      return this.showUnsavedAlert
        ? "deep-orange"
        : this.openConversation
        ? "warning"
        : "primary";
    }
  }
};
</script>

<style scoped>
.v-application--is-ltr
  .theme--light.v-stepper--vertical
  div:not(:last-child)
  .v-stepper__content {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.v-stepper__content > .v-stepper__wrapper {
  height: auto !important;
}
</style>
