import httpClient from "./httpClient";

const END_POINT = "/services";
const FORMAT_END_POINT = "format";

const getServiceFormat = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${FORMAT_END_POINT}`);
const updateServiceFormat = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_format: convertArraysToStrings(form) };
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${FORMAT_END_POINT}`,
    payload
  );
};

const createServiceFormat = data => {
  const payload = {
    service_format: convertArraysToStrings(data)
  };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${FORMAT_END_POINT}`,
    payload
  );
};

function convertArraysToStrings(data) {
  if (data.analysis_types) {
    data.analysis_types = data.analysis_types.join(",");
  }

  if (data.video_content_types) {
    data.video_content_types = data.video_content_types.join(",");
  }

  return data;
}

export { getServiceFormat, updateServiceFormat, createServiceFormat };
