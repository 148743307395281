<template>
  <v-dialog v-model="dialogOpen" scrollable persistent max-width="1000px">
    <Loading />
    <v-card>
      <v-toolbar color="grey" dark dense tile flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon @click.stop="newReply" class="mr-3" v-if="allowReply">
          <v-icon>$vuetify.icons.reply</v-icon>
        </v-btn>
        <v-btn small icon @click.stop="close">
          <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="height: 600px;">
        <v-list two-line key="main">
          <InboxMessageItem
            v-model="message"
            ref="main"
            v-on:close="close()"
            v-on:cancel="cancel()"
            v-on:onEdit="onEdit($event)"
          />
          <v-list two-line key="replies" class="ml-4">
            <template v-for="(reply, rIndex) in message.replies">
              <InboxMessageItem
                :value="reply"
                :key="rIndex"
                v-on:close="close()"
                v-on:cancel="cancel()"
                v-on:onEdit="onEdit($event)"
              />
            </template>
          </v-list>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InboxMessageItem from "./InboxMessageItem.vue";
import Loading from "./layout/Loading.vue";

export default {
  components: { Loading, InboxMessageItem },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
    ...mapState("inbox", ["message"]),
    ...mapGetters("user", ["isAdmin"]),
    dialogOpen: {
      set(dialogOpen) {
        this.$emit("input", dialogOpen);
      },
      get() {
        return this.value;
      }
    },
    title() {
      return this.message.id ? this.message.subject : "New Message";
    },
    allowReply() {
      return this.message.id && !this.editing;
    }
  },
  methods: {
    ...mapActions("inbox", ["setMessage", "removeUnsavedReply"]),
    ...mapActions("servicer", ["fetchServicers"]),
    close() {
      this.dialogOpen = false;
      this.setMessage({ ...this.defaultMessage });
    },
    cancel() {
      this.removeUnsavedReply();
      this.editing = false;
    },
    newReply() {
      const reply = {
        to_id:
          this.message.staff && this.isAdmin
            ? this.message.to_id
            : this.message.from_id,
        subject: this.message.subject,
        text: "",
        inbox_message_id: this.message.id
      };

      this.message.replies.push(reply);
      this.editing = true;
    },
    onEdit(value) {
      this.editing = value;
    }
  },
  async mounted() {
    if (this.isAdmin) {
      await this.fetchServicers();
    }
  }
};
</script>

<style scoped></style>
