<template>
  <v-dialog v-model="dialogOpen" scrollable max-width="1000px">
    <Loading />
    <v-card>
      <v-toolbar color="grey" dark dense>
        <v-icon class="pr-2">$vuetify.icons.clipboard</v-icon>
        <v-toolbar-title>Staff Notes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          small
          dark
          fab
          @click.stop="newNote"
          :disabled="editIndex >= 0"
        >
          <v-icon>$vuetify.icons.plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p
          v-if="staffNotes.length === 0"
          class="text-center grey--text text--lighten-1 mt-2"
        >
          <em>No staff notes yet!</em>
        </p>
        <v-list two-line v-else>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <template v-for="(item, index) in staffNotes">
              <v-list-item :key="index">
                <v-list-item-content v-if="isEditable(index)">
                  <v-row dense>
                    <v-col cols="12">
                      <TextAreaInput
                        label="Note"
                        v-model="item.note"
                        :rows="1"
                        :outlined="false"
                        :counter="4000"
                        :required="false"
                        :rules="noteValid"
                      ></TextAreaInput>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        label="Documents"
                        v-model="item.documents"
                        small-chips
                        multiple
                        :accept="acceptTypes"
                        :rules="filesValid"
                        hint="Valid file types include (5MB max): PDF, Images, MS Office Suite, Apple iWork Suite"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="primary" @click="save(item)">Save</v-btn>
                      <v-btn color="default" @click="cancel(item)"
                        >Cancel</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title
                    v-html="itemTitle(item)"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.note"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <template v-for="file in item.uploaded_files">
                      <v-chip
                        class="mt-2"
                        close
                        :href="file.file_url"
                        @click:close="deleteFile(item.id, file.id)"
                        :key="file.id"
                        >{{ file.file_name }}</v-chip
                      >
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="!isEditable(index)">
                  <div>
                    <v-btn x-small fab @click="editIndex = index">
                      <v-icon color="primary">$vuetify.icons.edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab @click="openDeleteDialog(item, index)">
                      <v-icon color="error">$vuetify.icons.delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < staffNotes.length"
                :key="'d_' + index"
              ></v-divider>
            </template>
          </v-form>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialogOpen = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="350px">
      <v-card>
        <v-card-title class="headline">
          Delete Note?
        </v-card-title>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeDeleteDialog()">
            Cancel
          </v-btn>
          <v-btn color="error" @click="remove(deleteItem, deleteIndex)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TextAreaInput from "./form/TextAreaInput.vue";
import { acceptTypes } from "../utils/helpers";
import HttpStatus from "http-status";
import {
  createStaffNote,
  deleteStaffNote,
  deleteStaffNoteFile,
  updateStaffNote
} from "../api/servicerStaffNotes.api";
import Loading from "./layout/Loading.vue";

export default {
  components: { Loading, TextAreaInput },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultNote: {
        note: "",
        documents: []
      },
      isFormValid: true,
      acceptTypes: acceptTypes,
      editIndex: -1,
      dialogDelete: false,
      deleteItem: {},
      deleteIndex: -1
    };
  },
  computed: {
    ...mapState("servicer", ["servicer", "staffNotes"]),
    dialogOpen: {
      set(dialogOpen) {
        this.$emit("input", dialogOpen);
      },
      get() {
        return this.value;
      }
    },
    staffNote() {
      return this.editIndex >= 0 ? this.staffNotes[this.editIndex] : null;
    },
    filesValid() {
      let sizeValid = true;
      this.staffNote.documents.forEach(file => {
        if (file.size > 5000000) {
          sizeValid = false;
        }
      });
      return [sizeValid || "Files may not exceed 5MB in size"];
    },
    noteValid() {
      return [
        this.staffNote.note.trim().length > 0 ||
          this.staffNote.documents.length > 0 ||
          "Note or Document Upload is required."
      ];
    }
  },
  methods: {
    ...mapActions("servicer", [
      "fetchStaffNotes",
      "addNewStaffNote",
      "updateStaffNote",
      "removeStaffNote",
      "removeNewStaffNote"
    ]),
    isEditable(index) {
      return index === this.editIndex;
    },
    itemTitle(item) {
      const subtext = `<small class="grey--text text--lighten-1 ml-2">${item.note_date}</small>`;
      return item.user + subtext;
    },
    newNote() {
      if (this.editIndex === -1) {
        this.addNewStaffNote({ ...this.defaultNote });
        this.editIndex = 0;
      }
    },
    save(item) {
      if (this.$refs.form.validate()) {
        this.$loading();
        !item.id ? this.createItem(item) : this.updateItem(item);
      }
    },
    createItem(item) {
      return createStaffNote(this.servicer.id, item)
        .then(response =>
          this.processSaveResponse(response, HttpStatus.CREATED)
        )
        .finally(() => this.$hideLoading());
    },
    updateItem(item) {
      return updateStaffNote(this.servicer.id, item)
        .then(response => this.processSaveResponse(response, HttpStatus.OK))
        .finally(() => this.$hideLoading());
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.updateStaffNote(response.data);
        this.editIndex = -1;
        return true;
      } else {
        return false;
      }
    },
    openDeleteDialog(item, index) {
      this.deleteItem = item;
      this.deleteIndex = index;
      this.dialogDelete = true;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.deleteItem = {};
      this.deleteIndex = -1;
    },
    remove(item, index) {
      this.dialogDelete = false;
      this.$loading();
      return deleteStaffNote(item.id, this.servicer.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            this.removeStaffNote(index);
            this.closeDeleteDialog();
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteFile(itemId, fileId) {
      this.$loading();
      return deleteStaffNoteFile(itemId, this.servicer.id, fileId)
        .then(response => this.processSaveResponse(response, HttpStatus.OK))
        .finally(() => this.$hideLoading());
    },
    cancel(item) {
      if (!item.id) {
        this.removeNewStaffNote();
      }
      this.editIndex = -1;
    }
  },
  async mounted() {
    await this.fetchStaffNotes(this.servicer.id);
  }
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  white-space: normal;
}
</style>
