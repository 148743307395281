const defaultService = {
  id: null,
  name: null,
  year: null
};

export default {
  namespaced: true,
  state: {
    confirmPaymentDialog: false,
    service: defaultService
  },
  getters: {},
  mutations: {
    SHOW_CONFIRM_PAYMENT_DIALOG(state, service) {
      state.confirmPaymentDialog = true;
      state.service.id = service.id;
      state.service.name = service.name;
      state.service.year = service.year;
    },
    CLOSE_CONFIRM_PAYMENT_DIALOG(state) {
      state.confirmPaymentDialog = false;
    }
  },
  actions: {
    showConfirmPaymentDialog({ commit }, service) {
      commit("SHOW_CONFIRM_PAYMENT_DIALOG", service);
    },
    closeConfirmPaymentDialog({ commit }) {
      commit("CLOSE_CONFIRM_PAYMENT_DIALOG");
    }
  }
};
