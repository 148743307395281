import httpClient from "./httpClient";
import { convertArraysToStrings } from "../utils/helpers";

const END_POINT = "/services";
const RELATIONSHIP_END_POINT = "relationship";

const getServiceRelationship = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${RELATIONSHIP_END_POINT}`);

const updateServiceRelationship = data => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${RELATIONSHIP_END_POINT}`,
    prepareData(form)
  );
};

const createServiceRelationship = data => {
  const form = { ...data };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${RELATIONSHIP_END_POINT}`,
    prepareData(form)
  );
};

function prepareData(data) {
  for (const key in data) {
    if (key.startsWith("schools_")) {
      data[key] = convertArraysToStrings(data[key]);
    }
  }

  return { service_relationship: data };
}

export {
  getServiceRelationship,
  updateServiceRelationship,
  createServiceRelationship
};
