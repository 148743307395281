<template>
  <v-input :value="scouts" :rules="[rules.atLeastOne]">
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="scouts"
          :loading="loadingTable"
          :loading-text="loadingText"
          :hide-default-footer="disabled"
          :disable-sort="disabled"
          sort-by="last_name"
          class="elevation-1"
        >
          <template v-slot:no-data>
            <em>No records yet!</em>
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Scouts</v-toolbar-title>
              <v-spacer />
              <v-dialog v-model="dialogForm" max-width="500px">
                <template v-if="!disabled" v-slot:activator="{ on }">
                  <v-btn color="success" v-on="on">Add Scout</v-btn>
                </template>
                <v-form ref="form" v-model="isFormValid" lazy-validation>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              label="First Name"
                              v-model="editedItem.first_name"
                              :rules="[rules.required, rules.firstName]"
                              :disabled="disabled"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              label="Last Name"
                              v-model="editedItem.last_name"
                              :rules="[rules.required, rules.lastName]"
                              :disabled="disabled"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="Responsibility/Role"
                              v-model="editedItem.responsibility"
                              :rules="[rules.required, rules.responsibility]"
                              :disabled="disabled"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn text @click="close">Cancel</v-btn>
                      <v-btn color="primary" @click="save">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="!disabled"
              small
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              $vuetify.icons.edit
            </v-icon>
            <v-icon
              v-if="!disabled"
              small
              @click="openDeleteDialog(item)"
              color="error"
            >
              $vuetify.icons.delete
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="350px">
          <v-card>
            <v-card-title class="headline">
              Delete Scout?
            </v-card-title>

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="close">
                Cancel
              </v-btn>
              <v-btn color="error" @click="deleteItem">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div v-if="isInvalid" class="error--text">
          Please enter at least one record.
        </div>
      </v-col>
    </v-row>
  </v-input>
</template>

<script>
import { loadingTextForTable } from "../../utils/helpers";
import { required } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import {
  createServiceScout,
  deleteServiceScout,
  updateServiceScout
} from "../../api/serviceScouts.api";

export default {
  name: "Scouts",
  props: {
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Responsibility/Role", value: "responsibility" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        service_id: 0,
        first_name: "",
        last_name: "",
        responsibility: ""
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        responsibility: ""
      },
      rules: {
        atLeastOne: v => v.length > 0,
        required,
        firstName: v =>
          (v && v.toString().length <= 50) ||
          "First Name can only be 50 characters",
        lastName: v =>
          (v && v.toString().length <= 50) ||
          "Last Name can only be 50 characters",
        responsibility: v =>
          (v && v.toString().length <= 100) ||
          "Last Name can only be 100 characters"
      }
    };
  },
  computed: {
    ...mapState({
      service: state => state.service.service
    }),
    scouts() {
      return this.$store.state.service.service.service_scouts;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Scout" : "Edit Scout";
    },
    isInvalid() {
      return !this.loadingTable && this.scouts.length === 0;
    }
  },
  methods: {
    ...mapActions({
      setServiceScouts: "service/setServiceScouts",
      addServiceScout: "service/addServiceScout",
      updateServiceScout: "service/updateServiceScout",
      removeServiceScout: "service/removeServiceScout"
    }),
    editItem(item) {
      this.editedIndex = this.scouts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.scouts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedItem.service_id = this.service.id;
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createServiceScout(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addServiceScout(response.data);
            this.$notify.success("Scout added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateServiceScout(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateServiceScout(response.data);
            this.$notify.success("Scout updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteServiceScout(this.editedItem.id, this.service.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeServiceScout(this.editedIndex);
            this.$notify.success("Scout removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    }
  }
};
</script>
