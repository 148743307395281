<template>
  <v-dialog v-model="dialogOpen" max-width="350px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ regionLabel(item.geo_scope) }}</span>
      </v-card-title>

      <v-card-text>
        <div v-if="item.geo_countries && item.geo_countries.length > 0">
          <h5>Countries:</h5>
          <div
            v-for="(country, index) in item.geo_countries"
            :key="'country_' + index"
          >
            {{ countryLabel(country) }}
            <div
              class="ml-4"
              v-if="item.geo_states && item.geo_states.length > 0"
            >
              <h5>States/Provinces:</h5>
              <div
                v-for="(state, index) in item.geo_states"
                :key="'state_' + index"
              >
                {{ stateLabel(country, state) }}
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    dialogOpen: {
      set(dialogOpen) {
        this.$emit("input", dialogOpen);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    close() {
      this.dialogOpen = false;
    },
    regionLabel(value) {
      return this.$store.getters["listOfValues/regionLabel"](value);
    },
    countryLabel(value) {
      return this.$store.getters["listOfValues/countryLabel"](value);
    },
    stateLabel(country, state) {
      return this.$store.getters["listOfValues/stateLabel"](country, state);
    }
  }
};
</script>

<style scoped></style>
