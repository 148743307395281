import {
  getApprovedServices,
  getService,
  getServices,
  getYear,
  getYears
} from "../../api/services.api";
import { findIndex, filter } from "lodash";

const newService = {
  id: "",
  payment_id: "",
  servicer_id: "",
  status_id: "",
  can_edit: true,
  can_view_comments: false,
  can_perf: false,
  service_identity: {
    service_id: null,
    name: "",
    website: "",
    year_established: "",
    federal_tax_id: "",
    sub_start_date: "",
    sub_end_date: "",
    mfb: false,
    mbb: false,
    wbb: false,
    mbb_nonprofit: null,
    mbb_nonprofit_donations: null,
    mbb_nonprofit_iawps: null,
    mbb_nonprofit_desc: "",
    service_social_media_accts_attributes: []
  },
  service_format: {
    video_only: null
  },
  service_promotion: {},
  service_staff: {},
  service_associates: [],
  service_scouts: [],
  service_sample_links: [],
  service_perf_reports: [],
  subscribers: []
};

export default {
  namespaced: true,
  state: {
    services: [],
    year: {},
    years: [],
    service: newService,
    service_format: {
      video_only: null
    },
    approvedServices: []
  },
  getters: {
    getServiceAssociatesByTypeId: state => typeId => {
      return filter(
        state.service.service_associates,
        associate => associate.type_id === typeId
      );
    },
    getApprovedServicesByYear: state => yearId => {
      return filter(
        state.approvedServices,
        service => service.year_id === yearId
      );
    }
  },
  mutations: {
    SET_YEAR(state, year) {
      state.year = year;
    },
    SET_YEARS(state, years) {
      state.years = years;
    },
    RESET_SERVICE(state) {
      state.service = { ...newService };
      state.service.service_format = {};
    },
    SET_SERVICES(state, services) {
      // eslint-disable-next-line no-param-reassign
      state.services = services;
    },
    SET_SERVICE(state, service) {
      // eslint-disable-next-line no-param-reassign
      state.service = service;
    },
    SET_SERVICE_STATUS(state, statusLog) {
      state.service.status = statusLog.status;
      state.service.status_id = statusLog.status_id;
    },
    SET_IDENTITY(state, identity) {
      state.service.service_identity = identity;
    },
    SET_SERVICE_FORMAT(state, format) {
      state.service.service_format = format;
      state.service_format = format;
    },
    SET_SERVICE_TYPE(state, type) {
      state.service.service_type = type;
    },
    SET_SERVICE_RELATIONSHIP(state, relationship) {
      state.service.service_relationship = relationship;
    },
    SET_SERVICE_PROMOTION(state, payload) {
      state.service.service_promotion = payload;
    },
    SET_SERVICE_STAFF(state, staff) {
      state.service.service_staff = staff;
    },
    SET_SERVICE_UPLOADS(state, service) {
      state.service.uploaded_files = service.uploaded_files;
      state.service.service_sample_links = service.service_sample_links;
    },
    REMOVE_SERVICE: (state, index) => state.services.splice(index, 1),
    SET_SERVICE_ASSOCIATES(state, payload) {
      state.service.service_associates = payload;
    },
    ADD_SERVICE_ASSOCIATE: (state, payload) =>
      state.service.service_associates.push(payload),
    UPDATE_SERVICE_ASSOCIATE: (state, payload) => {
      const index = findIndex(
        state.service.service_associates,
        associate => associate.id === payload.id
      );
      Object.assign(state.service.service_associates[index], payload);
    },
    REMOVE_SERVICE_ASSOCIATE: (state, index) =>
      state.service.service_associates.splice(index, 1),
    SET_SERVICE_SCOUTS: (state, payload) =>
      (state.service.service_scouts = payload),
    ADD_SERVICE_SCOUT: (state, payload) =>
      state.service.service_scouts.push(payload),
    UPDATE_SERVICE_SCOUT: (state, payload) => {
      const index = findIndex(
        state.service.service_scouts,
        scout => scout.id === payload.id
      );
      Object.assign(state.service.service_scouts[index], payload);
    },
    REMOVE_SERVICE_SCOUT: (state, index) =>
      state.service.service_scouts.splice(index, 1),
    SET_APPROVED_SERVICES: (state, approvedServices) => {
      state.approvedServices = approvedServices;
    },
    SET_SERVICE_SAMPLE_LINKS(state, payload) {
      state.service.service_sample_links = payload;
    },
    ADD_SERVICE_SAMPLE_LINK: (state, payload) =>
      state.service.service_sample_links.push(payload),
    UPDATE_SERVICE_SAMPLE_LINK: (state, payload) => {
      const index = findIndex(
        state.service.service_sample_links,
        sampleLink => sampleLink.id === payload.id
      );
      Object.assign(state.service.service_sample_links[index], payload);
    },
    REMOVE_SERVICE_SAMPLE_LINK: (state, index) =>
      state.service.service_sample_links.splice(index, 1),
    SET_SERVICE_SUBSCRIBERS(state, payload) {
      state.service.subscribers = payload;
    },
    SET_SERVICE_PERF_REPORTS(state, payload) {
      state.service.service_perf_reports = payload;
    },
    ADD_SERVICE_PAYMENT_TO_SERVICE(state, payload) {
      const service = state.services.find(
        service => service.id === payload.service_id
      );
      const isPaymentAlreadyInArray =
        service.service_payments.filter(
          servicePayment => servicePayment.payment_id === payload.payment_id
        ).length > 0;

      // only add service_payment if the service does not already have the payment_id
      if (!isPaymentAlreadyInArray) {
        service.service_payments.push(payload);
      }
    }
  },
  actions: {
    async fetchService({ commit }, id) {
      try {
        const response = await getService(id);

        if (response.status === 200) {
          commit("SET_SERVICE", response.data);
          if (response.data.servicer) {
            commit("servicer/SET_SERVICER", response.data.servicer, {
              root: true
            });
          }
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    async fetchServices({ commit }) {
      try {
        const response = await getServices();

        if (response.status === 200) {
          commit("SET_SERVICES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    setService({ commit }, service) {
      commit("SET_SERVICE", service);
    },
    setServiceIdentity({ commit }, serviceIdentity) {
      commit("SET_IDENTITY", serviceIdentity);
    },
    setServiceFormat({ commit }, serviceFormat) {
      commit("SET_SERVICE_FORMAT", serviceFormat);
    },
    setServiceType({ commit }, serviceType) {
      commit("SET_SERVICE_TYPE", serviceType);
    },
    setServiceRelationship({ commit }, serviceRelationship) {
      commit("SET_SERVICE_RELATIONSHIP", serviceRelationship);
    },
    setServicePromotion({ commit }, payload) {
      commit("SET_SERVICE_PROMOTION", payload);
    },
    setServiceStaff({ commit }, serviceStaff) {
      commit("SET_SERVICE_STAFF", serviceStaff);
    },
    setServiceUploads({ commit }, service) {
      commit("SET_SERVICE_UPLOADS", service);
    },
    setServiceAssociates({ commit }, payload) {
      commit("SET_SERVICE_ASSOCIATES", payload);
    },
    addServiceAssociate({ commit }, payload) {
      commit("ADD_SERVICE_ASSOCIATE", payload);
    },
    updateServiceAssociate({ commit }, payload) {
      commit("UPDATE_SERVICE_ASSOCIATE", payload);
    },
    removeServiceAssociate({ commit }, idToDelete) {
      commit("REMOVE_SERVICE_ASSOCIATE", idToDelete);
    },
    setServiceScouts({ commit }, payload) {
      commit("SET_SERVICE_SCOUTS", payload);
    },
    addServiceScout({ commit }, payload) {
      commit("ADD_SERVICE_SCOUT", payload);
    },
    updateServiceScout({ commit }, payload) {
      commit("UPDATE_SERVICE_SCOUT", payload);
    },
    removeServiceScout({ commit }, idToDelete) {
      commit("REMOVE_SERVICE_SCOUT", idToDelete);
    },
    resetService({ commit }) {
      commit("RESET_SERVICE");
    },
    removeService({ commit }, serviceIndex) {
      commit("REMOVE_SERVICE", serviceIndex);
    },
    async fetchYear({ commit }) {
      try {
        const response = await getYear();

        if (response.status === 200) {
          commit("SET_YEAR", response.data);
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    async fetchYears({ commit }) {
      try {
        const response = await getYears();

        if (response.status === 200) {
          commit("SET_YEARS", response.data);
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    async fetchApprovedServices({ commit }) {
      try {
        const response = await getApprovedServices();

        if (response.status === 200) {
          commit("SET_APPROVED_SERVICES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    setServiceSampleLinks({ commit }, payload) {
      commit("SET_SERVICE_SAMPLE_LINKS", payload);
    },
    addServiceSampleLink({ commit }, payload) {
      commit("ADD_SERVICE_SAMPLE_LINK", payload);
    },
    updateServiceSampleLink({ commit }, payload) {
      commit("UPDATE_SERVICE_SAMPLE_LINK", payload);
    },
    removeServiceSampleLink({ commit }, idToDelete) {
      commit("REMOVE_SERVICE_SAMPLE_LINK", idToDelete);
    },
    updateServicePerf({ commit }, payload) {
      commit("SET_SERVICE_SUBSCRIBERS", payload.subscribers);
      commit("SET_SERVICE_PERF_REPORTS", payload.service_perf_reports);
    },
    addServicePaymentToService({ commit }, payload) {
      commit("ADD_SERVICE_PAYMENT_TO_SERVICE", payload);
    }
  }
};
