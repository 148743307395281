import httpClient from "./httpClient";

const END_POINT = "/admin/cheatsheets";

const getCheatsheets = () => httpClient.get(`${END_POINT}`);
const getForms = () => httpClient.get(`${END_POINT}/forms`);

const createCheatsheet = data => {
  const payload = {
    cheatsheet: data
  };
  return httpClient.post(`${END_POINT}`, payload);
};

const updateCheatsheet = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { cheatsheet: form };
  return httpClient.put(`${END_POINT}/${id}`, payload);
};

const deleteCheatsheet = id => httpClient.delete(`${END_POINT}/${id}`);

export {
  getCheatsheets,
  createCheatsheet,
  updateCheatsheet,
  deleteCheatsheet,
  getForms
};
