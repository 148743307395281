<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Status Maintenance</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="statuses"
        :loading="loadingTable"
        :loading-text="loadingText"
        sort-by="sort_order"
      >
        <template v-slot:no-data>
          <em>No records yet!</em>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-dialog v-model="dialogForm" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on">Add Status</v-btn>
              </template>
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Description"
                            v-model="editedItem.description"
                            :rules="[rules.required]"
                            maxlength="100"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Sort Order"
                            type="number"
                            v-model="editedItem.sort_order"
                            :rules="[rules.required]"
                            max="100"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-switch
                            v-model="editedItem.active"
                            :disabled="disabled"
                            label="Active"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-switch
                            v-model="editedItem.system"
                            disabled
                            label="System (Not Editable)"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-switch
                            v-model="editedItem.final"
                            label="Final"
                            :disabled="disabled"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.description }}</td>
            <td>{{ props.item.sort_order }}</td>
            <td>{{ formatBoolean(props.item.active) }}</td>
            <td>{{ formatBoolean(props.item.system) }}</td>
            <td>{{ formatBoolean(props.item.final) }}</td>
            <td>{{ formatDate(props.item.created_at) }}</td>
            <td>{{ formatDate(props.item.updated_at) }}</td>
            <td class="text-end">
              <v-icon
                v-if="!disabled"
                small
                class="mr-2"
                @click="editItem(props.item)"
                color="primary"
              >
                $vuetify.icons.edit
              </v-icon>
              <v-icon
                v-if="!disabled"
                :disabled="!props.item.can_delete"
                small
                @click="openDeleteDialog(props.item)"
                color="error"
              >
                $vuetify.icons.delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Delete Status?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteItem">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { loadingTextForTable, formatDate } from "../../utils/helpers";
import { required } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import { createStatus, deleteStatus, updateStatus } from "../../api/status.api";

export default {
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      disabled: false,
      headers: [
        { text: "Description", value: "description" },
        { text: "Sort Order", value: "sort_order" },
        { text: "Active", value: "active" },
        { text: "System", value: "system" },
        { text: "Final", value: "final" },
        { text: "Created", value: "created_at" },
        { text: "Updated", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        description: "",
        sort_order: null,
        active: true,
        system: false,
        final: false
      },
      defaultItem: {
        description: "",
        sort_order: null,
        active: true,
        system: false,
        final: false
      },
      rules: {
        required
      }
    };
  },
  computed: {
    ...mapState("admin", ["statuses"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Status" : "Edit Status";
    }
  },
  methods: {
    ...mapActions("admin", [
      "fetchStatuses",
      "addStatus",
      "updateStatus",
      "removeStatus"
    ]),
    editItem(item) {
      this.editedIndex = this.statuses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.disabled = this.editedItem.system;
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.statuses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.disabled = false;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createStatus(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addStatus(response.data);
            this.$notify.success("Status added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateStatus(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateStatus(response.data);
            this.$notify.success("Status updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteStatus(this.editedItem.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeStatus(this.editedIndex);
            this.$notify.success("Status removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    formatBoolean(flag) {
      return flag ? "Yes" : "No";
    },
    formatDate(date) {
      return formatDate(date);
    }
  },
  mounted() {
    this.loadingTable = true;
    this.fetchStatuses().then(statuses => {
      this.loadingTable = false;
    });
  }
};
</script>
