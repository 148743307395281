<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle">Staff</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-label
              >Does the scouting service employ additional scouts?</v-label
            >
          </v-col>
          <v-col>
            <YesNoButtonGroup
              v-model="service_staff.employ_additional_scouts"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row v-if="service_staff.employ_additional_scouts">
          <v-col cols="12" v-if="!disabled">
            <v-label
              >Use the button below to add each scout that is currently employed
              by the service.</v-label
            >
          </v-col>
          <v-col cols="12"><Scouts :disabled="disabled"/></v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  createServiceStaff,
  updateServiceStaff
} from "../../api/serviceStaff.api";
import HttpStatus from "http-status";
import Scouts from "./Scouts.vue";
import YesNoButtonGroup from "../form/YesNoButtonGroup.vue";
import { cloneDeep, isEqual } from "lodash";

export default {
  name: "Staff",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Scouts,
    YesNoButtonGroup
  },
  data() {
    return {
      isFormValid: true,
      service_staff: {
        id: null,
        service_id: null,
        employ_additional_scouts: null
      }
    };
  },
  computed: {
    ...mapState({
      service: state => state.service.service
    }),
    isDirty() {
      return (
        this.service_staff.id &&
        !isEqual(this.service_staff, this.service.service_staff)
      );
    },
    isNew() {
      return (
        this.service_staff.id === null || this.service_staff.id === undefined
      );
    }
  },
  methods: {
    ...mapActions("service", ["setServiceStaff"]),
    initialize() {
      if (!this.service_staff.id) {
        this.reset();
      }
    },
    reset() {
      this.service_staff = cloneDeep(this.service.service_staff);
      this.$refs.form.resetValidation();
    },
    isValid() {
      return this.$refs.form.validate();
    },
    save() {
      if (this.isValid()) {
        this.service_staff.service_id = this.service.id;
        this.$loading();
        if (this.service_staff.id) {
          return this.update();
        } else {
          return this.create();
        }
      }
    },
    create() {
      return createServiceStaff(this.service_staff)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.setServiceStaff(response.data);
            this.service_staff = cloneDeep(response.data);
            this.$notify.success("Service Staff form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    update() {
      return updateServiceStaff(this.service_staff)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.setServiceStaff(response.data);
            this.service_staff = cloneDeep(response.data);
            this.$notify.success("Service Staff form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    }
  }
};
</script>
