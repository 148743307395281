import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
    values: {
      logout: "fa-sign-out-alt",
      passwordShow: "fa-eye",
      passwordHide: "fa-eye-slash",
      delete: "fa-trash-alt",
      edit: "fa-pen",
      externalLink: "fa-external-link-alt",
      pay: "fa-dollar-sign",
      payments: "fa-file-invoice-dollar",
      reset: "fa-undo",
      user: "fa-user",
      view: "fa-list",
      plus: "fa-plus",
      comment: "fa-comment-alt",
      reply: "fa-reply",
      close: "fa-window-close",
      closeX: "fa-times",
      info: "fa-info",
      history: "fa-history",
      email: "fa-envelope",
      emailOpen: "fa-envelope-open",
      print: "fa-print",
      clipboard: "fa-clipboard",
      suspend: "fa-user-slash",
      tasks: "fa-tasks",
      flagged: "fa-flag",
      notFlagged: "far fa-flag",
      exclaim: "fa-exclamation-triangle",
      question: "fa-question-circle"
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#007ac3",
        secondary: "#b0bec5",
        accent: "#8c9eff",

        success: "#51A351",
        error: "#b71c1c",
        info: "#2F96B4",
        warning: "#F89406"
      }
    }
  }
});
