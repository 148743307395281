import httpClient from "./httpClient";

const END_POINT = "/services";
const SCOUTS_END_POINT = "scouts";

const getServiceScouts = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${SCOUTS_END_POINT}`);

const createServiceScout = data => {
  const payload = {
    service_scout: data
  };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${SCOUTS_END_POINT}`,
    payload
  );
};

const updateServiceScout = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_scout: form };
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${SCOUTS_END_POINT}/${data.id}`,
    payload
  );
};

const deleteServiceScout = (id, serviceId) =>
  httpClient.delete(`${END_POINT}/${serviceId}/${SCOUTS_END_POINT}/${id}`);

export {
  getServiceScouts,
  createServiceScout,
  updateServiceScout,
  deleteServiceScout
};
