<template>
  <v-row justify="center">
    <v-dialog
      v-model="purgeDialog"
      fullscreen
      hide-overlay
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Purge Records for {{ year.year }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            dark
            color="error"
            @click="purgeRecords"
            :loading="purgingInProcess"
            :disabled="purgingInProcess"
            >Purge All</v-btn
          >
        </v-toolbar>
        <v-data-table
          :headers="serviceHeaders"
          :items="purgeable_services"
          :loading="loadingServicesTable"
          :loading-text="loadingText"
          dense
          no-data-text="No services will be purged"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <span class="headline">Services</span>
            </v-toolbar>
          </template>
        </v-data-table>
        <v-divider />
        <v-data-table
          :headers="servicerHeaders"
          :items="purgeable_servicers"
          :loading="loadingServicersTable"
          :loading-text="loadingText"
          dense
          no-data-text="No servicers will be purged"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <span class="headline">Servicers</span>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { loadingTextForTable } from "../../utils/helpers";
import { purgeYear } from "../../api/years.api";
import HttpStatus from "http-status";

export default {
  name: "Purge",
  data() {
    return {
      purgingInProcess: false,
      purgeDialog: false,
      year: {},
      loadingServicesTable: false,
      loadingServicersTable: false,
      loadingText: loadingTextForTable,
      serviceHeaders: [
        { text: "Year", value: "year", sortable: false },
        { text: "Name", value: "name" },
        { text: "Servicer", value: "servicer_name" },
        { text: "Status", value: "status" }
      ],
      servicerHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" }
      ]
    };
  },
  computed: {
    ...mapState("admin", ["purgeable_services", "purgeable_servicers"])
  },
  methods: {
    ...mapActions("admin", [
      "fetchPurgeableServices",
      "fetchPurgeableServicers",
      "removeYear"
    ]),
    openDialog(year) {
      this.year = year;
      this.purgeDialog = true;
      this.loadServices();
      this.loadServicers();
    },
    closeDialog() {
      this.purgeDialog = false;
    },
    loadServices() {
      this.loadingServicesTable = true;
      this.fetchPurgeableServices(this.year.id).then(
        () => (this.loadingServicesTable = false)
      );
    },
    loadServicers() {
      this.loadingServicersTable = true;
      this.fetchPurgeableServicers(this.year.id).then(
        () => (this.loadingServicersTable = false)
      );
    },
    purgeRecords() {
      this.purgingInProcess = true;

      const servicerIdsToPurge = this.purgeable_servicers.map(
        servicer => servicer.id
      );

      purgeYear(this.year.id, servicerIdsToPurge)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.removeYear(this.year.id);
            this.closeDialog();
            this.$notify.success("Year purged successfully");
          }
        })
        .catch(() =>
          this.$notify.error(
            `An error occurred while purging records for ${this.year.year}`
          )
        )
        .finally(() => (this.purgingInProcess = false));
    }
  }
};
</script>
