<template>
  <v-dialog v-model="dialogOpen" max-width="350px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ item.name }}</span>
        <v-spacer></v-spacer>
        <span class="currency">{{ formatCurrency(item.amount) }}</span>
      </v-card-title>

      <v-card-text>
        <p v-text="item.description"></p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatCurrency } from "../utils/helpers";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    dialogOpen: {
      set(dialogOpen) {
        this.$emit("input", dialogOpen);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    close() {
      this.dialogOpen = false;
    },
    formatCurrency(value) {
      return formatCurrency(value);
    }
  }
};
</script>

<style scoped></style>
