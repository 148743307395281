<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle"
      >Relationships</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="pl-0">
                      <v-label
                        >Identify all institutions that apply to each of the
                        following questions.</v-label
                      >
                    </td>
                    <td>
                      <v-checkbox
                        label="None of the Following Apply"
                        v-model="service_relationship.no_relationships"
                        class="mt-0"
                        :disabled="disabled || hasRelationships"
                        :rules="relationshipsRequired"
                      ></v-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label
                        >A. Been a member of a booster organization that
                        supports an NCAA institution?</v-label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="service_relationship.schools_booster_org"
                        :items="institutionOptions"
                        multiple
                        small-chips
                        deletable-chips
                        :menu-props="{ allowOverflow: true }"
                        :disabled="
                          disabled || service_relationship.no_relationships
                        "
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label
                        >B. Made financial contributions to the athletics
                        department or a booster organization?</v-label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="service_relationship.schools_fin_contrib"
                        :items="institutionOptions"
                        multiple
                        small-chips
                        deletable-chips
                        :menu-props="{ allowOverflow: true }"
                        :disabled="
                          disabled || service_relationship.no_relationships
                        "
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label
                        >C. Been involved in promoting an NCAA institution's
                        athletics program?</v-label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="service_relationship.schools_promote"
                        :items="institutionOptions"
                        multiple
                        small-chips
                        deletable-chips
                        :menu-props="{ allowOverflow: true }"
                        :disabled="
                          disabled || service_relationship.no_relationships
                        "
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label
                        >D. Provided or helped to arrange employment for NCAA
                        student-athletes?</v-label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="service_relationship.schools_sa_emp"
                        :items="institutionOptions"
                        multiple
                        small-chips
                        deletable-chips
                        :menu-props="{ allowOverflow: true }"
                        :disabled="
                          disabled || service_relationship.no_relationships
                        "
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label
                        >E. Been a season ticket holder at an NCAA
                        institution?</v-label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="service_relationship.schools_season_ticket"
                        :items="institutionOptions"
                        multiple
                        small-chips
                        deletable-chips
                        :menu-props="{ allowOverflow: true }"
                        :disabled="
                          disabled || service_relationship.no_relationships
                        "
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label
                        >F. Are you the parent or legal guardian of an enrolled
                        NCAA student-athlete?</v-label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="service_relationship.schools_sa_parent"
                        :items="institutionOptions"
                        multiple
                        small-chips
                        deletable-chips
                        :menu-props="{ allowOverflow: true }"
                        :disabled="
                          disabled || service_relationship.no_relationships
                        "
                      ></v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Describe the involvement that you, your staff, or your service
              have in the recruiting process?</v-label
            >
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_relationship.recruiting_involvement"
              :disabled="disabled"
            ></TextAreaInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Do you, or anyone completing work on your behalf, have personal
              interactions with prospects?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.psa_interact"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row v-if="service_relationship.psa_interact" class="ml-4">
          <v-col cols="12">
            <v-label
              >Please describe those interactions completely including a
              description of how/where those interactions occur, purpose for the
              interactions, types of information shared and nature of the
              relationships.</v-label
            >
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_relationship.psa_interact_desc"
              :required="service_relationship.psa_interact"
              :disabled="disabled"
            ></TextAreaInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Do you, or your staff, have any communication with NCAA coaches
              or staff members?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.coach_comm"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row v-if="service_relationship.coach_comm" class="ml-4">
          <v-col cols="12">
            <v-label
              >Describe the basis, purpose, and context of these
              communications.</v-label
            >
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_relationship.coach_comm_desc"
              :required="service_relationship.coach_comm"
              :disabled="disabled"
            ></TextAreaInput>
          </v-col>
        </v-row>
        <v-row v-if="service_relationship.coach_comm" class="ml-4">
          <v-col cols="12">
            <v-label
              >Do you understand that you may clarify information that is
              included in your reports, but it is not permissible for
              recruiting/scouting services to provide oral/electronic reports to
              coaches about prospects beyond the standardized, consistent
              information provided to all subscribers?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.coach_comm_ack"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Do you, or anyone completing work on your behalf, operate camps
              or clinics?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.camp_oper"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row v-if="service_relationship.camp_oper" class="ml-4">
          <v-col cols="12">
            <v-label
              >Are NCAA athletics staff members employed (either on a salaried
              or a volunteer basis) in any capacity with your camps or
              clinics?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.camp_oper_ncaa"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row
          v-if="
            service_relationship.camp_oper &&
            service_relationship.camp_oper_ncaa
          "
          class="ml-8"
        >
          <v-col cols="12">
            <v-label
              >Please identify each and explain their involvement. Include a
              description of the role they fulfill and identify any payment or
              exchange of benefit involved.</v-label
            >
          </v-col>
          <v-col cols="12">
            <Associates
              :type-id="
                getServiceAssociationIdByName('relationship_coach_employs')
              "
              :disabled="disabled"
              required
            />
          </v-col>
        </v-row>
        <v-row v-if="service_relationship.camp_oper" class="ml-4">
          <v-col cols="12">
            <v-label
              >Were any NCAA athletics staff members present at your camps or
              clinics?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.camp_attend_ncaa"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row
          v-if="
            service_relationship.camp_oper &&
            service_relationship.camp_attend_ncaa
          "
          class="ml-8"
        >
          <v-col cols="12">
            <v-label>Please identify the coaches in attendance.</v-label>
          </v-col>
          <v-col cols="12">
            <Associates
              :type-id="
                getServiceAssociationIdByName('relationship_coach_attends')
              "
              :disabled="disabled"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Are you, or anyone completing work on behalf of your service,
              also working (paid or unpaid) for an NCAA institution?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_relationship.emp_ncaa_school"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row v-if="service_relationship.emp_ncaa_school" class="ml-4">
          <v-col cols="12">
            <v-label
              >Identify each individual's name and the name of the involved
              institution.</v-label
            >
          </v-col>
          <v-col cols="12">
            <Associates
              :type-id="getServiceAssociationIdByName('relationship_employees')"
              :disabled="disabled"
              required
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, zeroOrGreater } from "../../utils/rules";
import { mapActions, mapGetters, mapState } from "vuex";
import HttpStatus from "http-status";
import {
  createServiceRelationship,
  updateServiceRelationship,
} from "../../api/serviceRelationship.api";
import TextAreaInput from "../form/TextAreaInput.vue";
import YesNoButtonGroup from "../form/YesNoButtonGroup.vue";
import Associates from "./Associates.vue";
import { cloneDeep, isEqual } from "lodash";

export default {
  components: { Associates, TextAreaInput, YesNoButtonGroup },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFormValid: true,
      service_relationship: {
        id: null,
        service_id: null,
        no_relationships: false,
        schools_booster_org: [],
        schools_fin_contrib: [],
        schools_promote: [],
        schools_sa_emp: [],
        schools_season_ticket: [],
        schools_sa_parent: [],
        recruiting_involvement: "",
        psa_interact: null,
        psa_interact_desc: "",
        coach_comm: null,
        coach_comm_desc: "",
        coach_comm_ack: null,
        camp_oper: null,
        camp_oper_ncaa: null,
        camp_attend_ncaa: null,
        emp_ncaa_school: null,
      },
      rules: {
        required,
        zeroOrGreater,
        relationshipsRequired: (v) =>
          v ||
          this.hasRelationships ||
          "Please specify institutions for A through F or check none apply.",
      },
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapGetters({
      institutionOptions: "listOfValues/institutionOptions",
      getServiceAssociationIdByName: "listOfValues/serviceAssociationIdByName",
    }),
    isDirty() {
      return (
        this.service_relationship.id &&
        !isEqual(this.service_relationship, this.service.service_relationship)
      );
    },
    isNew() {
      return (
        this.service_relationship.id === null ||
        this.service_relationship.id === undefined
      );
    },
    hasRelationships() {
      return (
        this.service_relationship.schools_booster_org.length > 0 ||
        this.service_relationship.schools_fin_contrib.length > 0 ||
        this.service_relationship.schools_promote.length > 0 ||
        this.service_relationship.schools_sa_emp.length > 0 ||
        this.service_relationship.schools_season_ticket.length > 0 ||
        this.service_relationship.schools_sa_parent.length > 0
      );
    },
    relationshipsRequired() {
      return [
        this.service_relationship.no_relationships ||
          this.hasRelationships ||
          "Please specify institutions for A through F or check none apply.",
      ];
    },
  },
  methods: {
    ...mapActions("service", ["setServiceRelationship"]),
    initialize() {
      if (!this.service_relationship.id) {
        this.reset();
      }
    },
    reset() {
      this.service_relationship = cloneDeep(this.service.service_relationship);
      this.$refs.form.resetValidation();
    },
    isValid() {
      return this.$refs.form.validate();
    },
    save() {
      this.clearQuestions();
      if (this.isValid()) {
        this.service_relationship.service_id = this.service.id;
        this.$loading();
        if (this.service_relationship.id) {
          return this.update();
        } else {
          return this.create();
        }
      }
    },
    create() {
      return createServiceRelationship(this.service_relationship)
        .then((response) =>
          this.processSaveResponse(response, HttpStatus.CREATED)
        )
        .finally(() => this.$hideLoading());
    },
    update() {
      return updateServiceRelationship(this.service_relationship)
        .then((response) => this.processSaveResponse(response, HttpStatus.OK))
        .finally(() => this.$hideLoading());
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.setServiceRelationship(response.data);
        this.service_relationship = cloneDeep(response.data);
        this.$notify.success("Relationships saved successfully.");
        return true;
      } else {
        return false;
      }
    },
    clearQuestions() {
      if (!this.service_relationship.psa_interact) {
        this.service_relationship.psa_interact_desc = "";
      }

      if (!this.service_relationship.coach_comm) {
        this.service_relationship.coach_comm_desc = "";
        this.service_relationship.coach_comm_ack = null;
      }

      if (!this.service_relationship.camp_oper) {
        this.service_relationship.camp_oper_ncaa = null;
        this.service_relationship.camp_attend_ncaa = null;
      }
    },
  },
};
</script>

<style scoped></style>
