import httpClient from "./httpClient";

const END_POINT = "/services";
const NOTES_END_POINT = "notes";

const getStaffNotes = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${NOTES_END_POINT}`);

const createStaffNote = (serviceId, data) => {
  return httpClient.post(
    `${END_POINT}/${serviceId}/${NOTES_END_POINT}`,
    prepareFormData(data)
  );
};

const updateStaffNote = (serviceId, data) => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.put(
    `${END_POINT}/${serviceId}/${NOTES_END_POINT}/${data.id}`,
    prepareFormData(form)
  );
};

const deleteStaffNote = (id, serviceId) =>
  httpClient.delete(`${END_POINT}/${serviceId}/${NOTES_END_POINT}/${id}`);

const deleteStaffNoteFile = (id, serviceId, fileId) =>
  httpClient.delete(
    `${END_POINT}/${serviceId}/${NOTES_END_POINT}/${id}?attachment_id=${fileId}`
  );

export {
  getStaffNotes,
  createStaffNote,
  updateStaffNote,
  deleteStaffNote,
  deleteStaffNoteFile
};

function prepareFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    const keyName = `staff_note[${key}]`;
    if (data[key] !== null && key === "documents") {
      for (let i = 0; i < data[key].length; i++) {
        const file = data[key][i];

        formData.append(keyName + "[]", file);
      }
    } else if (data[key] !== null && key !== "uploaded_files") {
      formData.append(keyName, data[key]);
    } else if (data[key] === null) {
      formData.append(keyName, "");
    }
  }

  return formData;
}
