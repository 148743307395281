<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Year Maintenance</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="years"
        :loading="loadingTable"
        :loading-text="loadingText"
        sort-by="year"
        :sort-desc="true"
      >
        <template v-slot:no-data>
          <em>No records yet!</em>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <div v-if="isAnyPurgeable">
              <span class="subtitle-2">Purge date: </span>
              <span class="body-1">{{ years[0].purge_date }}</span>
            </div>
            <v-spacer />
            <v-dialog v-model="dialogForm" max-width="500px">
              <template v-if="!disabled" v-slot:activator="{ on }">
                <v-btn color="success" v-on="on">Add Year</v-btn>
              </template>
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Year"
                            type="number"
                            max="2099"
                            v-model="editedItem.year"
                            :rules="[rules.required, rules.year]"
                            :disabled="disabled"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <DatetimePicker
                            label="Application Start Date"
                            v-model="editedItem.app_start_date"
                            :disabled="disabled"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <DatetimePicker
                            label="Application End Date"
                            v-model="editedItem.app_end_date"
                            :rules="[rules.required]"
                            :disabled="disabled"
                            :min="editedItem.app_start_date"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <DatetimePicker
                            label="Display Start Date"
                            v-model="editedItem.start_date"
                            :rules="[rules.required]"
                            :disabled="disabled"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <DatetimePicker
                            label="Display End Date"
                            v-model="editedItem.end_date"
                            :rules="[rules.required]"
                            :disabled="disabled"
                            :min="editedItem.start_date"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-currency-field
                            label="Application Fee"
                            v-model="
                              editedItem.fee_attributes.application_fee_amount
                            "
                            prefix="$"
                            :rules="[rules.required, rules.zeroOrGreater]"
                            :disabled="disabled"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-currency-field
                            label="Base Approval Fee"
                            v-model="editedItem.fee_attributes.standard_amount"
                            prefix="$"
                            :rules="[rules.required, rules.zeroOrGreater]"
                            :disabled="disabled"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <FeeStructures
                            :fees="
                              editedItem.fee_attributes
                                .fee_structures_attributes
                            "
                            :disabled="disabled"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item="props">
          <tr :class="{ warning: props.item.can_purge }">
            <td>{{ props.item.year }}</td>
            <td>{{ formatDate(props.item.app_start_date) }}</td>
            <td>{{ formatDate(props.item.app_end_date) }}</td>
            <td>{{ formatDate(props.item.start_date) }}</td>
            <td>{{ formatDate(props.item.end_date) }}</td>
            <td>
              {{
                formatCurrency(
                  parseFloat(props.item.fee_attributes.application_fee_amount)
                )
              }}
            </td>
            <td>
              {{
                formatCurrency(
                  parseFloat(props.item.fee_attributes.standard_amount)
                )
              }}
            </td>
            <td class="text-center">
              <v-chip>{{
                props.item.fee_attributes.fee_structures_attributes.length
              }}</v-chip>
            </td>
            <td class="text-end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-show="props.item.can_purge"
                    dark
                    small
                    class="mr-2"
                    @click="purgeItem(props.item)"
                    v-on="on"
                  >
                    fa-skull-crossbones
                  </v-icon>
                </template>
                <span>Purge Year</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="!disabled"
                    small
                    class="mr-2"
                    @click="editItem(props.item)"
                    color="primary"
                    v-on="on"
                  >
                    $vuetify.icons.edit
                  </v-icon>
                </template>
                <span>Edit Year</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="!disabled"
                    :disabled="!props.item.can_delete"
                    small
                    @click="openDeleteDialog(props.item)"
                    color="error"
                    v-on="on"
                  >
                    $vuetify.icons.delete
                  </v-icon>
                </template>
                <span>Delete Year</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Delete Year?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteItem">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <Purge ref="purge" />
  </v-card>
</template>

<script>
import { formatCurrency, loadingTextForTable } from "../../utils/helpers";
import { required, year, zeroOrGreater } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import { createYear, deleteYear, updateYear } from "../../api/years.api";
import DatetimePicker from "../../components/form/DatetimePicker.vue";
import Purge from "./Purge.vue";
import { some } from "lodash";
import FeeStructures from "./FeeStructures.vue";

import moment from "moment";

export default {
  components: { Purge, DatetimePicker, FeeStructures },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      disabled: false,
      headers: [
        { text: "Year", value: "year" },
        { text: "App Start Date", value: "app_start_date" },
        { text: "App End Date", value: "app_end_date" },
        { text: "Display Start Date", value: "start_date" },
        { text: "Display End Date", value: "end_date" },
        { text: "App Fee", value: "fee_attributes.application_fee_amount" },
        { text: "Approval Fee", value: "fee_attributes.standard_amount" },
        {
          text: "Graduated Fees",
          value: "fee_attributes.fee_structures_attributes"
        },
        {
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
          width: "10%"
        }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        year: null,
        app_start_date: null,
        app_end_date: null,
        start_date: null,
        end_date: null,
        fee_attributes: {
          id: null,
          year_id: null,
          application_fee_amount: null,
          standard_amount: null,
          fee_structures_attributes: []
        }
      },
      defaultItem: {
        year: null,
        app_start_date: null,
        app_end_date: null,
        start_date: null,
        end_date: null,
        fee_attributes: {
          id: null,
          year_id: null,
          application_fee_amount: null,
          standard_amount: null,
          fee_structures_attributes: []
        }
      },
      rules: {
        required,
        year,
        zeroOrGreater
      }
    };
  },
  computed: {
    ...mapState("admin", ["years"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Year" : "Edit Year";
    },
    isAnyPurgeable() {
      return some(this.years, { can_purge: true });
    }
  },
  methods: {
    ...mapActions("admin", [
      "fetchYears",
      "addYear",
      "updateYear",
      "removeYear"
    ]),
    formatCurrency(value) {
      return formatCurrency(value);
    },
    editItem(item) {
      this.editedIndex = this.years.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.reset();
      this.dialogForm = true;
    },
    purgeItem(year) {
      this.$refs.purge.openDialog(year);
    },
    openDeleteDialog(item) {
      this.editedIndex = this.years.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    reset() {
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.reset();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createYear(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addYear(response.data);
            this.$notify.success("Year added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateYear(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateYear(response.data);
            this.$notify.success("Year updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteYear(this.editedItem.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeYear(this.editedItem.id);
            this.$notify.success("Year removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY HH:mm:ss");
    }
  },
  mounted() {
    this.loadingTable = true;
    this.fetchYears().then(years => {
      this.loadingTable = false;
    });
  }
};
</script>

<style scoped>
/* Vuetify color code for "orange darken-2" */
.warning:hover {
  background-color: #f57c00 !important;
}
</style>
