import httpClient from "./httpClient";

const END_POINT = "/admin/years";

const getPurgeableServices = yearId =>
  httpClient.get(`${END_POINT}/${yearId}/purgeable_services`);
const getPurgeableServicers = yearId =>
  httpClient.get(`${END_POINT}/${yearId}/purgeable_servicers`);
const getYears = () => httpClient.get(`${END_POINT}`);
const purgeYear = (yearId, servicerIds) => {
  const payload = { servicer_ids: servicerIds };
  return httpClient.post(`${END_POINT}/${yearId}/purge`, payload);
};

const createYear = data => {
  const payload = {
    year: data
  };
  return httpClient.post(`${END_POINT}`, payload);
};

const updateYear = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { year: form };
  return httpClient.put(`${END_POINT}/${id}`, payload);
};

const deleteYear = id => httpClient.delete(`${END_POINT}/${id}`);

export {
  getPurgeableServices,
  getPurgeableServicers,
  getYears,
  createYear,
  updateYear,
  deleteYear,
  purgeYear
};
