<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          :hint="showHint ? format + ' format' : ''"
          :disabled="disabled"
          :readonly="readonly"
          :rules="rules"
          persistent-hint
          prepend-icon="far fa-calendar-alt"
          @blur="localValue = parseDate(dateFormatted)"
          v-on="on"
          maxlength="10"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="localValue"
        no-title
        @input="menu = false"
        :disabled="disabled"
        :readonly="readonly"
        :min="min"
        :max="max"
        :allowed-dates="allowedDates"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: "MM/DD/YYYY"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    allowedDates: {
      type: Function,
      default: null
    },
    rules: {
      type: Array
    },
    showHint: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      menu: false,
      dateFormatted: this.formatDate(this.value)
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      }
    }
  },
  watch: {
    value(val) {
      this.dateFormatted = this.formatDate(this.value);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      return moment(date, "YYYY-MM-DD").format(this.format);
    },
    parseDate(date) {
      if (!date) return null;

      return moment(date, this.format).format("YYYY-MM-DD");
    }
  }
};
</script>

<style scoped></style>
