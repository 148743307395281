<!-- A view component for display when page or api data cannot be found -->
<template>
  <v-alert prominent type="warning">
    <v-row>
      <v-col class="grow"
        >Oops! The {{ resource }} you're looking for is not here!</v-col
      >
      <v-col class="shrink"
        ><v-btn link :to="{ name: 'home' }">Return to home page</v-btn></v-col
      >
    </v-row>
  </v-alert>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: true,
      default: "page"
    }
  }
};
</script>
