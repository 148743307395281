import httpClient from "./httpClient";

const END_POINT = "/admin/statuses";

const getStatuses = () => httpClient.get(`${END_POINT}`);

const createStatus = data => {
  const payload = {
    status: data
  };
  return httpClient.post(`${END_POINT}`, payload);
};

const updateStatus = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { status: form };
  return httpClient.put(`${END_POINT}/${id}`, payload);
};

const deleteStatus = id => httpClient.delete(`${END_POINT}/${id}`);

export { getStatuses, createStatus, updateStatus, deleteStatus };
