<template>
  <v-snackbar
    :timeout="timeout"
    :color="color"
    :bottom="y === 'bottom'"
    :top="y === 'top'"
    :left="x === 'left'"
    :right="x === 'right'"
    :multi-line="multiLine"
    :vertical="vertical"
    v-model="active"
    class="v-application"
    :class="classes"
    @click="dismiss"
    role="alert"
  >
    <v-icon v-if="!!icon" left dark small>
      {{ icon }}
    </v-icon>

    <div style="display: inline-flex" v-html="message" />

    <v-spacer />

    <template v-slot:action="{ attrs }">
      <v-btn
        v-if="showClose"
        class="mx-1"
        dark
        icon
        @click="close"
        v-bind="attrs"
      >
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    x: {
      type: String,
      default: "right",
    },
    y: {
      type: String,
      default: "bottom",
    },
    color: {
      type: String,
      default: "info",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    classes: {
      type: [String, Object, Array],
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 4000,
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: "",
    },
    closeIcon: {
      type: String,
      default: "close",
    },
    closeColor: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    active: false,
  }),
  mounted() {
    this.$nextTick(() => this.show());
  },
  watch: {
    active: function (isActive, wasActive) {
      this.$emit("statusChange", isActive, wasActive);
    },
  },
  methods: {
    show() {
      this.active = true;
    },
    close() {
      this.active = false;
    },
    dismiss() {
      if (this.dismissable) {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.vts .v-snack__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
