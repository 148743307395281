<template>
  <div class="text-center">
    <v-dialog v-model="confirmPaymentDialog" persistent width="500">
      <v-form
        ref="form"
        :action="payment.ncaa_payment.vendor_url"
        method="post"
        target="_blank"
        @submit="closeConfirmPaymentDialog"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            {{ isPaymentLoaded ? "Continue to Payment?" : "Please wait" }}
          </v-card-title>

          <v-divider />

          <v-card-text :class="padTop">
            <div v-if="!isPaymentLoaded">
              <v-progress-circular
                indeterminate
                class="mr-1"
                color="primary"
                :size="20"
                :width="3"
              />
              Gathering payment information...
            </div>
            <div v-else>
              By clicking Continue, you will be redirected to our payment site
              to make a payment for {{ service.year }} pertaining to service
              <span :class="emphasize">{{ service.name }}</span
              >.

              <div class="mt-4" :class="{ 'd-none': !showFormFields }">
                <v-text-field
                  label="First Name"
                  name="x_first_name"
                  :value="payment.servicer.first_name"
                />
                <v-text-field
                  label="Last Name"
                  name="x_last_name"
                  :value="payment.servicer.last_name"
                />
                <v-text-field
                  label="Email"
                  name="x_email"
                  :value="payment.servicer.email"
                />
                <v-text-field
                  label="Phone"
                  name="x_phone"
                  :value="payment.servicer.phone"
                />
                <v-text-field
                  label="Address"
                  name="x_address"
                  :value="payment.servicer.home_address_attributes.street1"
                />
                <v-text-field
                  label="City"
                  name="x_city"
                  :value="payment.servicer.home_address_attributes.city"
                />
                <v-text-field
                  label="State"
                  name="x_state"
                  :value="payment.servicer.home_address_attributes.state"
                />
                <v-text-field
                  label="Postal Code"
                  name="x_zip"
                  :value="payment.servicer.home_address_attributes.postal_code"
                />
                <v-text-field
                  label="Country"
                  name="x_country"
                  :value="payment.servicer.home_address_attributes.country"
                />
                <v-text-field
                  label="Invoice Number"
                  name="x_invoice_num"
                  :value="payment.ncaa_payment.invoice_number"
                />
                <v-text-field
                  label="Payment Amount"
                  name="x_amount"
                  :value="payment.ncaa_payment.amount"
                />
                <v-text-field
                  label="Login Id"
                  class="d-none"
                  name="x_login"
                  :value="payment.ncaa_payment.vendor_login_id"
                />
                <v-text-field
                  label="Hash Sequence"
                  class="d-none"
                  name="x_fp_sequence"
                  :value="payment.ncaa_payment.hash_sequence"
                />
                <v-text-field
                  label="Hash Timestamp"
                  class="d-none"
                  name="x_fp_timestamp"
                  :value="payment.ncaa_payment.hash_timestamp"
                />
                <v-text-field
                  label="Hash Value"
                  class="d-none"
                  name="x_fp_hash"
                  :value="payment.ncaa_payment.hash_value"
                />
                <v-text-field
                  class="d-none"
                  name="x_email_customer"
                  value="TRUE"
                />
                <v-text-field
                  class="d-none"
                  name="x_show_form"
                  value="PAYMENT_FORM"
                />
              </div>
            </div>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn text @click="closeConfirmPaymentDialog">Cancel</v-btn>
            <v-spacer />
            <v-btn color="success" type="submit" :disabled="!isPaymentLoaded">
              Continue
              <v-icon right>$vuetify.icons.externalLink</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getServicePayment } from "../api/servicePayment.api";
import HttpStatus from "http-status";

export default {
  name: "PaymentConfirm",
  data() {
    return {
      isPaymentLoaded: false,
      showFormFields: false,
      payment: {
        ncaa_payment: {
          id: 0,
          amount: 0,
          status: "",
          invoice_number: "",
          hash_sequence: "",
          hash_timestamp: "",
          hash_value: "",
          authorization_code: "",
          transaction_id: "",
          response_code: "",
          response_reason_code: "",
          response_reason_text: "",
          response_received_at: "",
          vendor_login_id: "",
          vendor_url: "",
          created_at: "",
          updated_at: "",
          history: []
        },
        servicer: {}
      }
    };
  },
  methods: {
    ...mapActions("service", ["addServicePaymentToService"]),
    ...mapActions("servicePayment", ["closeConfirmPaymentDialog"]),
    resetPayment() {
      this.isPaymentLoaded = false;
    },
    startPayment() {
      this.resetPayment();

      getServicePayment(this.service.id)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            this.isPaymentLoaded = true;
            this.payment = response.data;

            if (this.isServicerPayingFromApplicationList) {
              this.addServicePaymentToService(this.payment.service_payment);
            }
          }
        })
        .catch(error => {
          this.closeConfirmPaymentDialog();
          if (error.response.data.message) {
            this.$notify.error(error.response.data.message);
          } else {
            this.$notify.error(
              "An error occurred while getting payment information."
            );
          }
        });
    }
  },
  computed: {
    ...mapState("servicePayment", ["confirmPaymentDialog", "service"]),
    emphasize() {
      return "font-weight-black";
    },
    padTop() {
      return "pt-5";
    },
    isServicerPayingFromApplicationList() {
      return this.$router.currentRoute.path.endsWith("/applications");
    }
  },
  watch: {
    confirmPaymentDialog(val) {
      if (val) {
        this.startPayment();
      }
    }
  }
};
</script>
