<template>
  <v-input :value="localValue" :rules="rules" :disabled="disabled">
    <v-btn-toggle
      :value="localValue"
      @change="bindInput($event)"
      color="primary"
      dense
    >
      <v-btn :value="true" :small="small" :x-small="xSmall"> Yes </v-btn>
      <v-btn :value="false" :small="small" :x-small="xSmall"> No </v-btn>
    </v-btn-toggle>
  </v-input>
</template>

<script>
export default {
  name: "YesNoButtonGroup",
  props: {
    value: Boolean,
    small: Boolean,
    xSmall: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: [(v) => v === true || v === false || "Please select Yes or No"],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  methods: {
    bindInput(event) {
      this.localValue = event;
    },
  },
};
</script>
