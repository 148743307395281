<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Status Email Maintenance</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="statusEmails"
        :loading="loadingTable"
        :loading-text="loadingText"
      >
        <template v-slot:no-data>
          <em>No records yet!</em>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-dialog v-model="dialogForm" max-width="1000px">
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on">Add Status Email</v-btn>
              </template>
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-select
                            v-model="editedItem.status_id"
                            label="Status"
                            :items="statuses"
                            :rules="[rules.required]"
                            item-value="id"
                            item-text="description"
                          />
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field
                            label="Subject"
                            v-model="editedItem.subject"
                            :rules="[rules.required]"
                            maxlength="255"
                          />
                        </v-col>
                        <v-col cols="12">
                          <tiptap-vuetify
                            v-model="editedItem.content"
                            :extensions="extensions"
                            placeholder="Enter email text here..."
                          />
                        </v-col>
                      </v-row>
                      <v-card outlined color="info" dark>
                        <v-card-subtitle
                          >Substitution Variables - Add the variable names below
                          within square brackets [] to include application
                          specific information within the email
                          text.</v-card-subtitle
                        >
                        <v-card-text>
                          <ul>
                            <li>
                              [SERVICE_NAME] - the application's service name.
                            </li>
                            <li>
                              [SPORTS] - list of selected sports from the
                              application
                            </li>
                            <li>
                              [YEAR_EXP_DATE] - the application year expiration
                              date (i.e. May 31, 2021)
                            </li>
                            <li>
                              [STATUS_DEADLINE] - the deadline to reply to
                              status request for information
                            </li>
                          </ul>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.active="{ item }">
          {{ formatBoolean(item.active) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)" color="primary">
            $vuetify.icons.edit
          </v-icon>
          <v-icon small @click="openDeleteDialog(item)" color="error">
            $vuetify.icons.delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Delete Status Email?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteItem">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { loadingTextForTable } from "../../utils/helpers";
import { required } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import {
  createStatusEmail,
  deleteStatusEmail,
  updateStatusEmail
} from "../../api/statusEmails.api";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";
export default {
  components: { TiptapVuetify },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      headers: [
        { text: "Status", value: "status" },
        { text: "Subject", value: "subject" },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        status_id: null,
        subject: "",
        content: ""
      },
      defaultItem: {
        status_id: null,
        subject: "",
        content: ""
      },
      rules: {
        required
      },
      extensions: [
        History,
        Bold,
        Italic,
        Strike,
        Underline,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        HorizontalRule,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ]
      ]
    };
  },
  computed: {
    ...mapState("admin", ["statusEmails", "statuses"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Status Email" : "Edit Status Email";
    }
  },
  methods: {
    ...mapActions("admin", [
      "fetchStatusEmails",
      "addStatusEmail",
      "updateStatusEmail",
      "removeStatusEmail",
      "fetchStatuses"
    ]),
    editItem(item) {
      this.editedIndex = this.statusEmails.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.statusEmails.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createStatusEmail(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addStatusEmail(response.data);
            this.$notify.success("Status Email added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateStatusEmail(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateStatusEmail(response.data);
            this.$notify.success("Status Email updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteStatusEmail(this.editedItem.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeStatusEmail(this.editedIndex);
            this.$notify.success("Status Email removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    formatBoolean(flag) {
      return flag ? "Yes" : "No";
    }
  },
  mounted() {
    this.loadingTable = true;
    this.fetchStatusEmails().then(statusEmails => {
      this.loadingTable = false;
    });
    this.fetchStatuses();
  }
};
</script>
