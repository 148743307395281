import httpClient from "./httpClient";

const END_POINT = "/services";
const FORMAT_END_POINT = "promotion";

const getServicePromotion = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${FORMAT_END_POINT}`);

const createServicePromotion = data => {
  const payload = {
    service_promotion: data
  };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${FORMAT_END_POINT}`,
    payload
  );
};

const updateServicePromotion = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_promotion: form };
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${FORMAT_END_POINT}`,
    payload
  );
};

export { getServicePromotion, createServicePromotion, updateServicePromotion };
