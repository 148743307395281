<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle"
      >Sample Upload
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-label
              >Upload a sample of the information your service provides.
            </v-label>
          </v-col>
        </v-row>
        <v-card elevation="1" v-if="!videoOnlyService">
          <v-card-title>Conventional Services</v-card-title>
          <v-card-text>
            <ul>
              <li>
                A complete sample is required, and it must include individual
                analysis beyond demographic information or rankings for each
                prospect identified in the information disseminated by the
                service. Statistics alone will not suffice. If multiple packages
                exist, the sample must include visibility to all packages.
              </li>
              <li>
                All prospects named in the scouting service must include
                analysis. You CANNOT include the name of a prospect in a list of
                up and coming athletes to watch OR in an article housed inside
                the scouting service without that prospect ALSO having a profile
                including analysis.
              </li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card elevation="1" v-if="videoOnlyService">
          <v-card-title>Video Only Services</v-card-title>
          <v-card-text>
            <ul>
              <li>
                Provide link(s) to samples of videos subscribers will receive.
              </li>
              <li>
                This sample must be an exemplar/mock-up of the complete product
                or report that would be provided to an institution with a full
                cross-section of the product[s] offered by the service.
              </li>
              <li>
                NCAA legislation requires access to samples or previews of the
                information accessible to college coaches prior to purchasing a
                subscription.
              </li>
            </ul>
          </v-card-text>
        </v-card>
        <v-row v-if="!videoOnlyService">
          <v-col cols="12" sm="3">
            <v-select
              v-model="upload.sport_code"
              :items="sports"
              label="Sport"
              :rules="[rules.required]"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <v-file-input
              v-model="upload.sample_files"
              small-chips
              multiple
              label="Samples"
              :accept="acceptTypes"
              :rules="uploadsValid"
              hint="Valid file types include (5MB max): PDF, Images, MS Office Suite, Apple iWork Suite"
              :disabled="disabled"
            ></v-file-input>
          </v-col>
          <v-col cols="12" offset-sm="3" sm="7" v-if="isAdmin">
            <v-select
              :items="conversationUploadOptions"
              v-model="upload.conversation_files"
              label="Attach existing upload from conversation history"
              multiple
              small-chips
              deletable-chips
              :rules="uploadsValid"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn color="info" @click="uploadFiles" :disabled="disabled"
              >Upload</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" class="mt-0">
            <v-list dense>
              <v-list-item
                v-for="(link, i) in service.service_sample_links"
                :key="i"
                dense
              >
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title v-if="link.sample_file">
                    <v-btn
                      v-if="!disabled"
                      icon
                      color="error"
                      @click="deleteFile(link.id)"
                    >
                      <v-icon small>$vuetify.icons.delete</v-icon>
                    </v-btn>
                    {{ upperCase(link.sport_code) }}
                    <a :href="link.sample_file.file_url">{{
                      link.sample_file.file_name
                    }}</a>
                    <span class="grey--text text--lighten-1"
                      >({{ link.sample_file.file_size }})</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="5" v-if="!isValid">
            <span class="error--text ml-4"
              >Please upload at least one file.</span
            >
          </v-col>
        </v-row>
        <v-row v-if="videoOnlyService">
          <v-col cols="12">
            <SampleLinks :required="true" :disabled="disabled" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import HttpStatus from "http-status";
import { acceptTypes } from "../../utils/helpers";
import { required } from "../../utils/rules";
import SampleLinks from "./SampleLinks.vue";
import { cloneDeep } from "lodash";
import {
  deleteSampleLink,
  getSampleLinks,
  uploadSamples
} from "../../api/serviceSampleLinks.api";

export default {
  components: { SampleLinks },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      upload: {
        sport_code: "",
        sample_files: [],
        conversation_files: []
      },
      defaultUpload: {
        sport_code: "",
        sample_files: [],
        conversation_files: []
      },
      isFormValid: true,
      rules: {
        required
      }
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("listOfValues", ["sports"]),
    ...mapGetters("serviceReview", ["conversationUploadOptions"]),
    ...mapGetters("user", ["isAdmin"]),
    isDirty() {
      return this.service.id && this.upload.sample_files.length > 0;
    },
    isNew() {
      return this.service.service_sample_links.length === 0;
    },
    videoOnlyService() {
      return this.service.service_format.video_only;
    },
    acceptTypes() {
      return acceptTypes;
    },
    uploadsValid() {
      let sizeValid = true;
      this.upload.sample_files.forEach(file => {
        if (file.size > 5000000) {
          sizeValid = false;
        }
      });
      return [
        this.upload.sample_files.length > 0 ||
          this.upload.conversation_files.length > 0 ||
          "Required",
        this.upload.sample_files.length +
          this.service.service_sample_links.length <=
          10 || "A maximum of 10 files may be uploaded",
        sizeValid || "Files may not exceed 5MB in size"
      ];
    },
    preparedUploadSave() {
      return {
        id: this.service.id,
        sport_code: this.upload.sport_code,
        sample_files: this.upload.sample_files,
        conversation_files: this.upload.conversation_files
      };
    }
  },
  methods: {
    ...mapActions("service", ["setServiceSampleLinks"]),
    initialize() {
      getSampleLinks(this.service.id).then(response =>
        this.setServiceSampleLinks(response.data)
      );
      this.reset();
    },
    reset() {
      this.upload = cloneDeep(this.defaultUpload);
      this.$refs.form.resetValidation();
    },
    save() {
      return Promise.resolve(true);
    },
    uploadFiles() {
      if (this.$refs.form.validate()) {
        this.$loading();
        return uploadSamples(this.preparedUploadSave)
          .then(response => this.processSaveResponse(response, HttpStatus.OK))
          .finally(() => this.$hideLoading());
      }
    },
    deleteFile(linkId) {
      this.$loading();
      return deleteSampleLink(linkId, this.service.id)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.setServiceSampleLinks(response.data);
            this.reset();
            this.$notify.success("File deleted successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.setServiceSampleLinks(response.data);
        this.reset();
        this.$notify.success("Uploads saved successfully.");
        return true;
      } else {
        return false;
      }
    },
    isValid() {
      return this.service.service_sample_links.length > 0;
    },
    upperCase(str) {
      return str ? str.toUpperCase() : str;
    }
  }
};
</script>

<style scoped></style>
