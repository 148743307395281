<template
  ><v-data-table
    :headers="headers"
    :items="fees"
    hide-default-footer
    disable-pagination
    no-data-text="No fees entered"
    sort-by="applied_at"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-label>Graduated Fees</v-label>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon color="info" class="ml-2" v-on="on">
              $vuetify.icons.question
            </v-icon>
          </template>
          <span
            >Each graduated fee will be applied in addition to the base approval
            fee</span
          >
        </v-tooltip>
        <v-spacer />
        <v-dialog v-model="dialog" max-width="500px">
          <template v-if="!disabled" v-slot:activator="{ on }">
            <v-btn color="success" v-on="on">Add Fee</v-btn>
          </template>
          <v-form ref="feeStructuresForm" v-model="isFormValid" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-currency-field
                        label="Fee"
                        v-model="editedItem.fee_amount"
                        prefix="$"
                        :rules="[rules.required, rules.zeroOrGreater]"
                        :disabled="disabled"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <Datepicker
                        label="Date Applied"
                        v-model="editedItem.applied_at"
                        :rules="[rules.required]"
                        :disabled="disabled"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn color="primary" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item="props">
      <tr v-if="!props.item._destroy">
        <td>{{ formatCurrency(props.item.fee_amount) }}</td>
        <td>{{ formatDate(props.item.applied_at) }}</td>
        <td class="text-end">
          <v-icon
            v-if="!disabled"
            small
            class="mr-2"
            @click="editItem(props.item)"
            color="primary"
          >
            $vuetify.icons.edit
          </v-icon>
          <v-icon
            v-if="!disabled"
            small
            @click="deleteItem(props.item)"
            color="error"
          >
            $vuetify.icons.delete
          </v-icon>
        </td>
      </tr>
      <tr v-else>
        <td colspan="3" class="text-center">
          <em
            >Row has been deleted, but form must still be saved.
            <a href="#" @click.prevent="undeleteItem(props.item)">Undo</a></em
          >
        </td>
      </tr>
    </template>
  </v-data-table></template
>

<script>
import { formatCurrency } from "../../utils/helpers";
import { required } from "../../utils/rules";
import Datepicker from "../../components/form/Datepicker.vue";

import moment from "moment";

export default {
  name: "FeeStructures",
  components: { Datepicker },
  props: {
    fees: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Fee",
          value: "fee_amount"
        },
        { text: "Applied On", value: "applied_at" },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        fee_id: null,
        fee_amount: null,
        applied_at: null,
        _destroy: false
      },
      defaultItem: {
        id: null,
        fee_id: null,
        fee_amount: null,
        applied_at: null,
        _destroy: false
      },
      isFormValid: true,
      rules: {
        required
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Fee" : "Edit Fee";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    formatCurrency(value) {
      return formatCurrency(value);
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
    },
    editItem(item) {
      this.editedIndex = this.fees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.fees.indexOf(item);

      if (item.id) {
        this.fees.splice(index, 1, { ...item, _destroy: true });
      } else {
        this.fees.splice(index, 1);
      }
    },
    undeleteItem(item) {
      const index = this.fees.indexOf(item);
      this.fees.splice(index, 1, { ...item, _destroy: false });
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.feeStructuresForm.resetValidation();
    },
    save() {
      if (this.$refs.feeStructuresForm.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.fees[this.editedIndex], this.editedItem);
        } else {
          this.fees.push(this.editedItem);
        }
        this.close();
      }
    }
  }
};
</script>
