import httpClient from "./httpClient";

const END_POINT = "/services";

const CHILD_END_POINTS = {
  PAYMENTS: "payments",
  PAYMENT_INFO: "payment_info"
};

const getServicePayment = serviceId => {
  return httpClient.get(
    `${END_POINT}/${serviceId}/${CHILD_END_POINTS.PAYMENT_INFO}`
  );
};

const getServicePayments = serviceId => {
  return httpClient.get(
    `${END_POINT}/${serviceId}/${CHILD_END_POINTS.PAYMENTS}`
  );
};

export { getServicePayment, getServicePayments };
