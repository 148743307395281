<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle"
      >Type & Scope</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-label
              >Does your service charge prospects or their families a
              fee?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_type.sa_fee"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row v-if="service_type.sa_fee" class="ml-4">
          <v-col cols="12">
            <v-label>Are all prospects charged the same fee rate?</v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_type.sa_fee_same"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <div v-if="showSaSameFeeDesc">
          <v-alert type="warning" class="ml-8"
            >NCAA legislation prohibits preferential treatment, benefits or
            services because of the individual’s athletics reputation or skill
            or pay-back potential as a professional athlete. Please confirm your
            understanding of this requirement and submit a description of how
            you will comply.</v-alert
          >
          <v-row class="ml-8">
            <v-col cols="12">
              <TextAreaInput
                v-model="service_type.sa_fee_same_desc"
                :required="showSaSameFeeDesc"
                :disabled="disabled"
              ></TextAreaInput>
            </v-col>
          </v-row>
        </div>
        <div v-if="service_type.sa_fee">
          <v-row class="ml-4">
            <v-col cols="12">
              <v-label
                >Are the fees based on whether prospects receive a
                scholarship?</v-label
              >
            </v-col>
            <v-col cols="12">
              <YesNoButtonGroup
                v-model="service_type.sa_fee_scholarship"
                :disabled="disabled"
              ></YesNoButtonGroup>
            </v-col>
          </v-row>
          <div v-if="service_type.sa_fee_scholarship">
            <v-alert type="warning" class="ml-8"
              >You may be jeopardizing the eligibility of prospects that use
              your service. Please provide a copy of the contract/standard
              agreement provided to prospects.</v-alert
            >
            <v-row class="ml-8">
              <v-col cols="12" sm="6">
                <v-file-input
                  v-model="service_type.contract_files"
                  small-chips
                  multiple
                  label="Contact/Standard Agreement"
                  :accept="acceptTypes"
                  :rules="contractFilesValid"
                  :disabled="disabled"
                  hint="Valid file types include (5MB max): PDF, Images, MS Office Suite, Apple iWork Suite"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list dense>
                  <v-list-item
                    v-for="(file, i) in service_type.contract_files_uploaded"
                    :key="i"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-btn
                          v-if="!disabled"
                          icon
                          color="error"
                          @click="deleteFile(file.id)"
                        >
                          <v-icon small>$vuetify.icons.delete</v-icon>
                        </v-btn>
                        <a :href="file.file_url">{{ file.file_name }}</a>
                        <span class="grey--text text--lighten-1"
                          >({{ file.file_size }})</span
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" sm="6" v-if="isAdmin">
                <v-select
                  :items="conversationUploadOptions"
                  v-model="service_type.contract_conversation_files"
                  label="Attach existing upload from conversation history"
                  multiple
                  small-chips
                  deletable-chips
                  :rules="contractFilesValid"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <v-row class="ml-4">
            <v-col cols="12">
              <v-label
                >How do you obtain the contact information for prospects to
                market your service to them?</v-label
              >
            </v-col>
            <v-col cols="12">
              <TextAreaInput
                v-model="service_type.sa_fee_contact_info"
                :required="service_type.sa_fee"
                :disabled="disabled"
              ></TextAreaInput></v-col
          ></v-row>
          <v-row class="ml-4">
            <v-col cols="12">
              <v-label
                >How do you promote your service? What methods are used to
                market your service to prospects?</v-label
              >
            </v-col>
            <v-col cols="12">
              <TextAreaInput
                v-model="service_type.sa_fee_promote"
                :required="service_type.sa_fee"
                :disabled="disabled"
              ></TextAreaInput></v-col
          ></v-row>
          <v-row class="ml-4">
            <v-col cols="12">
              <v-label
                >What assistance do you provide or work do you perform on behalf
                of prospects?</v-label
              >
            </v-col>
            <v-col cols="12">
              <TextAreaInput
                v-model="service_type.sa_fee_services"
                :required="service_type.sa_fee"
                :disabled="disabled"
              ></TextAreaInput></v-col
          ></v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-label
              >Does the service charge NCAA institutions a fee to have access to
              information about prospects?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_type.coach_fee"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <div v-if="service_type.coach_fee">
          <v-alert type="info">
            Scouting services are able to provide multiple pricing options to
            institutions provided the different packages (e.g. gold, silver and
            bronze) are:
            <ol>
              <li>
                not based on the division in which the institution competes;
              </li>
              <li>are based on the amount/type of information included;</li>
              <li>
                are identified and approved as part of the application process.
              </li>
            </ol>
            Once the institution selects a package, the same fee rate must be
            charged to all subscribers.
          </v-alert>
          <v-row class="ml-4">
            <v-col cols="12">
              <v-label
                >What price is the package that is the most frequently purchased
                by NCAA coaches?</v-label
              >
            </v-col>
            <v-col cols="12" sm="4">
              <v-currency-field
                v-model="service_type.coach_fee_price"
                prefix="$"
                :rules="
                  service_type.coach_fee
                    ? [rules.required, rules.zeroOrGreater]
                    : []
                "
                :disabled="disabled"
                :default-value="null"
              ></v-currency-field>
            </v-col>
          </v-row>
          <v-row class="ml-4">
            <v-col cols="12">
              <v-icon
                color="error"
                v-if="isAdmin && service_type.coach_fee_same_rate === false"
                >$vuetify.icons.exclaim</v-icon
              >
              <v-label
                >Are subscriptions to the service available to all institutions
                at the same fee rate? For services that offer multiple pricing
                options, will each school pay the same rate once a particular
                package is selected?</v-label
              >
            </v-col>
            <v-col cols="12">
              <YesNoButtonGroup
                v-model="service_type.coach_fee_same_rate"
                :disabled="disabled"
              ></YesNoButtonGroup>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-label
                >Are the subscription fees based strictly on the number of
                coaching staff members at an institution that are permitted to
                access the service?</v-label
              >
            </v-col>
            <v-col cols="12">
              <YesNoButtonGroup
                v-model="service_type.subscription_fees"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="12" v-if="service_type.subscription_fees">
              <TextAreaInput
                v-model="service_type.subscription_fees_explain"
                :required="service_type.subscription_fees"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <v-row class="ml-4" v-if="service_type.subscription_fees === false">
            <v-col cols="12">
              <v-label
                >Identify the standard fee rate for NCAA institutions.</v-label
              >
            </v-col>
            <v-col cols="12" sm="4">
              <v-currency-field
                v-model="service_type.standard_fee"
                prefix="$"
                :rules="
                  service_type.subscription_fees === false
                    ? [rules.required, rules.zeroOrGreater]
                    : []
                "
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <div v-if="service_type.subscription_fees">
            <v-row>
              <v-col cols="12">
                <v-label
                  >If multiple coaches from the same institution and in the same
                  sport are receiving reports or given access to online data,
                  are all those coaches covered under one subscription
                  fee?</v-label
                >
              </v-col>
              <v-col cols="12">
                <YesNoButtonGroup
                  v-model="service_type.same_fee_all_coaches"
                  :disabled="disabled"
                />
              </v-col>
            </v-row>
            <v-row
              class="ml-4"
              v-if="service_type.same_fee_all_coaches === false"
            >
              <v-col cols="12">
                <v-label>
                  Depending on the specific details, charging multiple coaches
                  may be interpreted as the institution having multiple
                  subscriptions, which is impermissible. Describe in detail how
                  multiple coaches from the same school are charged so the NCAA
                  can determine if your service is compliant.
                </v-label>
              </v-col>
              <v-col cols="12">
                <TextAreaInput
                  v-model="service_type.same_fee_all_coaches_no"
                  :required="service_type.same_fee_all_coaches === false"
                  :disabled="disabled"
                />
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12">
              <v-label
                >Are there different priced packages based on individual states
                or regions?</v-label
              >
            </v-col>
            <v-col cols="12">
              <YesNoButtonGroup
                v-model="service_type.diff_fee_per_region"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <v-row class="ml-4" v-if="service_type.diff_fee_per_region === false">
            <v-col
              cols="12"
              class="d-print-none"
              :class="{ 'd-none': isPrintPage }"
            >
              <v-label
                >Use the green button below to add each package with a clear
                description of what information is provided to subscribers. Be
                sure to include the standard fee rate and all available
                discounts.</v-label
              >
            </v-col>
            <v-col cols="12">
              <FeePackages
                :packages="service_type.service_price_packages_attributes"
                :valid="packagesValid"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <v-row class="ml-4" v-if="service_type.diff_fee_per_region">
            <v-col cols="12">
              <v-label
                >Upload a price sheet that includes all fee rates and a clear
                description of what is included in each package.</v-label
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input
                v-model="service_type.price_sheet_files"
                small-chips
                multiple
                label="Price Sheet"
                :accept="acceptTypes"
                :rules="priceSheetFilesValid"
                hint="Valid file types include (5MB max): PDF, Images, MS Office Suite, Apple iWork Suite"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-list dense>
                <v-list-item
                  v-for="(file, i) in service_type.price_sheet_files_uploaded"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn
                        v-if="!disabled"
                        icon
                        color="error"
                        @click="deleteFile(file.id)"
                      >
                        <v-icon small>$vuetify.icons.delete</v-icon>
                      </v-btn>
                      <a :href="file.file_url">{{ file.file_name }}</a>
                      <span class="grey--text text--lighten-1"
                        >({{ file.file_size }})</span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" v-if="isAdmin">
              <v-select
                :items="conversationUploadOptions"
                v-model="service_type.price_conversation_files"
                label="Attach existing upload from conversation history"
                multiple
                small-chips
                deletable-chips
                :rules="priceSheetFilesValid"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-label>Identify the geographical scope of the service.</v-label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="service_type.geo_scope"
              :items="serviceGeographicalTypes"
              :rules="[rules.required]"
              :disabled="disabled"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="showLocal">
          <v-col cols="12">
            <v-label>{{ geoLabel }}</v-label>
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_type.geo_local_desc"
              :required="showLocal"
              :disabled="disabled"
            ></TextAreaInput>
          </v-col>
        </v-row>
        <v-row v-if="showCountries">
          <v-col cols="12">
            <v-label>{{ geoLabel }}</v-label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-if="showStates || isNational"
              label="Country"
              v-model="single_country"
              :items="countryOptions"
              :rules="showCountries ? [rules.required] : []"
              :disabled="disabled"
            ></v-autocomplete>
            <v-autocomplete
              v-if="isInternational"
              label="Country"
              v-model="service_type.geo_countries"
              :items="countryOptions"
              multiple
              small-chips
              deletable-chips
              :rules="showCountries ? [rules.selectOne] : []"
              :disabled="disabled"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" v-if="showStates">
            <v-autocomplete
              label="State/Provinces"
              v-model="service_type.geo_states"
              :items="stateOptions"
              multiple
              small-chips
              deletable-chips
              :rules="showStates ? [rules.selectOne] : []"
              :disabled="disabled"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import HttpStatus from "http-status";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  createServiceType,
  deleteServiceTypeFile,
  updateServiceType,
} from "../../api/serviceType.api";
import { zeroOrGreater, required, selectOne } from "../../utils/rules";
import FeePackages from "./FeePackages.vue";
import TextAreaInput from "../form/TextAreaInput.vue";
import YesNoButtonGroup from "../form/YesNoButtonGroup.vue";
import {
  acceptTypes,
  itemsNotDestroyed,
  printPageName,
} from "../../utils/helpers";
import { cloneDeep, isEqual } from "lodash";

export default {
  components: { FeePackages, TextAreaInput, YesNoButtonGroup },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFormValid: true,
      service_type: {
        id: null,
        service_id: null,
        sa_fee: null,
        sa_fee_same: null,
        sa_fee_same_desc: "",
        sa_fee_scholarship: null,
        sa_fee_contact_info: "",
        sa_fee_promote: "",
        sa_fee_services: "",
        coach_fee: null,
        coach_fee_price: null,
        coach_fee_same_rate: null,
        geo_scope: "",
        geo_local_desc: "",
        geo_countries: [],
        geo_states: [],
        contract_files: [],
        subscription_fees: null,
        subscription_fees_explain: "",
        standard_fee: null,
        same_fee_all_coaches: null,
        same_fee_all_coaches_no: "",
        diff_fee_per_region: null,
        service_price_packages_attributes: [],
        price_sheet_files: [],
        contract_conversation_files: [],
        price_conversation_files: [],
      },
      rules: {
        required,
        selectOne,
        zeroOrGreater,
      },
      acceptTypes: acceptTypes,
    };
  },
  watch: {
    "service_type.sa_fee"() {
      this.syncSaFeeQuestions();
    },
    "service_type.sa_fee_same"() {
      this.syncSaFeeQuestions();
    },
    "service_type.sa_fee_scholarship"() {
      this.syncSaFeeQuestions();
    },
    "service_type.coach_fee"() {
      this.syncCoachFeeQuestions();
    },
    "service_type.subscription_fees"() {
      this.syncSubFeeQuestions();
    },
    single_country(newValue, oldValue) {
      if (
        this.service_type.geo_scope === "national" ||
        this.service_type.geo_scope === "state" ||
        this.service_type.geo_scope === "regional"
      ) {
        this.service_type.geo_countries = [newValue];
      }
    },
    "service_type.geo_scope"(newValue, oldValue) {
      if (newValue !== "local") {
        this.service_type.geo_local_desc = "";
      }

      if (
        newValue !== "state" &&
        newValue !== "regional" &&
        this.service_type.geo_states
      ) {
        this.service_type.geo_states = [];
      }

      if (
        newValue !== "national" &&
        newValue !== "state" &&
        newValue !== "regional"
      ) {
        this.single_country = "";
      }

      if (newValue === "local" && this.service_type.geo_countries) {
        this.service_type.geo_countries = [];
      }
    },
  },
  methods: {
    ...mapActions("service", ["setServiceType"]),
    isValid() {
      return this.$refs.form.validate();
    },
    initialize() {
      if (!this.service_type.id) {
        this.reset();
      }
    },
    reset() {
      this.service_type = cloneDeep(this.service.service_type);
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.isValid()) {
        this.service_type.service_id = this.service.id;
        this.$loading();
        if (this.service_type.id) {
          return this.update();
        } else {
          return this.create();
        }
      }
    },
    create() {
      return createServiceType(this.service_type)
        .then((response) =>
          this.processSaveResponse(response, HttpStatus.CREATED)
        )
        .finally(() => this.$hideLoading());
    },
    update() {
      return updateServiceType(this.service_type)
        .then((response) => this.processSaveResponse(response, HttpStatus.OK))
        .finally(() => this.$hideLoading());
    },
    deleteFile(fileId) {
      this.$loading();
      return deleteServiceTypeFile(this.service.id, fileId)
        .then((response) => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.setServiceType(response.data);
            this.service_type = cloneDeep(response.data);
            this.$notify.success("File deleted successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.setServiceType(response.data);
        this.service_type = cloneDeep(response.data);
        this.$notify.success("Service Type & Scope form saved successfully.");
        return true;
      } else {
        return false;
      }
    },
    syncSaFeeQuestions() {
      if (!this.service_type.sa_fee) {
        this.service_type.sa_fee_same = null;
        this.service_type.sa_fee_same_desc = "";
        this.service_type.sa_fee_scholarship = null;
        this.service_type.sa_fee_contact_info = "";
        this.service_type.sa_fee_promote = "";
        this.service_type.sa_fee_services = "";
      } else if (this.service_type.sa_fee_same) {
        this.service_type.sa_fee_same_desc = "";
      }
    },
    syncCoachFeeQuestions() {
      if (!this.service_type.coach_fee) {
        this.service_type.coach_fee_price = null;
        this.service_type.coach_fee_same_rate = null;
      }
    },
    syncSubFeeQuestions() {
      if (this.service_type.subscription_fees === false) {
        this.service_type.subscription_fees_explain = null;
        this.service_type.same_fee_all_coaches = null;
        this.service_type.same_fee_all_coaches_no = null;
      } else if (this.service_type.subscription_fees) {
        this.service_type.standard_fee = null;
      }
    },
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("listOfValues", ["serviceGeographicalTypes"]),
    ...mapGetters("listOfValues", ["countryOptions"]),
    ...mapGetters("user", ["isAdmin"]),
    ...mapGetters("serviceReview", ["conversationUploadOptions"]),
    isDirty() {
      return (
        this.service_type.id &&
        !isEqual(this.service_type, this.service.service_type)
      );
    },
    isNew() {
      return (
        this.service_type.id === null || this.service_type.id === undefined
      );
    },
    single_country: {
      get() {
        return this.service_type.geo_countries &&
          this.service_type.geo_countries.length > 0
          ? this.service_type.geo_countries[0]
          : "";
      },
      set(value) {
        if (
          this.service_type.geo_scope === "national" ||
          this.service_type.geo_scope === "state" ||
          this.service_type.geo_scope === "regional"
        ) {
          this.service_type.geo_countries = [value];
        }
      },
    },
    showSaSameFeeDesc() {
      return (
        this.service_type.sa_fee &&
        this.service_type.sa_fee_same != null &&
        this.service_type.sa_fee_same === false
      );
    },
    stateOptions() {
      return this.$store.getters["listOfValues/stateOptions"](
        this.single_country
      );
    },
    showLocal() {
      return this.service_type.geo_scope === "local";
    },
    showCountries() {
      return (
        this.service_type.geo_scope === "state" ||
        this.service_type.geo_scope === "regional" ||
        this.service_type.geo_scope === "national" ||
        this.service_type.geo_scope === "international"
      );
    },
    showStates() {
      return (
        this.service_type.geo_scope === "state" ||
        this.service_type.geo_scope === "regional"
      );
    },
    isNational() {
      return this.service_type.geo_scope === "national";
    },
    isInternational() {
      return this.service_type.geo_scope === "international";
    },
    geoLabel() {
      if (this.showLocal) {
        return "Describe what is included in the locale, identifying communities, groups, etc. that the locale encompasses.";
      } else if (this.showStates) {
        return "Identify the states/provinces included.";
      } else if (this.isNational) {
        return "Identify the country.";
      } else if (this.isInternational) {
        return "Identify the countries.";
      } else {
        return "";
      }
    },
    contractFilesValid() {
      let sizeValid = true;
      this.service_type.contract_files.forEach((file) => {
        if (file.size > 5000000) {
          sizeValid = false;
        }
      });
      return [
        this.service_type.contract_files.length > 0 ||
          this.service_type.contract_conversation_files.length > 0 ||
          this.service_type.contract_files_uploaded.length > 0 ||
          "At least one file must be selected/uploaded",
        this.service_type.contract_files.length +
          this.service_type.contract_files_uploaded.length <=
          10 || "A maximum of 10 files may be uploaded",
        sizeValid || "Files may not exceed 5MB in size",
      ];
    },
    packagesValid() {
      return (
        !this.service_type.subscription_fees ||
        this.service_type.diff_fee_per_region ||
        (!this.service_type.diff_fee_per_region &&
          itemsNotDestroyed(this.service_type.service_price_packages_attributes)
            .length > 0)
      );
    },
    priceSheetFilesValid() {
      let sizeValid = true;
      this.service_type.price_sheet_files.forEach((file) => {
        if (file.size > 5000000) {
          sizeValid = false;
        }
      });
      return [
        this.service_type.price_sheet_files.length > 0 ||
          this.service_type.price_conversation_files.length > 0 ||
          this.service_type.price_sheet_files_uploaded.length > 0 ||
          "At least one file must be selected/uploaded",
        this.service_type.price_sheet_files.length +
          this.service_type.price_sheet_files_uploaded.length <=
          10 || "A maximum of 10 files may be uploaded",
        sizeValid || "Files may not exceed 5MB in size",
      ];
    },
    isPrintPage() {
      return this.$router.currentRoute.name === printPageName;
    },
  },
};
</script>

<style scoped></style>
