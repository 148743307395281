import httpClient from "./httpClient";

const END_POINT = "/servicers";

const getServicers = () => httpClient.get(`${END_POINT}`);
const getServicer = id => httpClient.get(`${END_POINT}/${id}`);
const updateServicer = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { servicer: form };
  return httpClient.put(`${END_POINT}/${id}`, payload);
};

const updateServicerSuspension = data => {
  const payload = { servicer: { suspend_end_date: data.suspend_end_date } };
  return httpClient.post(`${END_POINT}/${data.id}/suspend`, payload);
};

const createServicer = data => {
  const payload = {
    servicer: data
  };
  return httpClient.post(END_POINT, payload);
};

const deleteServicer = servicerId => {
  return httpClient.delete(`${END_POINT}/${servicerId}`);
};

const confirmServicer = (email, token) => {
  const payload = {
    email: email,
    token: token
  };
  return httpClient.post(`${END_POINT}/confirm`, payload);
};

const completeServicer = (id, data) => {
  const payload = {
    servicer: data
  };
  return httpClient.post(`${END_POINT}/${id}/complete`, payload);
};

export {
  getServicers,
  getServicer,
  updateServicer,
  createServicer,
  deleteServicer,
  confirmServicer,
  completeServicer,
  updateServicerSuspension
};
