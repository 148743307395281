<template>
  <v-data-table
    :headers="headers"
    :items="packages"
    hide-default-footer
    disable-sort
    disable-pagination
    class="crud-table"
  >
    <template v-slot:top>
      <v-toolbar flat color="white" class="crud-toolbar">
        <v-label>Fee Packages</v-label>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-if="!disabled" v-slot:activator="{ on }">
            <v-btn color="success" v-on="on">Add Package</v-btn>
          </template>
          <v-form ref="smForm" v-model="isFormValid" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Package Name"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        maxlength="100"
                        :disabled="disabled"
                        persistent-hint
                        hint="Sport and Package Name (e.g.  Women's Basketball - Gold Package)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-currency-field
                        label="Amount"
                        v-model="editedItem.amount"
                        prefix="$"
                        :rules="[rules.required, rules.zeroOrGreater]"
                        :disabled="disabled"
                      ></v-currency-field>
                    </v-col>
                    <v-col cols="12">
                      <TextAreaInput
                        label="Description"
                        v-model="editedItem.description"
                        :disabled="disabled"
                      ></TextAreaInput>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      <em>No packages yet!</em>
    </template>
    <template v-if="!valid" v-slot:footer>
      <div>
        <span class="error--text ml-4">Please enter at least one package.</span>
      </div>
    </template>
    <template v-slot:item="props">
      <tr v-if="!props.item._destroy">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.description }}</td>
        <td class="text-end">
          <v-icon
            v-if="!disabled"
            small
            class="mr-2"
            @click="editItem(props.item)"
            color="primary"
          >
            $vuetify.icons.edit
          </v-icon>
          <v-icon
            v-if="!disabled"
            small
            @click="deleteItem(props.item)"
            color="error"
          >
            $vuetify.icons.delete
          </v-icon>
        </td>
      </tr>
      <tr v-else>
        <td colspan="3" class="text-center">
          <em
            >Row has been deleted, but form must still be saved.
            <a href="#" @click.prevent="undeleteItem(props.item)">Undo</a></em
          >
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { required, zeroOrGreater } from "../../utils/rules";
import TextAreaInput from "../form/TextAreaInput.vue";
export default {
  components: {
    TextAreaInput
  },
  props: {
    packages: {
      type: Array,
      required: true
    },
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Package Name",
          align: "start",
          value: "name"
        },
        { text: "Amount", value: "amount" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        amount: null,
        description: "",
        _destroy: false
      },
      defaultItem: {
        name: "",
        amount: null,
        description: "",
        _destroy: false
      },
      isFormValid: true,
      rules: {
        required,
        zeroOrGreater
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Package" : "Edit Package";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.packages.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.packages.indexOf(item);
      const response = confirm("Are you sure you want to delete this item?");

      if (response) {
        if (item.id) {
          this.packages.splice(index, 1, { ...item, _destroy: true });
        } else {
          this.packages.splice(index, 1);
        }
      }
    },
    undeleteItem(item) {
      const index = this.packages.indexOf(item);
      this.packages.splice(index, 1, { ...item, _destroy: false });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.smForm.resetValidation();
      }, 300);
    },

    save() {
      if (this.$refs.smForm.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.packages[this.editedIndex], this.editedItem);
        } else {
          this.packages.push(this.editedItem);
        }
        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.crud-table td,
.crud-table th {
  padding: 0 0 !important;
}

.crud-toolbar .v-toolbar__content,
.crud-toolbar .v-toolbar__extension {
  padding: 4px 0;
}
</style>
