const baseUrl = process.env.baseUrl;

// List of non-API Urls for reference within VueJS frontend
const handledRoutes = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  IMITATE_LOGIN: "/imitate_login"
};

function get(routeString) {
  return `${baseUrl}${routeString}`;
}

function imitateLoginUrl(email) {
  return `${get(handledRoutes.IMITATE_LOGIN)}?email=${email}`;
}

export default {
  base: get(""),
  login: get(handledRoutes.LOGIN),
  logout: get(handledRoutes.LOGOUT),
  imitateLoginUrl
};
