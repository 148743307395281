import httpClient from "./httpClient";

const END_POINT = "/services";
const SAMPLE_LINKS_END_POINT = "sample_links";

const getSampleLinks = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${SAMPLE_LINKS_END_POINT}`);

const createSampleLink = data => {
  const payload = {
    service_sample_link: data
  };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${SAMPLE_LINKS_END_POINT}`,
    payload
  );
};

const updateSampleLink = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_sample_link: form };
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${SAMPLE_LINKS_END_POINT}/${data.id}`,
    payload
  );
};

const deleteSampleLink = (id, serviceId) =>
  httpClient.delete(
    `${END_POINT}/${serviceId}/${SAMPLE_LINKS_END_POINT}/${id}`
  );

const uploadSamples = data => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.post(
    `${END_POINT}/${id}/${SAMPLE_LINKS_END_POINT}/upload`,
    prepareFormData(form)
  );
};

export {
  getSampleLinks,
  createSampleLink,
  updateSampleLink,
  deleteSampleLink,
  uploadSamples
};

function prepareFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    if (
      data[key] !== null &&
      (key === "sample_files" || key === "conversation_files")
    ) {
      for (let i = 0; i < data[key].length; i++) {
        const file = data[key][i];

        formData.append(key + "[]", file);
      }
    } else if (data[key] !== null && key !== "uploaded_files") {
      formData.append(key, data[key]);
    } else if (data[key] === null) {
      formData.append(key, "");
    }
  }

  return formData;
}
