<template>
  <v-card flat tile>
    <v-card-title class="print-title avoid-page-break" v-show="showTitle"
      >Identity</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Service Name"
              v-model="service_identity.name"
              :disabled="disabled"
              :rules="[rules.required]"
              maxlength="100"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Service Website"
              v-model="service_identity.website"
              :disabled="disabled"
              maxlength="100"
            />
          </v-col>
        </v-row>
        <SocialMedia
          :accounts="service_identity.service_social_media_accts_attributes"
          :disabled="disabled"
        ></SocialMedia>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Year of Establishment"
              v-model="service_identity.year_established"
              :items="estYears"
              :disabled="disabled"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-if="!servicer.is_international"
              label="Federal Tax ID (TIN/EIN)"
              v-model="service_identity.federal_tax_id"
              :disabled="disabled"
              maxlength="10"
              v-mask="'##-#######'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label>What are the dates of your subscription cycle?</v-label>
            <Tooltip
              text="Identify the range of dates the subscription applies to and when the service fee structures are likely to be changed."
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <Datepicker
              v-model="service_identity.sub_start_date"
              label="Start Date"
              :disabled="disabled"
              :max="service_identity.sub_end_date"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <Datepicker
              v-model="service_identity.sub_end_date"
              label="End Date"
              :disabled="disabled"
              :min="service_identity.sub_start_date"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Your service includes prospects from which sport(s)? (Select all
              that apply)</v-label
            >
          </v-col>
          <v-col cols="12">
            <v-checkbox
              label="Men's Basketball"
              v-model="service_identity.mbb"
              class="mt-0"
              :rules="sportRequired"
              hide-details
              :disabled="disabled"
            ></v-checkbox>
            <v-checkbox
              label="Women's Basketball"
              v-model="service_identity.wbb"
              class="mt-0"
              :rules="sportRequired"
              hide-details
              :disabled="disabled"
            ></v-checkbox>
            <v-checkbox
              label="Football"
              v-model="service_identity.mfb"
              class="mt-0"
              :rules="sportRequired"
              :disabled="disabled"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="service_identity.mbb">
          <v-col cols="12">
            <v-label>Is your service a nonprofit organization?</v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_identity.mbb_nonprofit"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row
          v-if="service_identity.mbb && service_identity.mbb_nonprofit"
          class="ml-4"
        >
          <v-col cols="12">
            <v-label
              >Does the nonprofit receive donations from men’s basketball
              programs?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_identity.mbb_nonprofit_donations"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row
          v-if="
            service_identity.mbb &&
              service_identity.mbb_nonprofit &&
              service_identity.mbb_nonprofit_donations
          "
          class="ml-8"
        >
          <v-col cols="12">
            <v-label
              >Is that money used to benefit nonscholastic teams, prospects or
              individuals associated with prospects (IAWPs)?</v-label
            >
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_identity.mbb_nonprofit_iawps"
              :disabled="disabled"
            ></YesNoButtonGroup>
          </v-col>
        </v-row>
        <v-row v-if="show_mbb_desc" class="ml-12">
          <v-col cols="12">
            <v-label
              >Please provide a description of what items are purchased using
              the donated funds and what types of benefits are
              provided.</v-label
            >
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_identity.mbb_nonprofit_desc"
              label="Items Purchased and Benefits Provided"
              :required="show_mbb_desc"
              :disabled="disabled"
            ></TextAreaInput>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Datepicker from "../form/Datepicker.vue";
import TextAreaInput from "../form/TextAreaInput.vue";
import Tooltip from "../form/Tooltip.vue";
import YesNoButtonGroup from "../form/YesNoButtonGroup.vue";
import SocialMedia from "./SocialMedia.vue";
import HttpStatus from "http-status";
import {
  createServiceIdentity,
  updateServiceIdentity
} from "../../api/serviceIdentity.api";
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import { required } from "../../utils/rules";
import { isEqual, cloneDeep } from "lodash";

export default {
  components: {
    SocialMedia,
    TextAreaInput,
    YesNoButtonGroup,
    Tooltip,
    Datepicker
  },
  data() {
    return {
      service_identity: {
        service_id: null,
        name: "",
        website: "",
        year_established: "",
        federal_tax_id: "",
        sub_start_date: "",
        sub_end_date: "",
        mfb: false,
        mbb: false,
        wbb: false,
        mbb_nonprofit: null,
        mbb_nonprofit_donations: null,
        mbb_nonprofit_iawps: null,
        mbb_nonprofit_desc: "",
        service_social_media_accts_attributes: []
      },
      isFormValid: true,
      rules: {
        required
      }
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    "service_identity.mbb"() {
      this.syncMBBQuestions();
    },
    "service_identity.mbb_nonprofit"() {
      this.syncMBBQuestions();
    },
    "service_identity.mbb_nonprofit_donations"() {
      this.syncMBBQuestions();
    },
    "service_identity.mbb_nonprofit_iawps"() {
      this.syncMBBQuestions();
    }
  },
  computed: {
    ...mapState("servicer", ["servicer"]),
    ...mapState("service", ["service"]),
    isDirty() {
      return (
        this.service_identity.id &&
        !isEqual(this.service_identity, this.service.service_identity)
      );
    },
    isNew() {
      return (
        this.service_identity.id === null ||
        this.service_identity.id === undefined
      );
    },
    estYears() {
      const currentYear = new Date().getFullYear();
      const years = [currentYear];

      for (let i = currentYear; i >= 1970; i--) {
        years.push(i);
      }

      return years;
    },
    show_mbb_desc() {
      return (
        this.service_identity.mbb &&
        this.service_identity.mbb_nonprofit &&
        this.service_identity.mbb_nonprofit_donations &&
        this.service_identity.mbb_nonprofit_iawps
      );
    },
    sportRequired() {
      return [
        this.service_identity.mbb ||
          this.service_identity.wbb ||
          this.service_identity.mfb ||
          "At least one sport should be selected"
      ];
    }
  },
  methods: {
    ...mapActions("service", ["setService", "setServiceIdentity"]),
    initialize() {
      if (!this.service_identity.id) {
        this.reset();
      }
    },
    isValid() {
      return this.$refs.form.validate();
    },
    syncMBBQuestions() {
      if (!this.service_identity.mbb) {
        this.service_identity.mbb_nonprofit = null;
        this.service_identity.mbb_nonprofit_donations = null;
        this.service_identity.mbb_nonprofit_iawps = null;
        this.service_identity.mbb_nonprofit_desc = null;
      } else if (!this.service_identity.mbb_nonprofit) {
        this.service_identity.mbb_nonprofit_donations = null;
        this.service_identity.mbb_nonprofit_iawps = null;
        this.service_identity.mbb_nonprofit_desc = null;
      } else if (!this.service_identity.mbb_nonprofit_donations) {
        this.service_identity.mbb_nonprofit_iawps = null;
        this.service_identity.mbb_nonprofit_desc = null;
      } else if (!this.service_identity.mbb_nonprofit_iawps) {
        this.service_identity.mbb_nonprofit_desc = null;
      }
    },
    save() {
      if (this.isValid()) {
        Vue.prototype.$loading();
        if (this.service_identity.service_id) {
          return this.update();
        } else {
          return this.create();
        }
      }
    },
    create() {
      return createServiceIdentity(this.service_identity)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.setService(response.data);
            this.$notify.success("Service Identity form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => Vue.prototype.$hideLoading());
    },
    update() {
      return updateServiceIdentity(this.service_identity)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.setServiceIdentity(response.data);
            this.$notify.success("Service Identity form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => Vue.prototype.$hideLoading());
    },
    reset() {
      this.service_identity = cloneDeep(this.service.service_identity);
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style scoped></style>
