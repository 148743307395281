import httpClient from "./httpClient";

const END_POINT = "/admin/attestations";

const getAttestations = () => httpClient.get(END_POINT);
const createAttestation = data => {
  return httpClient.post(`${END_POINT}`, data);
};

const updateAttestation = data => {
  const { id, ...form } = data; // remove id from form object
  return httpClient.put(`${END_POINT}/${id}`, form);
};

const deleteAttestation = id => {
  return httpClient.delete(`${END_POINT}/${id}`);
};

export {
  getAttestations,
  createAttestation,
  updateAttestation,
  deleteAttestation
};
