import { getServiceStatusLogs } from "../../api/serviceStatusLog.api";
import { findIndex, findLastIndex } from "lodash";
import { getServiceConversations } from "../../api/serviceConversations.api";
import { getStaffNotes } from "../../api/serviceStaffNotes.api";
import { status } from "../../utils/helpers";

export default {
  namespaced: true,
  state: {
    status_logs: [],
    conversations: [],
    conversationsLoading: false,
    staffNotes: []
  },
  getters: {
    formConversations: state => formId => {
      return state.conversations.filter(
        conversation => conversation.form_id === formId
      );
    },
    formOpenConversations: state => formId => {
      return state.conversations.filter(
        conversation => conversation.form_id === formId && !conversation.closed
      );
    },
    openConversations: state => {
      return state.conversations.filter(conversation => !conversation.closed);
    },
    getStaffNotesCount: state => {
      return state.staffNotes.filter(
        note => note.id !== undefined && note.id !== null
      ).length;
    },
    conversationUploadOptions: state => {
      const options = extractConversationUploads(state.conversations);
      options.sort((a, b) => a.text - b.text);
      return options;
    }
  },
  mutations: {
    SET_STATUS_LOGS(state, statusLogs) {
      state.status_logs = statusLogs;
    },
    ADD_STATUS_LOG: (state, payload) => state.status_logs.push(payload),
    UPDATE_STATUS_LOG: (state, payload) => {
      const index = findIndex(state.status_logs, log => log.id === payload.id);
      state.status_logs.splice(index, 1, payload);
    },
    SET_CONVERSATIONS(state, conversations) {
      state.conversations = conversations;
    },
    ADD_CONVERSATION: (state, { index, payload }) => {
      state.conversations.splice(index, 0, payload);
    },
    UPDATE_CONVERSATION: (state, { index, payload }) => {
      state.conversations.splice(index, 1, payload);
    },
    REMOVE_CONVERSATION: (state, { parentIndex, replyIndex }) => {
      if (replyIndex !== undefined && replyIndex !== null && replyIndex > -1) {
        state.conversations[parentIndex].replies.splice(replyIndex, 1);
      } else {
        state.conversations.splice(parentIndex, 1);
      }
    },
    SHOW_CONV_LOADING: state => (state.conversationsLoading = true),
    HIDE_CONV_LOADING: state => (state.conversationsLoading = false),
    SET_STAFF_NOTES(state, staffNotes) {
      state.staffNotes = staffNotes;
    },
    ADD_STAFF_NOTE: (state, payload) => {
      state.staffNotes.unshift(payload);
    },
    UPDATE_STAFF_NOTE: (state, { index, payload }) => {
      state.staffNotes.splice(index, 1, payload);
    },
    REMOVE_STAFF_NOTE: (state, index) => {
      state.staffNotes.splice(index, 1);
    }
  },
  actions: {
    async fetchStatusLogs({ state, commit }, serviceId) {
      try {
        const response = await getServiceStatusLogs(serviceId);

        if (response.status === 200) {
          commit("SET_STATUS_LOGS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addStatusLog({ commit }, statusLog) {
      commit("ADD_STATUS_LOG", statusLog);
      if (statusLog.status_id !== status.STAFF_EDIT) {
        commit("service/SET_SERVICE_STATUS", statusLog, {
          root: true
        });
      }
    },
    updateStatusLog({ commit }, statusLog) {
      commit("UPDATE_STATUS_LOG", statusLog);
    },
    async fetchConversations({ commit }, serviceId) {
      try {
        commit("SHOW_CONV_LOADING");
        const response = await getServiceConversations(serviceId);

        if (response.status === 200) {
          commit("SET_CONVERSATIONS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      } finally {
        commit("HIDE_CONV_LOADING");
      }
    },
    addConversation({ state, commit }, payload) {
      let index = findLastIndex(
        state.conversations,
        conversation => conversation.form_id === payload.form_id
      );

      index += 1;

      commit("ADD_CONVERSATION", { index, payload });
    },
    updateConversation({ state, commit }, payload) {
      let index = findIndex(
        state.conversations,
        conversation => conversation.id === payload.id
      );

      // Couldn't find existing conversation, meaning this was new so replace the last conversation.
      if (index < 0) {
        index = findLastIndex(
          state.conversations,
          conversation =>
            conversation.form_id === payload.form_id && !conversation.id
        );
      }

      commit("UPDATE_CONVERSATION", { index, payload });
    },
    removeConversation({ commit }, { parentIndex, replyIndex }) {
      commit("REMOVE_CONVERSATION", { parentIndex, replyIndex });
    },
    async fetchStaffNotes({ commit }, serviceId) {
      try {
        const response = await getStaffNotes(serviceId);

        if (response.status === 200) {
          commit("SET_STAFF_NOTES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addNewStaffNote({ commit }, payload) {
      commit("ADD_STAFF_NOTE", payload);
    },
    updateStaffNote({ state, commit }, payload) {
      const index = findIndex(state.staffNotes, note => note.id === payload.id);

      if (index >= 0) {
        commit("UPDATE_STAFF_NOTE", { index, payload });
      } else {
        const newIndex = findIndex(state.staffNotes, note => !note.id);
        commit("UPDATE_STAFF_NOTE", { newIndex, payload });
      }
    },
    removeStaffNote({ state, commit }, id) {
      const index = findIndex(state.staffNotes, note => note.id === id);

      commit("REMOVE_STAFF_NOTE", index);
    },
    removeNewStaffNote({ state, commit }) {
      const newIndex = findIndex(state.staffNotes, note => !note.id);
      commit("REMOVE_STAFF_NOTE", newIndex);
    }
  }
};

function extractConversationUploads(conversations) {
  let fileOptions = [];
  for (const conversation of conversations) {
    if (conversation.conversation_files_uploaded) {
      fileOptions = fileOptions.concat(
        conversation.conversation_files_uploaded.map(file => {
          return { text: file.file_name, value: file.id };
        })
      );
    }

    if (conversation.replies && conversation.replies.length > 0) {
      fileOptions = fileOptions.concat(
        extractConversationUploads(conversation.replies)
      );
    }
  }

  return fileOptions;
}
