<template>
  <div>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Application</v-toolbar-title>
      <v-spacer />
      <v-btn
        class="success mr-2"
        dark
        small
        @click="showConfirmPaymentDialog(service)"
        v-if="service.can_pay"
      >
        <v-icon left small>$vuetify.icons.pay</v-icon>
        <span class="font-weight-bold">Pay</span>
      </v-btn>
      <v-btn
        class="mr-2"
        small
        text
        :to="{ name: 'applicationPrint', params: { id: service.id } }"
        v-if="doesServiceExist"
        ><v-icon left small>$vuetify.icons.print</v-icon> Print</v-btn
      >
      <span v-if="service.status">Status: {{ service.status }}</span>
    </v-toolbar>
    <v-stepper v-model="step" vertical class="elevation-1">
      <StatusLogs v-if="service.status_id && service.status_id > 1" />
      <ApplicationStep
        :step="1"
        label="Service Identity"
        :complete="doesServiceExist && isFormValid($refs.identity)"
        editable
        :component-ref="$refs.identity"
        :current-step="step"
        :open-conversation="conversationAlert(1)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="1" v-if="service.can_view_comments" />
          <Identity
            ref="identity"
            v-model="service.service_identity"
            :disabled="!service.can_edit"
          />
          <v-btn
            color="primary"
            @click="saveForm($refs.identity)"
            v-text="saveBtnText"
          ></v-btn>
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.identity)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :step="2"
        label="Service Format"
        :complete="formatComplete && isFormValid($refs.format)"
        :editable="doesServiceExist"
        :component-ref="$refs.format"
        :current-step="step"
        :open-conversation="conversationAlert(2)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="2" v-if="service.can_view_comments" />
          <Format ref="format" :disabled="!service.can_edit" />
          <v-btn
            color="primary"
            @click="saveForm($refs.format)"
            v-text="saveBtnText"
          ></v-btn>
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.format)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :complete="typeComplete && isFormValid($refs.type)"
        :editable="formatComplete"
        :step="3"
        label="Service Type & Scope"
        :component-ref="$refs.type"
        :current-step="step"
        :open-conversation="conversationAlert(3)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="3" v-if="service.can_view_comments" />
          <Type ref="type" :disabled="!service.can_edit" />
          <v-btn
            color="primary"
            @click="saveForm($refs.type)"
            v-text="saveBtnText"
          ></v-btn>
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.type)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :complete="staffComplete && isFormValid($refs.staff)"
        :editable="typeComplete"
        :step="4"
        label="Staff"
        :component-ref="$refs.staff"
        :current-step="step"
        :open-conversation="conversationAlert(4)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="4" v-if="service.can_view_comments" />
          <Staff ref="staff" :disabled="!service.can_edit" />
          <v-btn
            color="primary"
            @click="saveForm($refs.staff)"
            v-text="saveBtnText"
          ></v-btn>
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.staff)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :complete="relationshipsComplete && isFormValid($refs.relationships)"
        :editable="staffComplete"
        :step="5"
        label="Relationships"
        :component-ref="$refs.relationships"
        :current-step="step"
        :open-conversation="conversationAlert(5)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="5" v-if="service.can_view_comments" />
          <Relationships ref="relationships" :disabled="!service.can_edit" />
          <v-btn
            color="primary"
            @click="saveForm($refs.relationships)"
            v-text="saveBtnText"
          ></v-btn>
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.relationships)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :complete="promotionComplete && isFormValid($refs.promotion)"
        :editable="relationshipsComplete"
        :step="6"
        label="Promotion of the Service"
        :component-ref="$refs.promotion"
        :current-step="step"
        :open-conversation="conversationAlert(6)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="6" v-if="service.can_view_comments" />
          <Promotion ref="promotion" :disabled="!service.can_edit" />
          <v-btn
            color="primary"
            @click="saveForm($refs.promotion)"
            v-text="saveBtnText"
          ></v-btn>
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.promotion)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :complete="uploadComplete && isFormValid($refs.uploads)"
        :editable="promotionComplete"
        :step="7"
        label="Sample Upload"
        :component-ref="$refs.uploads"
        :current-step="step"
        :open-conversation="conversationAlert(7)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="7" v-if="service.can_view_comments" />
          <Uploads ref="uploads" :disabled="!service.can_edit" />
          <v-btn color="primary" @click="saveForm($refs.uploads)"
            >Continue</v-btn
          >
          <v-btn
            v-if="service.can_edit"
            color="default"
            @click="resetForm($refs.uploads)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>

      <ApplicationStep
        :complete="submitComplete"
        :editable="uploadComplete"
        :step="8"
        label="Submission"
        :component-ref="$refs.submit"
        :current-step="step"
        :open-conversation="conversationAlert(8)"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Conversation :form-id="8" v-if="service.can_view_comments" />
          <Submit ref="submit" :disabled="!service.can_edit" />
          <v-btn
            v-if="service.can_edit && !submitComplete"
            color="primary"
            @click="submit"
            >Submit</v-btn
          >
        </template>
      </ApplicationStep>
      <ApplicationStep
        v-if="service.can_perf"
        :complete="perfComplete"
        :editable="service.can_perf"
        :step="9"
        label="Post-Execution Review Form (PERF)"
        :component-ref="$refs.perf"
        :current-step="step"
        v-on:clickStep="onStepChange"
      >
        <template v-slot:content>
          <Perf ref="perf" :disabled="!service.can_perf" />
          <v-btn
            v-if="service.can_perf"
            color="primary"
            @click="saveForm($refs.perf)"
            >Save</v-btn
          >
          <v-btn
            v-if="service.can_perf"
            color="default"
            @click="resetForm($refs.perf)"
            >Reset</v-btn
          >
        </template>
      </ApplicationStep>
    </v-stepper>
    <v-tooltip top v-if="isAdmin">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          fixed
          bottom
          right
          color="info"
          @click="dialog = true"
          v-on="on"
        >
          <v-badge
            :value="getStaffNotesCount > 0"
            color="green"
            :content="getStaffNotesCount"
          >
            <v-icon>$vuetify.icons.clipboard</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span>Staff Notes</span>
    </v-tooltip>
    <ServiceStaffNotes v-if="isAdmin" v-model="dialog" />
  </div>
</template>

<script>
import Format from "../components/service/Format.vue";
import Identity from "../components/service/Identity.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Type from "../components/service/Type.vue";
import Promotion from "../components/service/Promotion.vue";
import Staff from "../components/service/Staff.vue";
import Relationships from "../components/service/Relationships.vue";
import Uploads from "../components/service/Uploads.vue";
import Submit from "../components/service/Submit.vue";
import ApplicationStep from "../components/service/ApplicationStep.vue";
import StatusLogs from "../components/service/StatusLogs.vue";
import Conversation from "../components/service/Conversation.vue";
import { status } from "../utils/helpers";
import ServiceStaffNotes from "../components/ServiceStaffNotes.vue";
import Perf from "../components/service/Perf.vue";

export default {
  components: {
    Conversation,
    StatusLogs,
    ApplicationStep,
    Submit,
    Uploads,
    Relationships,
    Format,
    Identity,
    Type,
    Staff,
    Promotion,
    ServiceStaffNotes,
    Perf,
  },
  data() {
    return {
      step: 1,
      previousStep: 1,
      dialog: false,
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapGetters("user", ["isAdmin"]),
    ...mapGetters("serviceReview", ["getStaffNotesCount"]),
    doesServiceExist() {
      return typeof this.service.id === "number";
    },
    formatComplete() {
      return (
        this.doesServiceExist &&
        typeof this.service.service_format.id === "number"
      );
    },
    typeComplete() {
      return (
        this.doesServiceExist &&
        typeof this.service.service_type.id === "number"
      );
    },
    staffComplete() {
      return (
        this.doesServiceExist &&
        typeof this.service.service_staff.id === "number"
      );
    },
    relationshipsComplete() {
      return (
        this.doesServiceExist &&
        typeof this.service.service_relationship.id === "number"
      );
    },
    promotionComplete() {
      return (
        this.doesServiceExist &&
        typeof this.service.service_promotion.id === "number"
      );
    },
    uploadComplete() {
      return (
        this.doesServiceExist && this.service.service_sample_links.length > 0
      );
    },
    submitComplete() {
      return this.doesServiceExist && this.service.status_id > status.PENDING;
    },
    perfComplete() {
      return (
        this.doesServiceExist &&
        (this.service.subscribers.length > 0 ||
          this.service.service_perf_reports.length > 0)
      );
    },
    saveBtnText() {
      return this.service.can_edit ? "Save & Continue" : "Continue";
    },
  },
  methods: {
    ...mapActions("listOfValues", [
      "fetchOrganizations",
      "fetchAttestations",
      "fetchServiceAssociateTypes",
    ]),
    ...mapActions("servicePayment", ["showConfirmPaymentDialog"]),
    ...mapActions("serviceReview", ["fetchConversations"]),
    ...mapGetters("serviceReview", ["formOpenConversations"]),
    ...mapActions("admin", ["fetchCheatsheets"]),
    onStepChange() {
      if (this.previousStep === this.step) {
        this.step = 0;
        this.previousStep = 0;
      } else {
        this.previousStep = this.step;
      }
    },
    showFormErrors() {
      this.$notify.error("Please fix form error(s)");
    },
    isFormValid(ref) {
      return ref ? ref.isFormValid : true;
    },
    saveForm(ref) {
      if (
        this.step < 9 &&
        this.service.can_edit &&
        (ref.isDirty || ref.isNew)
      ) {
        if (ref.isValid()) {
          ref.save().then((result) => {
            if (result) {
              this.step++;
            }
          });
        } else {
          this.showFormErrors();
        }
      } else if (this.step === 9 && this.service.can_perf && ref.isDirty) {
        ref.save();
      } else {
        this.step++;
      }
    },
    resetForm(ref) {
      if (this.service.can_edit) {
        ref.reset();
      }
    },
    submit() {
      if (this.canSubmit()) {
        this.$refs.submit.submit();
      }
    },
    canSubmit() {
      if (
        this.$refs.identity.isFormValid &&
        this.$refs.format.isFormValid &&
        this.$refs.type.isFormValid &&
        this.$refs.staff.isFormValid &&
        this.$refs.relationships.isFormValid &&
        this.$refs.promotion.isFormValid &&
        this.$refs.uploads.isFormValid &&
        this.$refs.submit.isValid() &&
        !this.$refs.identity.isDirty &&
        !this.$refs.format.isDirty &&
        !this.$refs.type.isDirty &&
        !this.$refs.staff.isDirty &&
        !this.$refs.relationships.isDirty &&
        !this.$refs.promotion.isDirty &&
        !this.$refs.uploads.isDirty
      ) {
        return true;
      } else {
        this.$notify.error(
          "Please fix form error(s) and/or save changes prior to submitting application."
        );
        return false;
      }
    },
    startingStep() {
      if (this.service.can_perf) {
        return 9;
      } else if (this.submitComplete) {
        return 0;
      } else if (this.uploadComplete) {
        return 8;
      } else if (this.promotionComplete) {
        return 7;
      } else if (this.relationshipsComplete) {
        return 6;
      } else if (this.staffComplete) {
        return 5;
      } else if (this.typeComplete) {
        return 4;
      } else if (this.formatComplete) {
        return 3;
      } else if (this.doesServiceExist) {
        return 2;
      } else {
        return 1;
      }
    },
    conversationAlert(formId) {
      return (
        this.service.can_view_comments &&
        this.$store.getters["serviceReview/formOpenConversations"](formId)
          .length > 0
      );
    },
  },
  watch: {
    step(val) {
      switch (parseInt(val)) {
        case 2:
          this.$refs.format.initialize();
          break;
        case 3:
          this.$refs.type.initialize();
          break;
        case 4:
          this.$refs.staff.initialize();
          break;
        case 5:
          this.$refs.relationships.initialize();
          break;
        case 6:
          this.$refs.promotion.initialize();
          break;
        case 7:
          this.$refs.uploads.initialize();
          break;
        case 8:
          this.$refs.submit.initialize();
          break;
        case 9:
          this.$refs.perf.initialize();
          break;
      }
    },
  },
  // async beforeMount() {
  //   // this.fetchOrganizations();
  //   console.log("nothing");
  // },
  async beforeMount() {
    this.step = this.startingStep();
    this.previousStep = this.step;
    this.fetchOrganizations();
    this.fetchAttestations();
    this.fetchServiceAssociateTypes();

    if (this.service.id && this.service.status_id > status.PENDING) {
      this.fetchConversations(this.service.id);
      if (this.isAdmin) {
        this.fetchCheatsheets();
      }
    }
  },
};
</script>

<style scoped>
.v-stepper__step--active {
  background-color: #e3f2fd;
  opacity: 0.8;
}

.v-stepper {
  border-radius: 0;
}
</style>
