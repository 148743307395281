import { fetchUser } from "../../api/users.api";

export default {
  namespaced: true,
  state: {
    user: JSON.parse(sessionStorage.getItem("user")),
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    accountComplete(state) {
      return !!state.user && state.user.is_account_complete;
    },
    isAdmin(state) {
      return !!state.user && state.user.is_staff;
    },
    isServicer(state) {
      return !!state.user && state.user.is_servicer;
    },
    isMember(state) {
      return !!state.user && state.user.is_membership;
    },
    isStaffNoAccount(state) {
      return !!state.user && state.user.is_staff_no_account;
    },
    isImpersonating(state) {
      return !!state.user && state.user.is_impersonating;
    },
  },
  mutations: {
    SET_USER(state, user) {
      // eslint-disable-next-line no-param-reassign
      state.user = user;
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    CLEAR_USER(state) {
      sessionStorage.removeItem("user");
      state.user = null;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const response = await fetchUser();

        if (response.status === 200) {
          commit("SET_USER", response.data);
          if (response.data.servicer) {
            commit("servicer/SET_SERVICER", response.data.servicer, {
              root: true,
            });
          }
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
  },
};
