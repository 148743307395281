import httpClient from "./httpClient";

const END_POINT = "/services";
const PERF_END_POINT = "perf";

const updateServicePerf = data => {
  const { id, ...form } = data;

  return httpClient.put(
    `${END_POINT}/${id}/${PERF_END_POINT}`,
    prepareFormData(form)
  );
};

const deletePerfReport = (id, serviceId) =>
  httpClient.delete(`${END_POINT}/${serviceId}/${PERF_END_POINT}/${id}`);

function prepareFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    if (data[key] !== null && Array.isArray(data[key])) {
      if (data[key].length > 0) {
        for (let i = 0; i < data[key].length; i++) {
          const item = data[key][i];

          formData.append(key + "[]", item);
        }
      } else {
        formData.append(key + "[]", "");
      }
    } else if (data[key] !== null) {
      formData.append(key, data[key]);
    } else if (data[key] === null) {
      formData.append(key, "");
    }
  }

  return formData;
}

export { updateServicePerf, deletePerfReport };
