<template>
  <div>
    <v-form
      ref="form"
      v-model="isFormValid"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-card tile flat outlined>
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="2">
              <v-select
                label="Prefix"
                v-model="profile.prefix"
                :items="prefixOptions"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="First Name"
                v-model="profile.first_name"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="Last Name"
                v-model="profile.last_name"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="Suffix"
                v-model="profile.suffix"
                hint="optional"
                persistent-hint
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="5">
              <v-text-field
                label="Email"
                v-model="profile.email"
                :rules="[rules.required, rules.email]"
                :disabled="disableEmail"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                label="Phone"
                type="tel"
                v-model="profile.phone"
                v-mask="phoneMask"
                :maxlength="profile.international_phone ? 20 : 14"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch
                label="International?"
                v-model="profile.international_phone"
              />
            </v-col>
          </v-row>
          <div class="title">Business Address</div>
          <Address
            v-model="profile.business_address_attributes"
            id="bus"
            class="border-0"
          />

          <div class="title">Mailing Address</div>

          <v-switch
            label="Click here if mailing and business address are the same."
            v-model="copyAddress"
            @change="toggleCopy"
          />

          <Address
            v-model="profile.home_address_attributes"
            id="home"
            class="border-0"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text :to="{ name: 'home' }"> Cancel </v-btn>
          <v-btn color="primary" type="submit"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-tooltip top v-if="isAdmin">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          fixed
          bottom
          right
          color="info"
          @click="dialog = true"
          v-on="on"
        >
          <v-badge
            :value="getStaffNotesCount > 0"
            color="green"
            :content="getStaffNotesCount"
          >
            <v-icon>$vuetify.icons.clipboard</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span>Staff Notes</span>
    </v-tooltip>
    <ServicerStaffNotes v-if="isAdmin" v-model="dialog" />
  </div>
</template>

<script>
import Address from "../components/Address.vue";
import { createServicer, updateServicer } from "../api/servicers.api";
import HttpStatus from "http-status";
import { mapActions, mapGetters, mapState } from "vuex";
import { required, email } from "../utils/rules";
import ServicerStaffNotes from "../components/ServicerStaffNotes.vue";

const prefixOptions = ["Mr.", "Mrs.", "Ms"];

const defaultAddress = {
  street1: "",
  street2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
};

export default {
  components: { ServicerStaffNotes, Address },
  props: {
    servicer: {
      type: Object,
    },
  },
  data() {
    return {
      isFormValid: true,
      dialog: false,
      copyAddress: false,
      profile: {
        prefix: "",
        first_name: "",
        last_name: "",
        suffix: "",
        email: "",
        phone: "",
        international_phone: false,
        business_address_attributes: { ...defaultAddress },
        home_address_attributes: { ...defaultAddress },
      },
      rules: {
        required,
        email,
      },
    };
  },
  computed: {
    ...mapGetters("servicer", ["getStaffNotesCount"]),
    prefixOptions() {
      return prefixOptions;
    },
    phoneMask() {
      return this.profile.international_phone ? null : "(###) ###-####";
    },
    message() {
      return this.$route.params.message;
    },
    title() {
      return this.$route.name === "register"
        ? "Register New Account"
        : "Edit Profile";
    },
    disableEmail() {
      return this.loggedIn && !this.servicer;
    },
    ...mapGetters("user", ["loggedIn", "isAdmin"]),
    ...mapState("user", {
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions("servicer", ["fetchStaffNotes"]),
    ...mapActions("user", ["fetchUser"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.$loading();
        if (this.servicer) {
          this.update();
        } else {
          this.create();
        }
      }
    },
    toggleCopy(checked) {
      if (checked) {
        this.profile.home_address_attributes = {
          ...this.profile.business_address_attributes,
          id: this.profile.home_address_attributes.id,
        };
      }
    },
    create() {
      createServicer(this.profile)
        .then((response) => {
          if (response.status === HttpStatus.CREATED) {
            if (response.data.code && response.data.code === "success") {
              this.fetchUser().then((response) => {
                this.$router.push({
                  name: "home",
                  params: {
                    successMessage: "Profile created successfully.",
                  },
                });
              });
            } else {
              this.$router.push({
                name: "complete",
                params: {
                  code: "confirm",
                },
              });
            }
          }
        })
        .finally(() => this.$hideLoading());
    },
    update() {
      updateServicer(this.profile)
        .then((response) => {
          if (response.status === HttpStatus.OK) {
            this.$notify.success("Profile saved successfully.");
            this.$store.commit("servicer/SET_SERVICER", response.data);
          }
        })
        .finally(() => this.$hideLoading());
    },
  },
  mounted() {
    disableAutofill();

    if (this.message) {
      this.$notify.info(this.message);
    }

    if (this.loggedIn && !this.servicer) {
      this.profile.first_name = this.user.first_name;
      this.profile.last_name = this.user.last_name;
      this.profile.email = this.user.email;
    } else if (this.servicer) {
      this.profile = { ...this.servicer };
      if (this.isAdmin) {
        this.fetchStaffNotes(this.servicer.id);
      }
    }
  },
};

function disableAutofill() {
  var inputs = document.getElementsByTagName("input");
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].autocomplete = "new-password";
  }
}
</script>
