<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-btn color="info" small @click="dialogForm = true">
            Change Request
          </v-btn>
        </span>
      </template>
      <span>Request Change/Cancellation</span>
    </v-tooltip>
    <v-dialog v-model="dialogForm" max-width="500px">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="saveChangeRequest"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Request</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-alert type="info" cols="12">
                  Submit a request to change or cancel this service application.
                </v-alert>
                <v-col cols="12">
                  <TextAreaInput
                    v-model="status_log.comment"
                    :outlined="false"
                    label="Change/Cancellation Request Description"
                    :rows="1"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Submit Change Request</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { createChangeRequest } from "../../api/serviceStatusLog.api";
import HttpStatus from "http-status";
import TextAreaInput from "../form/TextAreaInput.vue";

export default {
  name: "ChangeRequest",
  components: { TextAreaInput },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      status_log: {
        comment: ""
      },
      default_log: {
        comment: ""
      }
    };
  },
  computed: {
    ...mapState("service", ["service"])
  },
  methods: {
    ...mapActions("serviceReview", ["addStatusLog"]),
    isValid() {
      return this.$refs.form.validate();
    },
    close() {
      this.dialogForm = false;
      this.status_log = { ...this.default_log };
      this.$refs.form.resetValidation();
    },
    saveChangeRequest() {
      if (this.isValid()) {
        this.dialogForm = false;
        this.$loading();
        return createChangeRequest(this.service.id, this.status_log)
          .then(response => {
            if (response.status === HttpStatus.CREATED) {
              // Update vuex store
              this.close();
              this.$notify.success("Change Request submitted successfully.");
              this.addStatusLog(response.data);
            }
          })
          .finally(() => this.$hideLoading());
      }
    }
  }
};
</script>

<style scoped></style>
