import { findIndex } from "lodash";
import { getMessages, toggleRead } from "../../api/inboxMessages.api";
import HttpStatus from "http-status";
import { INBOX_STAFF_ID } from "../../utils/helpers";

export default {
  namespaced: true,
  state: {
    message: {
      to: null,
      subject: "",
      text: "",
      read: false
    },
    messages: [],
    inboxLoading: false
  },
  getters: {
    isUnreadMessage(state, getters, rootState, rootGetters) {
      const isAdmin = rootGetters["user/isAdmin"];
      const queryTo = isAdmin ? INBOX_STAFF_ID : rootState.user.user.id;
      if (state.message.read) {
        const unreadReplies = state.message.replies.filter(
          reply => !reply.read && reply.to_id === queryTo
        );

        return unreadReplies.length > 0;
      } else {
        return state.message.to_id === queryTo;
      }
    },
    unreadMessageCount(state, getters, rootState, rootGetters) {
      const isAdmin = rootGetters["user/isAdmin"];
      const queryTo = isAdmin ? INBOX_STAFF_ID : rootState.user.user.id;

      const unread = state.messages.filter(message => {
        if (message.read) {
          const unreadReplies = message.replies.filter(
            reply => !reply.read && reply.to_id === queryTo
          );

          return unreadReplies.length > 0;
        } else {
          return message.to_id === queryTo;
        }
      });

      return unread.length;
    }
  },
  mutations: {
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages;
    },
    ADD_MESSAGE: (state, payload) => {
      state.messages.unshift(payload);
    },
    UPDATE_MESSAGE: (state, { index, payload }) => {
      state.messages.splice(index, 1, payload);
    },
    REMOVE_UNSAVED_REPLY: (state, index) => {
      state.message.replies.splice(index, 1);
    },
    SHOW_INBOX_LOADING: state => (state.inboxLoading = true),
    HIDE_INBOX_LOADING: state => (state.inboxLoading = false)
  },
  actions: {
    async fetchMessages({ commit }) {
      try {
        commit("SHOW_INBOX_LOADING");
        const response = await getMessages();

        if (response.status === 200) {
          commit("SET_MESSAGES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      } finally {
        commit("HIDE_INBOX_LOADING");
      }
    },
    updateMessage({ state, commit }, payload) {
      commit("SET_MESSAGE", payload);

      const index = findIndex(
        state.messages,
        message => message.id === payload.id
      );

      if (index >= 0) {
        commit("UPDATE_MESSAGE", { index, payload });
      } else {
        commit("ADD_MESSAGE", payload);
      }
    },
    setMessage({ commit }, message) {
      commit("SET_MESSAGE", message);
    },
    async readMessage({ state, dispatch, getters }) {
      if (getters.isUnreadMessage) {
        try {
          const response = await toggleRead(state.message.id);

          if (response.status === HttpStatus.OK) {
            dispatch("updateMessage", response.data);
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    removeUnsavedReply({ state, commit }) {
      if (state.message.replies.length > 0) {
        const index = findIndex(state.message.replies, reply => !reply.id);

        if (index >= 0) {
          commit("REMOVE_UNSAVED_REPLY", index);
        }
      }
    }
  }
};
