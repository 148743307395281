<!--Component largely based on the vuetify-datetime-picker: https://github.com/darrenfang/vuetify-datetime-picker -->
<template>
  <v-menu
    v-model="display"
    ref="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    bottom
    :max-width="dialogWidth"
    :min-width="dialogWidth"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        v-model="formattedDatetime"
        v-on="on"
        :readonly="readonly"
        :rules="rules"
        :hint="showHint ? dateTimeFormat + ' format' : ''"
        :persistentHint="showHint"
        :maxlength="dateTimeFormat.length"
        @blur="onTextUpdate()"
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>far fa-calendar-alt</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>far fa-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width
              :max="max"
              :min="min"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
              full-width
              :use-seconds="useSeconds"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{
            clearText
          }}</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">{{
            okText
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";

export default {
  name: "v-datetime-picker",
  model: {
    prop: "datetime",
    event: "input"
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    dateFormat: {
      type: String,
      default: "MM/DD/YYYY"
    },
    timeFormat: {
      type: String,
      default: "HH:mm:ss"
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    rules: {
      type: Array
    },
    showHint: {
      type: Boolean,
      default: true
    },
    useSeconds: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      formattedDatetime: this.formatDate(this.datetime)
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    localDatetime: {
      set(localDatetime) {
        this.$emit("input", localDatetime);
      },
      get() {
        return this.datetime;
      }
    },
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        return moment(datetimeString, this.defaultDateTimeFormat);
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    }
  },
  methods: {
    init() {
      if (!this.localDatetime) {
        this.formattedDatetime = "";
        this.date = DEFAULT_DATE;
        this.time = DEFAULT_TIME;
        return;
      }

      this.formattedDatetime = this.formatDate(this.localDatetime);

      let initDateTime;
      if (this.localDatetime instanceof Date) {
        initDateTime = this.localDatetime;
      } else if (
        typeof this.localDatetime === "string" ||
        this.localDatetime instanceof String
      ) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = moment(this.localDatetime, this.defaultDateTimeFormat);
      }

      this.date = initDateTime.format(DEFAULT_DATE_FORMAT);
      this.time = initDateTime.format(DEFAULT_TIME_FORMAT);
    },
    okHandler() {
      this.resetPicker();
      this.$emit(
        "input",
        this.selectedDatetime.format(this.defaultDateTimeFormat)
      );
    },
    onTextUpdate() {
      this.localDatetime = this.parseDate(this.formattedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
    parseDate(date) {
      if (!date) return null;

      return moment(date, this.dateTimeFormat).format(
        this.defaultDateTimeFormat
      );
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date, this.defaultDateTimeFormat).format(
        this.dateTimeFormat
      );
    }
  },
  watch: {
    datetime: function() {
      this.init();
    }
  }
};
</script>
