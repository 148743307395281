import countries from "country-region-data/data";
import { getOrgs } from "../../api/orgs.api";
import { getServiceAssociateTypes } from "../../api/serviceAssociates.api";
import { sports } from "../../utils/helpers";
import { getAttestations } from "../../api/services.api";
import { getCommonLinks, getCurrentYear } from "../../api/generic.api";

const SESSION_ITEM_COMMON_LINKS = "commonLinks";
const SESSION_ITEM_CURRENT_YEAR = "currentYear";

export default {
  namespaced: true,
  state: {
    countries: countries,
    commonLinks: JSON.parse(sessionStorage.getItem(SESSION_ITEM_COMMON_LINKS)),
    currentYear: JSON.parse(sessionStorage.getItem(SESSION_ITEM_CURRENT_YEAR)),
    organizations: JSON.parse(sessionStorage.getItem("organizations")),
    attestations: JSON.parse(sessionStorage.getItem("attestations")) || [],
    serviceAssociateTypes: [],
    serviceGeographicalTypes: [
      { text: "Local", value: "local" },
      { text: "State/Province", value: "state" },
      { text: "Regional", value: "regional" },
      { text: "National", value: "national" },
      { text: "International", value: "international" }
    ],
    sports: [
      { text: "Men's Football", value: sports.MENS_FOOTBALL },
      { text: "Men's Basketball", value: sports.MENS_BASKETBALL },
      { text: "Women's Basketball", value: sports.WOMENS_BASKETBALL }
    ]
  },
  getters: {
    countryOptions(state) {
      // Map country object to label/value objects and reorder United States and Canada to the top
      return state.countries
        .map(country => {
          return {
            text: country.countryName,
            value: country.countryShortCode
          };
        })
        .reduce((acc, element) => {
          if (element.value === "US" || element.value === "CA") {
            return [element, ...acc];
          }
          return [...acc, element];
        }, []);
    },
    stateOptions: state => countryCode => {
      const country = state.countries.find(
        country => country.countryShortCode === countryCode
      );
      if (country) {
        return country.regions.map(region => {
          return { text: region.name, value: region.shortCode };
        });
      } else {
        return [];
      }
    },
    institutionOptions(state) {
      if (state.organizations && state.organizations.length > 0) {
        return state.organizations
          .filter(org => org.type_id === "2")
          .map(org => {
            return { text: org.name_official, value: org.org_id };
          });
      } else {
        return [];
      }
    },
    institutionD1Options(state) {
      if (state.organizations && state.organizations.length > 0) {
        return state.organizations
          .filter(org => org.type_id === "2" && org.division === 1)
          .map(org => {
            return { text: org.name_official, value: org.org_id };
          });
      } else {
        return [];
      }
    },
    conferenceOptions(state) {
      if (state.organizations && state.organizations.length > 0) {
        return state.organizations
          .filter(org => org.type_id === "5")
          .map(org => {
            return { text: org.name_official, value: org.org_id };
          });
      } else {
        return [];
      }
    },
    activeAttestations(state) {
      if (state.attestations && state.attestations.length > 0) {
        return state.attestations.filter(att => att.active === true);
      } else {
        return [];
      }
    },
    savedAttestations: state => savedArray => {
      if (state.attestations && state.attestations.length > 0) {
        return state.attestations.filter(att => savedArray.includes(att.id));
      } else {
        return [];
      }
    },
    serviceAssociationIdByName: state => serviceAssociationName => {
      return state.serviceAssociateTypes[serviceAssociationName];
    },
    regionLabel: state => value => {
      if (!value) return "";
      return state.serviceGeographicalTypes.find(
        region => region.value === value
      ).text;
    },
    sportLabel: state => value => {
      if (!value) return "";
      return state.sports.find(sport => sport.value === value).text;
    },
    countryLabel: (state, getters) => value => {
      if (!value) return "";
      return getters.countryOptions.find(country => country.value === value)
        .text;
    },
    stateLabel: (state, getters) => (country, state) => {
      if (!state) return "";
      return getters.stateOptions(country).find(state => state.value === state)
        .text;
    },
    linkUrlByName: state => linkName => {
      if (!linkName || !state.commonLinks) return "";
      return state.commonLinks.find(link => link.name === linkName).url;
    },
    orgLabel: state => value => {
      if (!value) return "";
      let org;
      if (
        state.organizations &&
        !!(org = state.organizations.find(org => org.org_id === value))
      )
        return org.name_official;
      else return "";
    }
  },
  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      // eslint-disable-next-line no-param-reassign
      state.organizations = organizations;
      sessionStorage.setItem("organizations", JSON.stringify(organizations));
    },
    SET_ATTESTATIONS(state, attestations) {
      state.attestations = attestations;
      sessionStorage.setItem("attestations", JSON.stringify(attestations));
    },
    SET_SERVICE_ASSOCIATE_TYPES(state, payload) {
      state.serviceAssociateTypes = payload;
      sessionStorage.setItem("serviceAssociateTypes", JSON.stringify(payload));
    },
    SET_COMMON_LINKS(state, payload) {
      state.commonLinks = payload;
      sessionStorage.setItem(
        SESSION_ITEM_COMMON_LINKS,
        JSON.stringify(payload)
      );
    },
    SET_CURRENT_YEAR(state, payload) {
      state.currentYear = payload;
      sessionStorage.setItem(
        SESSION_ITEM_CURRENT_YEAR,
        JSON.stringify(payload)
      );
    }
  },
  actions: {
    async fetchOrganizations({ state, commit }) {
      if (!state.organizations) {
        try {
          const response = await getOrgs();

          if (response.status === 200) {
            commit("SET_ORGANIZATIONS", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    async fetchAttestations({ state, commit }) {
      if (!state.attestations || state.attestations.length === 0) {
        try {
          const response = await getAttestations();

          if (response.status === 200) {
            commit("SET_ATTESTATIONS", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    async fetchServiceAssociateTypes({ state, commit }) {
      if (
        !state.serviceAssociateTypes ||
        state.serviceAssociateTypes.length === 0
      ) {
        try {
          const response = await getServiceAssociateTypes();

          if (response.status === 200) {
            commit("SET_SERVICE_ASSOCIATE_TYPES", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    async fetchCommonLinks({ state, commit }) {
      if (!state.commonLinks) {
        try {
          const response = await getCommonLinks();

          if (response.status === 200) {
            commit("SET_COMMON_LINKS", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    async fetchCurrentYear({ state, commit }) {
      if (!state.currentYear) {
        try {
          const response = await getCurrentYear();

          if (response.status === 200) {
            commit("SET_CURRENT_YEAR", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    }
  }
};
