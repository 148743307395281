<template>
  <v-input :value="associates" :rules="required ? [rules.atLeastOne] : []">
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="associates"
          :loading="loadingTable"
          :loading-text="loadingText"
          hide-default-footer
          disable-sort
          disable-pagination
          sort-by="name"
          class="elevation-1"
        >
          <template v-slot:no-data>
            <em>No records yet!</em>
          </template>
          <template v-slot:top v-if="!disabled">
            <v-toolbar flat color="white">
              <v-spacer />
              <v-dialog v-model="dialogForm" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="success" v-on="on">Add Personnel</v-btn>
                </template>
                <v-form ref="form" v-model="isFormValid" lazy-validation>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              label="Name"
                              v-model="editedItem.name"
                              :rules="[rules.required, rules.name]"
                              maxlength="100"
                              :disabled="disabled"
                            />
                          </v-col>
                          <v-col cols="12">
                            <TextAreaInput
                              label="Description"
                              v-model="editedItem.description"
                              :counter="500"
                              :disabled="disabled"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Institution"
                              v-model="editedItem.org_id"
                              :items="institutionOptions"
                              :rules="[rules.required]"
                              ref="autocomplete"
                              :disabled="disabled"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn text @click="close">Cancel</v-btn>
                      <v-btn color="primary" @click="save">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.org_id="{ item }">
            {{ institutionLabel(item.org_id) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              color="primary"
              :disabled="disabled"
            >
              $vuetify.icons.edit
            </v-icon>
            <v-icon
              small
              @click="openDeleteDialog(item)"
              color="error"
              :disabled="disabled"
            >
              $vuetify.icons.delete
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="350px">
          <v-card>
            <v-card-title class="headline">
              Delete Personnel?
            </v-card-title>

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="close">
                Cancel
              </v-btn>
              <v-btn color="error" @click="deleteItem">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div v-if="isInvalid" class="error--text">
          Please enter at least one record.
        </div>
      </v-col>
    </v-row>
  </v-input>
</template>

<script>
import { loadingTextForTable } from "../../utils/helpers";
import { required } from "../../utils/rules";
import { mapActions, mapGetters, mapState } from "vuex";
import HttpStatus from "http-status";
import TextAreaInput from "../form/TextAreaInput.vue";
import {
  createServiceAssociate,
  updateServiceAssociate,
  deleteServiceAssociate
} from "../../api/serviceAssociates.api";

export default {
  name: "Associates",
  components: {
    TextAreaInput
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name"
        },
        { text: "Institution", value: "org_id" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        service_id: 0,
        type_id: this.typeId,
        name: "",
        description: "",
        org_id: null
      },
      defaultItem: {
        type_id: this.typeId,
        name: "",
        description: "",
        org_id: null
      },
      rules: {
        atLeastOne: v => v.length > 0,
        required,
        name: v =>
          (v && v.toString().length <= 100) || "Name can only be 100 characters"
      }
    };
  },
  computed: {
    ...mapGetters({
      institutionOptions: "listOfValues/institutionOptions",
      getServiceAssociatesByTypeId: "service/getServiceAssociatesByTypeId"
    }),
    ...mapState({
      service: state => state.service.service
    }),
    associates() {
      return this.getServiceAssociatesByTypeId(this.typeId);
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Personnel" : "Edit Personnel";
    },
    isInvalid() {
      return (
        this.required && !this.loadingTable && this.associates.length === 0
      );
    }
  },
  methods: {
    ...mapActions({
      setServiceAssociates: "service/setServiceAssociates",
      addServiceAssociate: "service/addServiceAssociate",
      updateServiceAssociate: "service/updateServiceAssociate",
      removeServiceAssociate: "service/removeServiceAssociate"
    }),
    institutionLabel(orgId) {
      const org = this.institutionOptions.find(org => org.value === orgId);
      return org ? org.text : "";
    },
    editItem(item) {
      this.editedIndex = this.associates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.associates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.autocomplete.internalSearch = null;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedItem.service_id = this.service.id;
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createServiceAssociate(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addServiceAssociate(response.data);
            this.$notify.success("Personnel added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateServiceAssociate(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateServiceAssociate(response.data);
            this.$notify.success("Personnel updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteServiceAssociate(this.editedItem.id, this.service.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeServiceAssociate(this.editedIndex);
            this.$notify.success("Personnel removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    }
  }
};
</script>
