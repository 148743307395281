<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Cheat Sheet Maintenance</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="cheatsheets"
        :loading="loadingTable"
        :loading-text="loadingText"
        :search="formFilter"
        sort-by="form"
      >
        <template v-slot:no-data>
          <em>No records yet!</em>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-dialog v-model="dialogForm" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on">Add Cheat Sheet</v-btn>
              </template>
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="editedItem.form_id"
                            label="Form"
                            :items="forms"
                            :rules="[rules.required]"
                            item-value="id"
                            item-text="description"
                            :disabled="editedItem.id"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Sort Order"
                            type="number"
                            v-model="editedItem.sort_order"
                            :rules="[rules.required]"
                            max="100"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Title"
                            v-model="editedItem.title"
                            :rules="[rules.required]"
                            maxlength="100"
                          />
                        </v-col>
                        <v-col cols="12">
                          <TextAreaInput
                            label="Text"
                            :outlined="false"
                            v-model="editedItem.text"
                            :rows="1"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-select
                label="Form"
                v-model="formFilter"
                :items="forms"
                item-value="description"
                item-text="description"
                clearable
              />
            </v-col>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="props">
          <v-icon
            v-if="!disabled"
            small
            class="mr-2"
            @click="editItem(props.item)"
            color="primary"
          >
            $vuetify.icons.edit
          </v-icon>
          <v-icon small @click="openDeleteDialog(props.item)" color="error">
            $vuetify.icons.delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="350px">
        <v-card>
          <v-card-title class="headline">
            Delete Cheat Sheet?
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close">
              Cancel
            </v-btn>
            <v-btn color="error" @click="deleteItem">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { loadingTextForTable } from "../../utils/helpers";
import { required, selectOne } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import {
  createCheatsheet,
  deleteCheatsheet,
  updateCheatsheet
} from "../../api/cheatsheet.api";
import TextAreaInput from "../../components/form/TextAreaInput.vue";

export default {
  components: { TextAreaInput },
  data() {
    return {
      formFilter: "",
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      disabled: false,
      headers: [
        { text: "Form", value: "form" },
        { text: "Sort Order", value: "sort_order", filterable: false },
        { text: "Title", value: "title", filterable: false },
        { text: "Text", value: "text", filterable: false },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        title: "",
        text: "",
        sort_order: null,
        form_id: null
      },
      defaultItem: {
        title: "",
        text: "",
        sort_order: null,
        form_id: null
      },
      rules: {
        required,
        selectOne
      }
    };
  },
  computed: {
    ...mapState("admin", ["cheatsheets", "forms"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Cheat Sheet" : "Edit Cheat Sheet";
    }
  },
  methods: {
    ...mapActions("admin", [
      "fetchCheatsheets",
      "addCheatsheet",
      "updateCheatsheet",
      "removeCheatsheet",
      "fetchForms"
    ]),
    editItem(item) {
      this.editedIndex = this.cheatsheets.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.disabled = this.editedItem.system;
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.cheatsheets.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.disabled = false;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createCheatsheet(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addCheatsheet(response.data);
            this.$notify.success("Cheat Sheet added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateCheatsheet(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateCheatsheet(response.data);
            this.$notify.success("Cheat Sheet updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteCheatsheet(this.editedItem.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeCheatsheet(this.editedIndex);
            this.$notify.success("Cheat Sheet removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    formatBoolean(flag) {
      return flag ? "Yes" : "No";
    }
  },
  mounted() {
    this.loadingTable = true;
    this.fetchCheatsheets().then(cheatsheets => {
      this.loadingTable = false;
    });
    this.fetchForms();
  }
};
</script>
