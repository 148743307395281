import { getServicer, getServicers } from "../../api/servicers.api";
import { getStaffNotes } from "../../api/servicerStaffNotes.api";
import { findIndex } from "lodash";
import moment from "moment";;

export default {
  namespaced: true,
  state: {
    servicers: [],
    servicer: {
      is_international: false
    },
    staffNotes: []
  },
  getters: {
    getStaffNotesCount: state => {
      return state.staffNotes.length;
    },
    isSuspended: state => {
      let suspended = false;
      if (state.servicer.suspend_end_date) {
        const suspendDate = moment(
          state.servicer.suspend_end_date,
          "YYYY-MM-DD"
        );
        if (suspendDate.isAfter(moment())) {
          suspended = true;
        }
      }
      return suspended;
    }
  },
  mutations: {
    SET_SERVICERS(state, servicers) {
      state.servicers = servicers;
    },
    SET_SERVICER(state, servicer) {
      state.servicer = servicer;
    },
    UPDATE_SERVICER: (state, { index, payload }) => {
      state.servicers.splice(index, 1, payload);
    },
    REMOVE_SERVICER: (state, index) => state.servicers.splice(index, 1),
    SET_STAFF_NOTES(state, staffNotes) {
      state.staffNotes = staffNotes;
    },
    ADD_STAFF_NOTE: (state, payload) => {
      state.staffNotes.unshift(payload);
    },
    UPDATE_STAFF_NOTE: (state, { index, payload }) => {
      state.staffNotes.splice(index, 1, payload);
    },
    REMOVE_STAFF_NOTE: (state, index) => {
      state.staffNotes.splice(index, 1);
    }
  },
  actions: {
    async fetchServicers({ commit }) {
      try {
        const response = await getServicers();

        if (response.status === 200) {
          commit("SET_SERVICERS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    async fetchServicer({ commit }, id) {
      try {
        const response = await getServicer(id);

        if (response.status === 200) {
          commit("SET_SERVICER", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    updateServicer({ state, commit }, payload) {
      const index = findIndex(
        state.servicers,
        servicer => servicer.id === payload.id
      );

      commit("UPDATE_SERVICER", { index, payload });
    },
    removeServicer({ commit }, servicerIndex) {
      commit("REMOVE_SERVICER", servicerIndex);
    },
    async fetchStaffNotes({ commit }, servicerId) {
      try {
        const response = await getStaffNotes(servicerId);

        if (response.status === 200) {
          commit("SET_STAFF_NOTES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addNewStaffNote({ commit }, payload) {
      commit("ADD_STAFF_NOTE", payload);
    },
    updateStaffNote({ state, commit }, payload) {
      const index = findIndex(state.staffNotes, note => note.id === payload.id);

      if (index >= 0) {
        commit("UPDATE_STAFF_NOTE", { index, payload });
      } else {
        const newIndex = findIndex(state.staffNotes, note => !note.id);
        commit("UPDATE_STAFF_NOTE", { newIndex, payload });
      }
    },
    removeStaffNote({ state, commit }, id) {
      const index = findIndex(state.staffNotes, note => note.id === id);

      commit("REMOVE_STAFF_NOTE", index);
    },
    removeNewStaffNote({ state, commit }) {
      const newIndex = findIndex(state.staffNotes, note => !note.id);
      commit("REMOVE_STAFF_NOTE", newIndex);
    }
  }
};
