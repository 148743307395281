<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          label="Country"
          v-model="value.country"
          :items="countryOptions"
          :disabled="disabled"
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Street Address"
          v-model="value.street1"
          :disabled="disabled"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Street Address 2"
          v-model="value.street2"
          hint="optional"
          persistent-hint
          :disabled="disabled"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          label="City"
          v-model="value.city"
          :disabled="disabled"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          label="State/Province"
          v-model="value.state"
          :items="stateOptions"
          :disabled="disabled"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          label="Postal Code"
          v-model="value.postal_code"
          :disabled="disabled"
          :rules="[postalCodeRequiredRule, postalCodeLengthRule]"
          :maxlength="value.country === 'US' ? 5 : 15"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "../utils/rules";
const postalRegex = RegExp(/\d{5}$/);
export default {
  data() {
    return {
      rules: {
        required
      }
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    "value.country"(newValue, oldValue) {
      if (
        newValue === "US" &&
        this.value.postal_code &&
        this.value.postal_code.length > 5
      ) {
        this.value.postal_code = this.value.postal_code.substring(0, 5);
      }
    }
  },
  computed: {
    ...mapGetters("listOfValues", ["countryOptions"]),
    stateOptions() {
      return this.$store.getters["listOfValues/stateOptions"](
        this.value.country
      );
    },
    postalCodeLengthRule() {
      if (this.value.country !== "US") {
        return () =>
          this.value.postal_code.length <= 15 ||
          "Postal Code may not exceed 15 characters.";
      } else {
        return () =>
          postalRegex.test(this.value.postal_code) ||
          "Postal Code must be 5 digits.";
      }
    },
    postalCodeRequiredRule(v) {
      if (this.value.country === "US" || this.value.country === "CA") {
        return () =>
          (this.value.postal_code &&
            this.value.postal_code.trim().length > 0) ||
          "Required";
      } else {
        return () => true;
      }
    }
  }
};
</script>
