import httpClient from "./httpClient";

const END_POINT = "/services";
const CONVERSATIONS_END_POINT = "conversations";

const getServiceConversations = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${CONVERSATIONS_END_POINT}`);

const createServiceConversation = (serviceId, data) => {
  return httpClient.post(
    `${END_POINT}/${serviceId}/${CONVERSATIONS_END_POINT}`,
    prepareFormData(data)
  );
};

const updateServiceConversation = (serviceId, data) => {
  const { id } = data; // remove id from form object

  return httpClient.put(
    `${END_POINT}/${serviceId}/${CONVERSATIONS_END_POINT}/${id}`,
    prepareFormData(data)
  );
};

const deleteServiceConversation = (id, serviceId) =>
  httpClient.delete(
    `${END_POINT}/${serviceId}/${CONVERSATIONS_END_POINT}/${id}`
  );

const deleteServiceConversationFile = (serviceId, conversationId, fileId) => {
  return httpClient.delete(
    `${END_POINT}/${serviceId}/${CONVERSATIONS_END_POINT}/${conversationId}?attachment_id=${fileId}`
  );
};

export {
  getServiceConversations,
  createServiceConversation,
  updateServiceConversation,
  deleteServiceConversation,
  deleteServiceConversationFile
};

function prepareFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    const keyName = `service_conversation[${key}]`;
    if (data[key] !== null && key === "conversation_files") {
      for (let i = 0; i < data[key].length; i++) {
        const file = data[key][i];

        formData.append(keyName + "[]", file);
      }
    } else if (data[key] !== null && key !== "conversation_files_uploaded") {
      formData.append(keyName, data[key]);
    } else if (data[key] === null) {
      formData.append(keyName, "");
    }
  }

  return formData;
}
