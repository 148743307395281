<template>
  <v-data-table
    :headers="headers"
    :items="accounts"
    hide-default-footer
    disable-sort
    disable-pagination
    class="social-media-table"
  >
    <template v-slot:top>
      <v-toolbar flat color="white" class="social-media-toolbar">
        <v-label>Recruiting/Scouting Service Social Media Accounts</v-label>
        <Tooltip
          class="d-print-none"
          text="Not personal unless they are one and the same."
        />
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-if="!disabled" v-slot:activator="{ on }">
            <v-btn color="success" v-on="on">Add Account</v-btn>
          </template>
          <v-form ref="smForm" v-model="isFormValid" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        label="Platform"
                        :items="platforms"
                        v-model="editedItem.platform"
                        :rules="[rules.required]"
                        :disabled="disabled"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <OtherInput
                        v-if="platformOtherSelected(editedItem)"
                        v-model="editedItem.platform_other"
                        label="Please specify other platform"
                        maxlength="50"
                        :disabled="disabled"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Account URL"
                        v-model="editedItem.account"
                        :rules="[rules.required]"
                        maxlength="255"
                        :disabled="disabled"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      <em>No accounts yet!</em>
    </template>
    <template v-slot:item="props">
      <tr v-if="!props.item._destroy">
        <td>{{ platformLabel(props.item) }}</td>
        <td>{{ props.item.account }}</td>
        <td class="text-end">
          <v-icon
            v-if="!disabled"
            small
            class="mr-2"
            @click="editItem(props.item)"
            color="primary"
          >
            $vuetify.icons.edit
          </v-icon>
          <v-icon
            v-if="!disabled"
            small
            @click="deleteItem(props.item)"
            color="error"
          >
            $vuetify.icons.delete
          </v-icon>
        </td>
      </tr>
      <tr v-else>
        <td colspan="3" class="text-center">
          <em
            >Row has been deleted, but form must still be saved.
            <a href="#" @click.prevent="undeleteItem(props.item)">Undo</a></em
          >
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import OtherInput from "../form/OtherInput.vue";
import Tooltip from "../form/Tooltip.vue";
export default {
  components: { Tooltip, OtherInput },
  props: {
    accounts: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Platform",
          align: "start",
          sortable: false,
          value: "platform"
        },
        { text: "Account URL", value: "account", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        platform: "",
        account: "",
        _destroy: false
      },
      defaultItem: {
        platform: "",
        account: "",
        _destroy: false
      },
      isFormValid: true,
      rules: {
        required: v => !!v || "Required"
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add Social Media Account"
        : "Edit Social Media Account";
    },
    platforms() {
      return [
        { text: "Twitter", value: "twitter" },
        { text: "Facebook", value: "facebook" },
        { text: "Instagram", value: "instagram" },
        { text: "YouTube", value: "youtube" },
        { text: "Snapchat", value: "snapchat" },
        { text: "TikTok", value: "tiktok" },
        { text: "Other", value: "other" }
      ];
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    platformLabel(item) {
      const found = this.platforms.find(
        platform => platform.value === item.platform
      );

      return found
        ? this.platformOtherSelected(item)
          ? item.platform_other
          : found.text
        : "";
    },
    platformOtherSelected(item) {
      return item.platform === "other";
    },
    editItem(item) {
      this.editedIndex = this.accounts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.accounts.indexOf(item);
      const response = confirm("Are you sure you want to delete this item?");

      if (response) {
        if (item.id) {
          this.accounts.splice(index, 1, { ...item, _destroy: true });
        } else {
          this.accounts.splice(index, 1);
        }
      }
    },
    undeleteItem(item) {
      const index = this.accounts.indexOf(item);
      this.accounts.splice(index, 1, { ...item, _destroy: false });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.smForm.resetValidation();
      }, 300);
    },

    save() {
      if (this.$refs.smForm.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.accounts[this.editedIndex], this.editedItem);
        } else {
          this.accounts.push(this.editedItem);
        }
        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.social-media-table td,
.social-media-table th {
  padding: 0 0 !important;
}

.social-media-toolbar .v-toolbar__content,
.social-media-toolbar .v-toolbar__extension {
  padding: 4px 0;
}
</style>
