"use strict";

import axios from "axios";
import router from "../router/router";
import store from "../store/store";
import HttpStatus from "http-status";
import Vue from "vue";

// Full config:  https://github.com/axios/axios#request-config
const config = {
  baseURL: process.env.apiUrl || "",
  timeout: 120 * 1000, // Timeout
  headers: { Accept: "application/json" }
};

const httpClient = axios.create(config);

const csrfInterceptor = config => {
  const csrfToken = document.querySelector("meta[name='csrf-token']").content;
  const csrfHeader = document.querySelector("meta[name='csrf-param']").content;

  config.headers.common[csrfHeader] = csrfToken;

  return config;
};

const errorInterceptor = error => {
  if (error.response) {
    const response = error.response;
    switch (response.status) {
      case HttpStatus.FORBIDDEN:
        if (response.data.is_staff) {
          store.commit("user/CLEAR_USER");
          router
            .push({
              name: "forbidden",
              params: { message: response.data.error_message }
            })
            .catch(err => console.error("error occurred from router: " + err));
        } else if (response.data.is_servicer) {
          store.commit("user/SET_USER", response.data);
          router
            .push({
              name: "profile",
              params: { message: response.data.error_message }
            })
            .catch(err => console.error("error occurred from router: " + err));
        } else if (response.data.message) {
          router
            .push({
              name: "forbidden",
              params: { message: response.data.message }
            })
            .catch(err => console.error("error occurred from router: " + err));
        }
        break;
      case HttpStatus.UNAUTHORIZED:
        store.commit("user/CLEAR_USER");
        if (router.currentRoute.meta.requiresAuth) {
          router
            .push({ name: "home" })
            .catch(err => console.error("error: " + err));
        }
        break;
      case HttpStatus.INTERNAL_SERVER_ERROR:
        Vue.prototype.$notify.error("An unexpected server error has occurred.");
        break;
      default:
        Vue.prototype.$notify.error(error.response.data.message);
        console.error(error.response.status, error.message);
    }
  }

  return Promise.reject(error);
};

const responseInterceptor = response => {
  switch (response.status) {
    case HttpStatus.OK:
      // If we need to do anything for good responses add here
      break;
    default:
  }

  return response;
};

httpClient.interceptors.request.use(csrfInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
