import Vue from "vue";
import App from "../App.vue";
import router from "../router/router";
import store from "../store/store";
import NProgress from "nprogress";
import LoadingPlugin from "../plugins/loading";
import NotifyPlugin from "../notifications/index";
import Vuelidate from "vuelidate";
import vuetify from "../plugins/vuetify";
import VueTheMask from "ke-the-mask";
import { VTextField } from "vuetify/lib"; // Globally import VTextField
import VCurrencyField from "v-currency-field";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import VueGtag from "vue-gtag";
import VuePapaParse from "vue-papa-parse";
import * as ActiveStorage from "@rails/activestorage";

ActiveStorage.start();

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

Vue.use(NotifyPlugin, {
  x: "center",
  y: "top",
  property: "$notify",
  $vuetify: vuetify.framework
});

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "en-US",
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: null
});

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "fa"
});

Vue.use(LoadingPlugin);
Vue.use(Vuelidate);
Vue.use(VuePapaParse);
Vue.use(VueTheMask);
Vue.use(
  VueGtag,
  {
    config: { id: "UA-62522476-2" }
  },
  router
);

NProgress.configure({ showSpinner: false });

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
});
