import {
  getPurgeableServices,
  getPurgeableServicers,
  getYears
} from "../../api/years.api";
import { filter, findIndex } from "lodash";
import { getStatuses } from "../../api/status.api";
import { getCheatsheets, getForms } from "../../api/cheatsheet.api";
import { getAttestations } from "../../api/attestations.api";
import { getStatusEmails } from "../../api/statusEmails.api";
import { status as statuses } from "../../utils/helpers";

export default {
  namespaced: true,
  state: {
    years: [],
    statuses: [],
    cheatsheets: JSON.parse(sessionStorage.getItem("cheatsheets")) || [],
    forms: JSON.parse(sessionStorage.getItem("forms")),
    attestations: [],
    statusEmails: [],
    purgeable_services: [],
    purgeable_servicers: []
  },
  getters: {
    getActiveStatuses: state => {
      return filter(
        state.statuses,
        status =>
          status.active &&
          status.id !== statuses.CHANGE_REQUEST &&
          status.id !== statuses.CHANGE_REQUEST_APPROVED
      );
    },
    formCheatsheets: state => formId => {
      return state.cheatsheets.filter(
        cheatsheet => cheatsheet.form_id === formId
      );
    }
  },
  mutations: {
    SET_YEARS(state, years) {
      state.years = years;
    },
    ADD_YEAR: (state, payload) => state.years.push(payload),
    REMOVE_YEAR: (state, id) => {
      const index = findIndex(state.years, year => year.id === id);
      state.years.splice(index, 1);
    },
    UPDATE_YEAR: (state, payload) => {
      const index = findIndex(state.years, year => year.id === payload.id);
      Object.assign(state.years[index], payload);
    },
    SET_STATUSES(state, statuses) {
      state.statuses = statuses;
    },
    ADD_STATUS: (state, payload) => state.statuses.push(payload),
    REMOVE_STATUS: (state, index) => state.statuses.splice(index, 1),
    UPDATE_STATUS: (state, payload) => {
      const index = findIndex(
        state.statuses,
        status => status.id === payload.id
      );
      Object.assign(state.statuses[index], payload);
    },
    SET_CHEATSHEETS(state, cheatsheets) {
      state.cheatsheets = cheatsheets;
      sessionStorage.setItem("cheatsheets", JSON.stringify(cheatsheets));
    },
    ADD_CHEATSHEET: (state, payload) => {
      state.cheatsheets.push(payload);
      sessionStorage.setItem("cheatsheets", JSON.stringify(state.cheatsheets));
    },
    REMOVE_CHEATSHEET: (state, index) => {
      state.cheatsheets.splice(index, 1);
      sessionStorage.setItem("cheatsheets", JSON.stringify(state.cheatsheets));
    },
    UPDATE_CHEATSHEET: (state, payload) => {
      const index = findIndex(
        state.cheatsheets,
        cheatsheet => cheatsheet.id === payload.id
      );
      Object.assign(state.cheatsheets[index], payload);
      sessionStorage.setItem("cheatsheets", JSON.stringify(state.cheatsheets));
    },
    SET_FORMS(state, forms) {
      // eslint-disable-next-line no-param-reassign
      state.forms = forms;
      sessionStorage.setItem("forms", JSON.stringify(forms));
    },
    SET_ATTESTATIONS(state, attestations) {
      state.attestations = attestations;
    },
    ADD_ATTESTATION: (state, payload) => {
      state.attestations.push(payload);
    },
    REMOVE_ATTESTATION: (state, index) => {
      state.attestations.splice(index, 1);
    },
    UPDATE_ATTESTATION: (state, payload) => {
      const index = findIndex(
        state.attestations,
        attestation => attestation.id === payload.id
      );
      Object.assign(state.attestations[index], payload);
    },
    SET_STATUS_EMAILS(state, statusEmails) {
      state.statusEmails = statusEmails;
    },
    ADD_STATUS_EMAIL: (state, payload) => {
      state.statusEmails.push(payload);
    },
    REMOVE_STATUS_EMAIL: (state, index) => {
      state.statusEmails.splice(index, 1);
    },
    UPDATE_STATUS_EMAIL: (state, payload) => {
      const index = findIndex(
        state.statusEmails,
        statusEmail => statusEmail.id === payload.id
      );
      Object.assign(state.statusEmails[index], payload);
    },
    SET_PURGEABLE_SERVICES(state, payload) {
      state.purgeable_services = payload;
    },
    SET_PURGEABLE_SERVICERS(state, payload) {
      state.purgeable_servicers = payload;
    }
  },
  actions: {
    async fetchYears({ state, commit }) {
      try {
        const response = await getYears();

        if (response.status === 200) {
          commit("SET_YEARS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addYear({ commit }, year) {
      commit("ADD_YEAR", year);
    },
    updateYear({ commit }, year) {
      commit("UPDATE_YEAR", year);
    },
    removeYear({ commit }, yearIndex) {
      commit("REMOVE_YEAR", yearIndex);
    },
    async fetchStatuses({ state, commit }) {
      try {
        const response = await getStatuses();

        if (response.status === 200) {
          commit("SET_STATUSES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addStatus({ commit }, status) {
      commit("ADD_STATUS", status);
    },
    updateStatus({ commit }, status) {
      commit("UPDATE_STATUS", status);
    },
    removeStatus({ commit }, statusIndex) {
      commit("REMOVE_STATUS", statusIndex);
    },
    async fetchCheatsheets({ state, commit }) {
      if (!state.cheatsheets || state.cheatsheets.length === 0) {
        try {
          const response = await getCheatsheets();

          if (response.status === 200) {
            commit("SET_CHEATSHEETS", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    addCheatsheet({ commit }, cheatsheet) {
      commit("ADD_CHEATSHEET", cheatsheet);
    },
    updateCheatsheet({ commit }, cheatsheet) {
      commit("UPDATE_CHEATSHEET", cheatsheet);
    },
    removeCheatsheet({ commit }, cheatsheetIndex) {
      commit("REMOVE_CHEATSHEET", cheatsheetIndex);
    },
    async fetchAttestations({ state, commit }) {
      try {
        const response = await getAttestations();

        if (response.status === 200) {
          commit("SET_ATTESTATIONS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addAttestation({ commit }, attestation) {
      commit("ADD_ATTESTATION", attestation);
    },
    updateAttestation({ commit }, attestation) {
      commit("UPDATE_ATTESTATION", attestation);
    },
    removeAttestation({ commit }, attestationIndex) {
      commit("REMOVE_ATTESTATION", attestationIndex);
    },
    async fetchForms({ state, commit }) {
      if (!state.forms) {
        try {
          const response = await getForms();

          if (response.status === 200) {
            commit("SET_FORMS", response.data);
            return response.data;
          }
        } catch (err) {
          // swallow error because axios interceptor is already handling it
        }
      }
    },
    async fetchStatusEmails({ state, commit }) {
      try {
        const response = await getStatusEmails();

        if (response.status === 200) {
          commit("SET_STATUS_EMAILS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    addStatusEmail({ commit }, statusEmail) {
      commit("ADD_STATUS_EMAIL", statusEmail);
    },
    updateStatusEmail({ commit }, statusEmail) {
      commit("UPDATE_STATUS_EMAIL", statusEmail);
    },
    removeStatusEmail({ commit }, statusEmailIndex) {
      commit("REMOVE_STATUS_EMAIL", statusEmailIndex);
    },
    async fetchPurgeableServices({ commit }, yearId) {
      try {
        const response = await getPurgeableServices(yearId);

        if (response.status === 200) {
          commit("SET_PURGEABLE_SERVICES", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    },
    async fetchPurgeableServicers({ commit }, yearId) {
      try {
        const response = await getPurgeableServicers(yearId);

        if (response.status === 200) {
          commit("SET_PURGEABLE_SERVICERS", response.data);
          return response.data;
        }
      } catch (err) {
        // swallow error because axios interceptor is already handling it
      }
    }
  }
};
