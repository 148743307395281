<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="approvedList"
      :loading="loading"
      :loading-text="loadingText"
      :search="search"
      :custom-filter="filterTable"
      sort-by="name"
    >
      <template v-slot:no-data>
        <em>No services have been approved!</em>
      </template>
      <template v-slot:top>
        <v-row dense>
          <v-col cols="12" sm="6">
            <h3>Approvals valid until {{ formattedDate }} EDT</h3>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <v-select
              v-model="filter.sport"
              :items="sports"
              label="Sport"
              hide-details
              clearable
          /></v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              label="Country"
              v-model="filter.country"
              :items="countryOptions"
              hide-details
              clearable
          /></v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              label="State"
              v-model="filter.state"
              :items="stateOptions"
              hide-details
              clearable
          /></v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              label="Search"
              hide-details
              append-icon="fa-search"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.mbb="{ item }">
        <p
          class="mt-0 mb-0"
          v-if="item.mbb"
          v-text="sportLabel(sportsConst.MENS_BASKETBALL)"
        ></p>
        <p
          class="mt-0 mb-0"
          v-if="item.wbb"
          v-text="sportLabel(sportsConst.WOMENS_BASKETBALL)"
        ></p>
        <p
          class="mt-0 mb-0"
          v-if="item.mfb"
          v-text="sportLabel(sportsConst.MENS_FOOTBALL)"
        ></p>
      </template>
      <template v-slot:item.standard_fee="{ item }">
        <div v-if="!item.coach_fee">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a href="javascript:;" v-bind="attrs" v-on="on">
                $ 0
              </a>
            </template>
            <span>Free for NCAA coaches.</span>
          </v-tooltip>
        </div>
        <div v-else-if="item.diff_fee_per_region">
          <div
            v-for="(file, index) in item.price_sheets"
            :key="'price_' + index"
          >
            <a :href="file.file_url">{{ file.file_name }}</a>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(fee, index) in item.price_packages"
            :key="'fee_' + index"
          >
            <a href="#" @click.prevent="openFeeDialog(fee)">{{
              formatCurrency(fee.amount)
            }}</a>
          </div>
        </div>
      </template>
      <template v-slot:item.geo_scope="{ item }">
        <a
          v-if="hasStatesCountries(item)"
          href="#"
          @click.prevent="openRegionDialog(item)"
          >{{ regionLabel(item.geo_scope) }}</a
        >
        <div v-else>
          {{ regionLabel(item.geo_scope) }}
        </div>
      </template>
      <template v-slot:item.scouts="{ item }">
        <v-menu offset-y v-if="item.scouts.length > 0" max-height="250">
          <template v-slot:activator="{ on, attrs }">
            <a
              style="white-space: nowrap"
              href="javascript:;"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.scouts.length }} scout{{
                item.scouts.length > 1 ? "s" : ""
              }}
            </a>
          </template>
          <v-list>
            <v-list-item v-for="(scout, index) in item.scouts" :key="index">
              <v-list-item-title
                ><v-chip class="mt-2" :key="scout.id">{{
                  scout.name
                }}</v-chip></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <span v-else> --</span>
      </template>
      <template v-slot:item.sample_links="{ item }">
        <div
          v-if="item.sample_links"
          v-html="sampleLinksDisplay(item.sample_links)"
        ></div>
      </template>
    </v-data-table>
    <FeeDialog v-model="feeDialog" :item="selectedFee" />
    <RegionDialog v-model="regionDialog" :item="selectedRegion" />
  </div>
</template>

<script>
import {
  formatDateTime,
  loadingTextForTable,
  sports,
  formatCurrency
} from "../utils/helpers";
import { mapGetters, mapState } from "vuex";
import RegionDialog from "./RegionDialog.vue";
import FeeDialog from "./FeeDialog.vue";

export default {
  components: { RegionDialog, FeeDialog },
  props: {
    year: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingText: loadingTextForTable,
      search: "",
      sportsConst: sports,
      feeDialog: false,
      regionDialog: false,
      selectedFee: {
        name: "",
        description: "",
        amount: null
      },
      selectedRegion: {
        geo_scope: "",
        geo_countries: [],
        geo_states: []
      },
      filter: {
        sport: "",
        country: "",
        state: ""
      },
      headers: [
        {
          text: "Year",
          align: "start",
          value: "year"
        },
        { text: "Service Name", value: "name" },
        {
          text: "Operator Name",
          value: "servicer_name"
        },
        { text: "Sport", value: "mbb" },
        { text: "Region", value: "geo_scope" },
        { text: "Fee", value: "standard_fee", sortable: false },
        {
          text: "Scouts",
          value: "scouts",
          sortable: false
        },
        { text: "Samples", value: "sample_links", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      geoScopes: state => state.listOfValues.serviceGeographicalTypes,
      sports: state => state.listOfValues.sports
    }),
    ...mapGetters("listOfValues", ["countryOptions"]),
    approvedList() {
      let approvedList = this.$store.getters[
        "service/getApprovedServicesByYear"
      ](this.year.id);

      if (this.isFiltering(this.filter.sport)) {
        approvedList = approvedList.filter(x => {
          if (this.filter.sport === sports.MENS_FOOTBALL) return x.mfb;
          if (this.filter.sport === sports.MENS_BASKETBALL) return x.mbb;
          if (this.filter.sport === sports.WOMENS_BASKETBALL) return x.wbb;
        });
      }

      if (this.isFiltering(this.filter.country)) {
        approvedList = approvedList.filter(x => {
          return x.geo_countries.includes(this.filter.country);
        });
      }

      if (this.isFiltering(this.filter.state)) {
        approvedList = approvedList.filter(x => {
          return x.geo_states.includes(this.filter.state);
        });
      }

      return approvedList;
    },
    formattedDate() {
      return formatDateTime(this.year.end_date);
    },
    stateOptions() {
      return this.$store.getters["listOfValues/stateOptions"](
        this.filter.country
      );
    }
  },
  methods: {
    regionLabel(value) {
      return this.$store.getters["listOfValues/regionLabel"](value);
    },
    sportLabel(value) {
      return this.$store.getters["listOfValues/sportLabel"](value);
    },
    formatCurrency(value) {
      return formatCurrency(value);
    },
    openFeeDialog(item) {
      this.selectedFee = { ...item };
      this.feeDialog = true;
    },
    openRegionDialog(item) {
      this.selectedRegion = { ...item };
      this.regionDialog = true;
    },
    isFiltering(myData) {
      return myData !== undefined && myData !== null && myData.length > 0;
    },
    hasStatesCountries(item) {
      return (
        (item.geo_countries && item.geo_countries.length > 0) ||
        (item.geo_states && item.geo_states.length > 0)
      );
    },
    sampleLinksDisplay(sampleLinks) {
      let html = "";
      const mbbLinks = sampleLinks.filter(
        link => link.sport_code === sports.MENS_BASKETBALL
      );
      const wbbLinks = sampleLinks.filter(
        link => link.sport_code === sports.WOMENS_BASKETBALL
      );
      const mfbLinks = sampleLinks.filter(
        link => link.sport_code === sports.MENS_FOOTBALL
      );

      html += this.buildSportUrls(mbbLinks, sports.MENS_BASKETBALL);
      if (html.length > 0 && wbbLinks.length > 0) {
        html += `<br/>`;
      }
      html += this.buildSportUrls(wbbLinks, sports.WOMENS_BASKETBALL);
      if (html.length > 0 && mfbLinks.length > 0) {
        html += `<br/>`;
      }
      html += this.buildSportUrls(mfbLinks, sports.MENS_FOOTBALL);

      return html;
    },
    buildSportUrls(links, sportCode) {
      let html = "";
      if (links.length > 0) {
        html += `${this.sportLabel(sportCode)}: `;
        for (let i = 0; i < links.length; i++) {
          if (i > 0) {
            html += ", ";
          }
          if (links[i].sample_file) {
            html += `<a href="${links[i].sample_file.file_url}">${links[i].sample_file.file_name}</a>`;
          } else {
            html += `<a href="http://${links[i].url}" target="_blank">${links[i].description}</a>`;
          }
        }
      }
      return html;
    },
    filterTable(value, search, item) {
      if (!search) return true;

      if (
        value != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLowerCase()
          .includes(search.toString().toLowerCase())
      ) {
        return true;
      }

      return (
        value != null &&
        Array.isArray(value) &&
        value.some(
          scout =>
            scout.name &&
            scout.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }
};
</script>

<style scoped></style>
