<template>
  <v-input :value="sampleLinks" :rules="required ? [rules.atLeastOne] : []">
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="sampleLinks"
          :loading="loadingTable"
          :loading-text="loadingText"
          hide-default-footer
          disable-sort
          disable-pagination
          class="elevation-0"
        >
          <template v-slot:no-data>
            <em>No records yet!</em>
          </template>
          <template v-slot:item.url="{ item }">
            <a :href="'http://' + item.url" target="_blank">{{ item.url }}</a>
          </template>
          <template v-slot:item.sport_code="{ item }">
            {{ sportLabel(item.sport_code) }}
          </template>
          <template v-slot:top v-if="!disabled">
            <v-toolbar flat color="white">
              <v-spacer />
              <v-dialog v-model="dialogForm" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="success" v-on="on">Add Link</v-btn>
                </template>
                <v-form ref="form" v-model="isFormValid" lazy-validation>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.sport_code"
                              :items="sports"
                              label="Sport"
                              :rules="[rules.required]"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="URL"
                              v-model="editedItem.url"
                              :rules="[rules.required]"
                              maxlength="255"
                              :disabled="disabled"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="Description"
                              v-model="editedItem.description"
                              :rules="[rules.required]"
                              maxlength="255"
                              :disabled="disabled"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn text @click="close">Cancel</v-btn>
                      <v-btn color="primary" @click="save">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              color="primary"
              :disabled="disabled"
            >
              $vuetify.icons.edit
            </v-icon>
            <v-icon
              small
              @click="openDeleteDialog(item)"
              color="error"
              :disabled="disabled"
            >
              $vuetify.icons.delete
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="350px">
          <v-card>
            <v-card-title class="headline">
              Delete Link?
            </v-card-title>

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="close">
                Cancel
              </v-btn>
              <v-btn color="error" @click="deleteItem">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div v-if="isInvalid" class="error--text">
          Please enter at least one record.
        </div>
      </v-col>
    </v-row>
  </v-input>
</template>

<script>
import { loadingTextForTable } from "../../utils/helpers";
import { required } from "../../utils/rules";
import { mapActions, mapState } from "vuex";
import HttpStatus from "http-status";
import {
  createSampleLink,
  updateSampleLink,
  deleteSampleLink
} from "../../api/serviceSampleLinks.api";

export default {
  name: "SampleLinks",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: true,
      dialogForm: false,
      dialogDelete: false,
      loadingTable: false,
      loadingText: loadingTextForTable,
      headers: [
        {
          text: "Sport",
          value: "sport_code"
        },
        {
          text: "URL",
          align: "start",
          value: "url"
        },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        service_id: 0,
        sport_code: "",
        url: "",
        description: ""
      },
      defaultItem: {
        sport_code: "",
        url: "",
        description: ""
      },
      rules: {
        atLeastOne: v => v.length > 0,
        required
      }
    };
  },
  computed: {
    ...mapState({
      service: state => state.service.service,
      sports: state => state.listOfValues.sports
    }),
    sampleLinks() {
      return this.service.service_sample_links;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Link" : "Edit Link";
    },
    isInvalid() {
      return (
        this.required && !this.loadingTable && this.sampleLinks.length === 0
      );
    }
  },
  methods: {
    ...mapActions({
      setServiceSampleLinks: "service/setServiceSampleLinks",
      addServiceSampleLink: "service/addServiceSampleLink",
      updateServiceSampleLink: "service/updateServiceSampleLink",
      removeServiceSampleLink: "service/removeServiceSampleLink"
    }),
    sportLabel(value) {
      return this.$store.getters["listOfValues/sportLabel"](value);
    },
    editItem(item) {
      this.editedIndex = this.sampleLinks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },
    openDeleteDialog(item) {
      this.editedIndex = this.sampleLinks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialogForm = false;
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$loading();
        this.editedItem.service_id = this.service.id;
        this.editedIndex === -1 ? this.createItem() : this.updateItem();
      }
    },
    createItem() {
      this.$loading();
      this.dialogForm = false;
      return createSampleLink(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.addServiceSampleLink(response.data);
            this.$notify.success("Link added successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    updateItem() {
      this.$loading();
      this.dialogForm = false;
      return updateSampleLink(this.editedItem)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.updateServiceSampleLink(response.data);
            this.$notify.success("Link updated successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    deleteItem() {
      this.$loading();
      this.dialogDelete = false;
      return deleteSampleLink(this.editedItem.id, this.service.id)
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            // Update vuex store
            this.removeServiceSampleLink(this.editedIndex);
            this.$notify.success("Link removed successfully");
            this.close();
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    }
  }
};
</script>

<style lang="scss">
.crud-table td,
.crud-table th {
  padding: 0 0 !important;
}

.crud-toolbar .v-toolbar__content,
.crud-toolbar .v-toolbar__extension {
  padding: 4px 0;
}
</style>
