<template>
  <ApprovedList />
</template>

<script>
import ApprovedList from "../components/ApprovedList.vue";

export default {
  components: { ApprovedList }
};
</script>

<style scoped></style>
