<template>
  <v-footer class="d-print-none container" style="margin-top: 0">
    <v-row no-gutters>
      <v-btn
        small
        text
        dark
        :href="linkUrlByName(linkNames.EMAIL_CONTACT_FORM)"
        target="_blank"
      >
        <v-icon small left>fa-envelope</v-icon> Contact Us
      </v-btn>
      <v-btn small text dark>
        <v-icon small left color="white">fa-phone</v-icon> 1-844-562-6201
        (option 3)
      </v-btn>
      <v-btn
        small
        text
        :href="linkUrlByName(linkNames.SCOUTING_SERVICES_APPROVAL)"
        target="_blank"
      >
        <v-icon small left color="white">fa-globe</v-icon> Web
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small text :href="termsUrl" target="_blank">
        Terms and Conditions
      </v-btn>
      <v-btn small text :href="privacyPolicyUrl" target="_blank">
        Our Privacy Policy
      </v-btn>
      <v-col class="pb-2 text-center" cols="12">
        &copy; {{ copyrightDate }} NCAA
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import { linkNames } from "../../utils/helpers";

export default {
  name: "Footer",
  data() {
    return {
      linkNames: linkNames,
      privacyPolicyUrl: "http://web1.ncaa.org/web_files/privacy.html",
      termsUrl: "http://web1.ncaa.org/web_files/terms_and_conditions.html"
    };
  },
  computed: {
    ...mapGetters("listOfValues", ["linkUrlByName"]),
    copyrightDate() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style scoped>
.v-footer {
  background-color: #999 !important;
}

footer,
footer a {
  text-shadow: none;
}
footer,
footer a:hover {
  color: white !important;
  text-decoration: none;
}
</style>
