<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle">Format</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-label
              >How does your service provide information about prospects?
            </v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3">
            <v-select
              label="Please select"
              v-model="service_format.info_to_prospects_type"
              :items="infoToProspectValues"
              :rules="[rules.required]"
              :disabled="disabled"
            />
          </v-col>
          <v-col>
            <OtherInput
              v-model="service_format.info_to_prospects_other"
              v-if="service_format.info_to_prospects_type === 'other'"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Are college coaches required to register online and/or access the
              service with a username and password?
            </v-label>
          </v-col>
          <v-col>
            <YesNoButtonGroup
              v-model="service_format.registration_required"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row v-if="service_format.registration_required" :class="indent">
          <v-col
            cols="12"
            class="d-print-none"
            :class="{ 'd-none': isPrintPage }"
          >
            <v-alert type="warning"
              >You will need to create an account for the NCAA that allows
              access to all areas of the service.
            </v-alert>
          </v-col>
          <v-col>
            <v-text-field
              label="Email/Username"
              v-model="service_format.registration_username"
              maxlength="100"
              :rules="[rules.required]"
              :disabled="disabled"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Password"
              v-model="service_format.registration_password"
              maxlength="100"
              :rules="[rules.required]"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Does your service provide analysis (academic information,
              athletic evaluations, GPA, vertical leap, wingspan, 40-yard dash,
              etc.) for each athlete identified in your service?
            </v-label>
          </v-col>
          <v-col>
            <YesNoButtonGroup
              v-model="service_format.analysis_provided"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row
          :class="indent"
          v-if="service_format.analysis_provided === false"
        >
          <v-col cols="12">
            <v-alert type="warning"
              >NCAA legislation requires that the service provides individual
              analysis beyond demographic information or rankings for each
              prospect in the information provided to college coaches.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-label>Do you understand and agree to comply?</v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_format.analysis_compliant"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <span
          v-if="
            service_format.analysis_provided ||
            service_format.analysis_compliant
          "
        >
          <v-row :class="indent">
            <v-col>
              <v-label
                >Which forms of analysis does your service provide?</v-label
              >
            </v-col>
          </v-row>
          <v-row :class="indent">
            <v-col>
              <v-select
                label="Select all that apply"
                v-model="service_format.analysis_types"
                :items="analysisValues"
                multiple
                small-chips
                deletable-chips
                :rules="[rules.selectOne]"
                :disabled="disabled"
              />
            </v-col>
            <v-col>
              <OtherInput
                v-if="doesAnalysisTypesIncludeOther"
                v-model="service_format.analysis_types_other"
                label="Please specify other forms of analysis"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
        </span>
        <v-row>
          <v-col cols="12">
            <v-label>Does the service include video?</v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_format.video_included"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <span v-if="service_format.video_included">
          <v-row :class="indent">
            <v-col>
              <v-label>Is this a video only service?</v-label>
            </v-col>
          </v-row>
          <v-row :class="indent">
            <v-col>
              <YesNoButtonGroup
                v-model="service_format.video_only"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <v-row :class="indent" v-if="service_format.video_only">
            <v-col
              cols="12"
              class="d-print-none"
              :class="{ 'd-none': isPrintPage }"
            >
              <v-alert type="warning"
                >A video only service is strictly that - video ONLY - and does
                not include information or analysis of prospects. However,
                limited text that helps college coaches identify who they are
                watching is permitted. This includes: prospect's name, jersey
                number, position, high school/team name and graduation
                year.</v-alert
              >
            </v-col>
            <v-col cols="12">
              <v-label
                >What information will be provided to label the video?
              </v-label>
            </v-col>
            <v-col>
              <v-select
                label="Select all that apply"
                v-model="service_format.video_content_types"
                :items="videoContentValues"
                multiple
                small-chips
                deletable-chips
                :rules="[rules.selectOne]"
                :disabled="disabled"
              />
            </v-col>
            <v-col>
              <OtherInput
                v-if="doesContentTypesIncludeOther"
                v-model="service_format.video_content_types_other"
                label="Please specify other content"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
        </span>
        <v-row>
          <v-col cols="12">
            <v-label
              >Does your service distribute information/video of prospects in a
              standardized and consistent format?
            </v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_format.standard_and_consistent"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row
          :class="indent"
          v-if="service_format.standard_and_consistent === false"
        >
          <v-col cols="12">
            <v-alert type="warning"
              >Recruiting/scouting services are required to provide information
              regarding each prospect in a standardized format that ensures
              consistent distribution to all subscribers. Please confirm your
              understanding of this requirement and submit a description of how
              you will comply.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_format.standard_and_consistent_desc"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-label
              >Are there any interactions where information is provided to
              college coaches beyond the recruiting/scouting service reports or
              online data (e.g., text messages, phone calls, in-person
              conversations, etc.)?
            </v-label>
          </v-col>
          <v-col cols="12">
            <YesNoButtonGroup
              v-model="service_format.additional_coach_info"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <v-row :class="indent" v-if="service_format.additional_coach_info">
          <v-col cols="12">
            <v-alert type="warning"
              >It is permissible for NCAA coaches to communicate with
              recruiting/scouting services to clarify information that is
              included in the subscription. However, it is not permissible for
              these services to provide oral/electronic reports about prospects
              beyond the standardized, consistent information provided to all
              subscribers.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-label
              >Please explain how you will comply with this requirement.
            </v-label>
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="service_format.additional_coach_content"
              label=""
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <div v-if="service.year >= 2023">
          <v-row>
            <v-col cols="12">
              <v-label
                >Will this service provide information about NCAA
                Student-Athletes that are transferring?
              </v-label>
            </v-col>
            <v-col>
              <YesNoButtonGroup
                v-model="service_format.sa_transfer"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <v-row :class="indent" v-if="service_format.sa_transfer">
            <v-col cols="12">
              <v-label
                >How does your service obtain the names of the athletes that are
                listed in the NCAA transfer portal?
              </v-label>
            </v-col>
            <v-col cols="12">
              <TextAreaInput
                v-model="service_format.sa_transfer_desc"
                label=""
                :disabled="disabled"
              />
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import OtherInput from "../form/OtherInput.vue";
import TextAreaInput from "../form/TextAreaInput.vue";
import YesNoButtonGroup from "../form/YesNoButtonGroup.vue";
import { cloneDeep, includes, isEqual } from "lodash";
import {
  createServiceFormat,
  updateServiceFormat,
} from "../../api/serviceFormat.api";
import HttpStatus from "http-status";
import { mapActions, mapState } from "vuex";
import { printPageName } from "../../utils/helpers";
import { required, selectOne } from "../../utils/rules";

export default {
  name: "Format",
  components: {
    OtherInput,
    TextAreaInput,
    YesNoButtonGroup,
  },
  props: {
    infoToProspectValues: {
      type: Array,
      default: () => [
        { text: "Online", value: "online" },
        { text: "Paper", value: "paper" },
        { text: "Email", value: "email" },
        { text: "Other", value: "other" },
      ],
    },
    analysisValues: {
      type: Array,
      default: () => [
        { text: "Academic Information", value: "academic_info" },
        { text: "Athletic Evaluations", value: "athletic_eval" },
        { text: "GPA", value: "gpa" },
        {
          text: "Measurements (vertical leap, wingspan, 40-yard dash, etc.)",
          value: "measurements",
        },
        { text: "Other", value: "other" },
      ],
    },
    videoContentValues: {
      type: Array,
      default: () => [
        { text: "Prospect's Name", value: "prospect_name" },
        { text: "Jersey Number", value: "jersey_number" },
        { text: "Position", value: "position" },
        {
          text: "High School / Team Name",
          value: "school_team_name",
        },
        { text: "Graduation Year", value: "grad_year" },
        { text: "Other", value: "other" },
      ],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFormValid: true,
      showPassword: false,
      service_format: {
        id: null,
        service_id: null,
        info_to_prospects_type: "",
        info_to_prospects_other: "",
        registration_required: null,
        registration_username: "",
        registration_password: "",
        analysis_provided: null,
        analysis_compliant: null,
        analysis_types: [],
        analysis_types_other: "",
        video_included: null,
        video_only: null,
        video_content_types: [],
        video_content_types_other: "",
        standard_and_consistent: null,
        standard_and_consistent_desc: "",
        additional_coach_info: null,
        additional_coach_content: "",
        sa_transfer: null,
        sa_transfer_desc: "",
      },
      rules: {
        required,
        selectOne,
      },
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    indent() {
      return "ml-4";
    },
    doesAnalysisTypesIncludeOther() {
      return includes(this.service_format.analysis_types, "other");
    },
    doesContentTypesIncludeOther() {
      return includes(this.service_format.video_content_types, "other");
    },
    isDirty() {
      return (
        this.service_format.id &&
        !isEqual(this.service_format, this.service.service_format)
      );
    },
    isNew() {
      return (
        this.service_format.id === null || this.service_format.id === undefined
      );
    },
    isPrintPage() {
      return this.$router.currentRoute.name === printPageName;
    },
  },
  methods: {
    ...mapActions("service", ["setServiceFormat"]),
    initialize() {
      if (!this.service_format.id) {
        this.reset();
      }
    },
    reset() {
      this.service_format = cloneDeep(this.service.service_format);
      this.$refs.form.resetValidation();
    },
    isValid() {
      return this.$refs.form.validate();
    },
    save() {
      this.preSaveClean();
      if (this.isValid()) {
        this.service_format.service_id = this.service.id;
        this.$loading();
        if (this.service_format.id) {
          return this.update();
        } else {
          return this.create();
        }
      }
    },
    create() {
      return createServiceFormat(this.service_format)
        .then((response) => {
          if (response.status === HttpStatus.CREATED) {
            // Update vuex store
            this.setServiceFormat(response.data);
            this.service_format = cloneDeep(response.data);
            this.$notify.success("Service Format form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    update() {
      return updateServiceFormat(this.service_format)
        .then((response) => {
          if (response.status === HttpStatus.OK) {
            // Update vuex store
            this.setServiceFormat(response.data);
            this.service_format = cloneDeep(response.data);
            this.$notify.success("Service Format form saved successfully.");
            return true;
          } else {
            return false;
          }
        })
        .finally(() => this.$hideLoading());
    },
    preSaveClean() {
      if (!this.service_format.registration_required) {
        this.service_format.registration_username = null;
        this.service_format.registration_password = null;
      }

      if (this.service_format.analysis_provided) {
        this.service_format.analysis_compliant = null;
      } else {
        if (this.service_format.analysis_compliant === false) {
          this.service_format.analysis_types = [];
        }
      }

      if (!this.service_format.video_included) {
        this.service_format.video_only = null;
        this.service_format.video_content_types = [];
      } else {
        if (this.service_format.video_content_types === false) {
          this.service_format.video_content_types = [];
        }
      }

      if (this.service_format.standard_and_consistent) {
        this.service_format.standard_and_consistent_desc = null;
      }

      if (this.service_format.additional_coach_info === false) {
        this.service_format.additional_coach_content = null;
      }

      if (this.service_format.sa_transfer === false) {
        this.service_format.sa_transfer_desc = null;
      }
    },
  },
  watch: {
    "service_format.info_to_prospects_type"(val) {
      if (val !== "other") {
        // clear other response
        this.service_format.info_to_prospects_other = null;
      }
    },
    "service_format.analysis_types"(val) {
      if (!includes(val, "other")) {
        // clear other response
        this.service_format.analysis_types_other = null;
      }
    },
    "service_format.video_content_types"(val) {
      if (!includes(val, "other")) {
        // clear other response
        this.service_format.video_content_types_other = null;
      }
    },
  },
};
</script>
