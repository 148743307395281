<template>
  <v-text-field
    :disabled="disabled"
    :label="label"
    :maxlength="maxlength"
    :rules="[rules.required]"
    :value="localValue"
    @input="bindInput($event)"
  />
</template>

<script>
import { required } from "../../utils/rules";

export default {
  name: "OtherInput",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Please specify"
    },
    maxlength: {
      type: Number,
      default: 100
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      rules: {
        required
      }
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    bindInput(event) {
      this.localValue = event;
    }
  }
};
</script>
