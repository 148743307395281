<template>
  <v-card flat tile>
    <v-card-title class="print-title" v-show="showTitle"
      >Submission</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-label
              >Please read and agree to the following statements in order to
              submit the application:</v-label
            >
          </v-col>
        </v-row>
        <v-input
          :value="localService.selected_attestations"
          :rules="attestationsValid"
        >
          <v-list flat>
            <v-list-item-group
              v-model="localService.selected_attestations"
              multiple
            >
              <template v-for="attestation in attestations">
                <v-list-item
                  :key="attestation.id"
                  :value="attestation.id"
                  :disabled="disabled"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        :true-value="attestation.id"
                        color="primary"
                        :disabled="disabled"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="attestation.description"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-input>
        <v-row>
          <v-col cols="12">
            <v-label
              >Anything else that you want the NCAA to consider when processing
              your application?</v-label
            >
          </v-col>
          <v-col cols="12">
            <TextAreaInput
              v-model="localService.submitter_comment"
              :disabled="disabled"
              :required="false"
            ></TextAreaInput>
          </v-col>
        </v-row>
        <v-alert type="info">
          By entering text in the Name field below, you are signing this
          application electronically and agreeing to all the Attestation
          statements in the previous section. Your electronic signature is the
          legal equivalent of your manual/handwritten signature and by signing,
          you give your consent to the terms and conditions of this application
          and of the ECAG policies and procedures.
        </v-alert>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="localService.submitter_signature"
              :rules="[rules.required]"
              label="Name"
              :disabled="disabled"
              maxlength="100"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <Datepicker
              v-model="signatureDate"
              label="Date"
              disabled
              :show-hint="false"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "../../utils/rules";
import Datepicker from "../form/Datepicker.vue";
import HttpStatus from "http-status";
import { submitService } from "../../api/services.api";
import TextAreaInput from "../form/TextAreaInput.vue";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: { Datepicker, TextAreaInput },
  data() {
    return {
      localService: {
        submitter_comment: "",
        submitter_signature: "",
        submitter_sig_date: "",
        selected_attestations: [],
      },
      rules: {
        required,
      },
      isFormValid: true,
    };
  },
  computed: {
    ...mapGetters("listOfValues", ["activeAttestations"]),
    ...mapState("service", ["service"]),
    isDirty() {
      return (
        this.localService.id &&
        (!isEqual(
          this.localService.submitter_comment,
          this.service.submitter_comment
        ) ||
          !isEqual(
            this.localService.submitter_signature,
            this.service.submitter_signature
          ) ||
          !isEqual(
            this.localService.selected_attestations,
            this.service.selected_attestations
          ))
      );
    },
    attestations() {
      // If app is editable by servicer, show current active attestations. Otherwise, show previous selections.
      return this.service.status_id === 1
        ? this.activeAttestations
        : this.savedAttestations;
    },
    savedAttestations() {
      return this.$store.getters["listOfValues/savedAttestations"](
        this.service.selected_attestations || []
      );
    },
    attestationsValid() {
      return [
        this.localService.selected_attestations.length ===
          this.attestations.length ||
          "Please check that you agree with all statements before submitting the application.",
      ];
    },
    signatureDate() {
      return this.localService.submitter_sig_date || moment().format();
    },
  },
  methods: {
    initialize() {
      if (!this.localService.id) {
        this.localService = cloneDeep(this.service);
        this.$refs.form.resetValidation();
      }
    },
    isValid() {
      return this.$refs.form.validate() && this.attestationsValid;
    },
    submit() {
      if (this.isValid()) {
        this.$loading();
        return submitService(this.localService)
          .then((response) => this.processSaveResponse(response, HttpStatus.OK))
          .finally(() => this.$hideLoading());
      }
    },
    processSaveResponse(response, successStatus) {
      if (response.status === successStatus) {
        // Update vuex store
        this.$notify.success("Application submitted successfully.");
        this.$router.push({ name: "applications" });
      }
    },
  },
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  white-space: normal;
}
</style>
