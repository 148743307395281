<template>
  <v-card flat tile outlined>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>Approved Services</v-toolbar-title>
      <v-progress-linear
        :active="yearLoading"
        :indeterminate="yearLoading"
        absolute
        bottom
        color="grey accent-4"
      ></v-progress-linear>
    </v-toolbar>
    <v-card-text>
      <template v-for="(year, index) in years">
        <ApprovedTable :year="year" :key="year.id" :loading="loading" />
        <v-divider
          class="mb-2"
          v-if="index + 1 < years.length"
          :key="'d_' + index"
        ></v-divider>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ApprovedTable from "./ApprovedTable.vue";

export default {
  name: "MemberHome",
  components: { ApprovedTable },
  data() {
    return {
      loading: false,
      yearLoading: false
    };
  },
  computed: {
    ...mapState("service", ["years"])
  },
  methods: {
    ...mapActions("service", ["fetchYears", "fetchApprovedServices"])
  },
  async mounted() {
    this.loading = true;
    this.yearLoading = true;
    await this.fetchYears().then(years => {
      this.yearLoading = false;
    });
    await this.fetchApprovedServices().then(services => {
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
