import httpClient from "./httpClient";

const END_POINT = "/services";
const ASSOCIATES_END_POINT = "associates";

const getServiceAssociateTypes = () =>
  httpClient.get("/service_associate_types");

const getServiceAssociates = (serviceId, typeId) =>
  httpClient.get(
    `${END_POINT}/${serviceId}/${ASSOCIATES_END_POINT}?type_id=${typeId}`
  );

const createServiceAssociate = data => {
  const payload = {
    service_associate: data
  };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${ASSOCIATES_END_POINT}`,
    payload
  );
};

const updateServiceAssociate = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_associate: form };
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${ASSOCIATES_END_POINT}/${data.id}`,
    payload
  );
};

const deleteServiceAssociate = (id, serviceId) =>
  httpClient.delete(`${END_POINT}/${serviceId}/${ASSOCIATES_END_POINT}/${id}`);

export {
  getServiceAssociateTypes,
  getServiceAssociates,
  createServiceAssociate,
  updateServiceAssociate,
  deleteServiceAssociate
};
