<template>
  <header>
    <v-row class="my-2">
      <v-col class="logo-container" cols="3" sm="2" md="1">
        <img
          src="https://web2.ncaa.org/ncaa_style/img/All_Logos/sm/NCAA_logo_sm.png"
          alt="NCAA"
        />
      </v-col>
      <v-col class="appTitle" cols="8" sm="6">
        Recruiting/Scouting Services Portal
      </v-col>
      <v-spacer class="hidden-sm-and-down" />
      <v-col
        v-if="loggedIn"
        class="text-md-right hidden-sm-and-down"
        cols="8"
        sm="3"
      >
        <div class="userName">{{ user.name }}</div>
        <div v-if="user.org_name" class="instName">{{ user.org_name }}</div>
        <div class="userLinks mr-n2 d-print-none">
          <v-btn-toggle dense borderless>
            <v-btn
              v-show="user.is_servicer"
              small
              text
              :to="{ name: 'profile' }"
            >
              Profile
            </v-btn>
            <v-btn small text :to="{ name: 'logout' }">
              Log Out
            </v-btn>
            <v-btn
              small
              text
              :href="linkUrlByName(linkNames.ECAG_FAQ)"
              target="_blank"
            >
              <v-icon small class="mr-1">far fa-question-circle</v-icon> Help
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
      <v-col
        v-if="loggedIn && (user.is_membership || user.is_staff)"
        align-self="center"
        sm="1"
        class="institutionLogo hidden-sm-and-down d-print-none"
        :class="{ 'staff-logo': user.is_staff }"
      >
        <img
          :src="
            user.is_staff
              ? ncaaLogo
              : user.org_logo_url
          "
          alt="Logo"
        />
      </v-col>
      <v-col v-if="!loggedIn" class="userLinks text-right d-print-none">
        <v-btn-toggle dense borderless>
          <v-btn small text :to="{ name: 'login' }">
            Login
          </v-btn>
          <v-btn small text :to="{ name: 'register' }">
            Register
          </v-btn>
          <v-btn
            small
            text
            :href="linkUrlByName(linkNames.ECAG_FAQ)"
            target="_blank"
          >
            <v-icon small class="mr-1">far fa-question-circle</v-icon> Help
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { linkNames } from "../../utils/helpers";
import ncaaLogo from "../../images/NCAA_flat_sm.png";
export default {
  name: "Header",
  data() {
    return {
      linkNames: linkNames,
      ncaaLogo,
    };
  },
  computed: {
    ...mapState("user", {
      user: state => state.user
    }),
    ...mapGetters("listOfValues", ["linkUrlByName"]),
    ...mapGetters("user", ["loggedIn"])
  }
};
</script>

<style scoped>
.staff-logo {
  margin-bottom: 2em;
}
.userLinks a {
  text-decoration: none;
}
</style>
