<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon class="d-print-none" :color="color" dark v-on="on">{{
        icon
      }}</v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default: "fa-info-circle"
    }
  }
};
</script>

<style scoped>
.v-icon.v-icon {
  padding: 0 5px;
}
</style>
