import httpClient from "./httpClient";

const END_POINT = "/services";
const FORMAT_END_POINT = "staff";

const getServiceStaff = serviceId =>
  httpClient.get(`${END_POINT}/${serviceId}/${FORMAT_END_POINT}`);

const createServiceStaff = data => {
  const payload = {
    service_staff: data
  };
  return httpClient.post(
    `${END_POINT}/${data.service_id}/${FORMAT_END_POINT}`,
    payload
  );
};

const updateServiceStaff = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { service_staff: form };
  return httpClient.put(
    `${END_POINT}/${data.service_id}/${FORMAT_END_POINT}`,
    payload
  );
};

export { getServiceStaff, createServiceStaff, updateServiceStaff };
