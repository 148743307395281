import httpClient from "./httpClient";

const END_POINT = "/inbox/messages";

const getMessages = serviceId => httpClient.get(`${END_POINT}`);

const createMessage = data => {
  const payload = {
    inbox_message: data
  };
  return httpClient.post(`${END_POINT}`, payload);
};

const updateMessage = data => {
  const { id, ...form } = data; // remove id from form object
  const payload = { inbox_message: form };
  return httpClient.put(`${END_POINT}/${id}`, payload);
};

const toggleRead = id => {
  return httpClient.post(`${END_POINT}/${id}/read`);
};

export { getMessages, createMessage, updateMessage, toggleRead };
