import Notification from "./Notification.vue";
import { assign, get, isEmpty } from "lodash";

const ICONS = {
  success: "fa-check-circle",
  error: "fa-exclamation-circle",
  info: "fa-info-circle",
  warning: "fa-exclamation-triangle",
};

function init(Vue, globalOptions = {}) {
  let cmp = null;
  const queue = [];
  const property = globalOptions.property || "$notify";

  function createCmp(options) {
    const component = new Vue(Notification);
    const componentOptions = {
      ...Vue.prototype[property].globalOptions,
      ...options,
    };

    if (componentOptions.slot) {
      component.$slots.default = componentOptions.slot;
      delete componentOptions.slot;
    }

    Object.assign(component, componentOptions);
    document.body.appendChild(component.$mount().$el);

    return component;
  }

  function show(message, options = {}) {
    // default message, show as informational
    if (get(options, "icon") === undefined) {
      assign(options, { icon: ICONS.info });
    }

    if (cmp) {
      const isQueueable =
        options.queueable !== undefined
          ? options.queueable
          : globalOptions.queueable;

      if (isQueueable) {
        queue.push({ message, options });
      } else {
        cmp.close();
        queue.unshift({ message, options });
      }

      return;
    }

    options.message = message;
    cmp = createCmp(options);
    cmp.$on("statusChange", (isActive, wasActive) => {
      if (wasActive && !isActive) {
        cmp.$nextTick(() => {
          cmp.$destroy();
          cmp = null;

          if (queue.length) {
            const notification = queue.shift();
            show(notification.message, notification.options);
          }
        });
      }
    });
  }

  function shorts(options) {
    const colors = ["success", "info", "error", "warning"];
    const methods = {};

    colors.forEach((color) => {
      methods[color] = (message, options) => {
        // initialize options if undefined
        if (isEmpty(options)) {
          options = {};
        }

        switch (color) {
          case "success":
            assign(options, { icon: ICONS.success });
            break;
          case "error":
            assign(options, { icon: ICONS.error });
            assign(options, { showClose: true });
            assign(options, { timeout: -1 });
            break;
          case "warning":
            assign(options, { icon: ICONS.warning });
            break;
          default:
            assign(options, { icon: ICONS.info });
        }

        show(message, { color, ...options });
      };
    });

    if (options.shorts) {
      for (const key in options.shorts) {
        const localOptions = options.shorts[key];
        methods[key] = (message, options) =>
          show(message, { ...localOptions, ...options });
      }
    }

    return methods;
  }

  function getCmp() {
    return cmp;
  }

  function clearQueue() {
    return queue.splice(0, queue.length);
  }

  Vue.prototype[property] = Object.assign(show, {
    globalOptions,
    getCmp,
    clearQueue,
    ...shorts(globalOptions),
  });
}

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(init);
}

export default init;
