import moment from "moment";

export const printPageName = "applicationPrint";
export const convertArraysToStrings = dataArray => dataArray.join(",");

// Clear all children array records. Sets records that have already been
// saved to the database to _destroy: true
export const clearChildren = items => {
  let i = items.length;
  while (i--) {
    if (items[i].id) {
      items[i]._destroy = true;
    } else {
      items.splice(i, 1);
    }
  }
};

// Filters out destroyed records to see if any valid records exist.
// Can be used for validation of children array records.
export const itemsNotDestroyed = items => {
  return items.filter(item => {
    return item._destroy === undefined || !item._destroy;
  });
};

export const acceptTypes =
  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, .txt, text/csv, application/pdf, image/*, application/vnd.apple.keynote, application/vnd.apple.pages, application/vnd.apple.numbers";

export const loadingTextForTable = "Loading... Please wait";
export const noMatchingRecordsText = "No matching records found";
export const sports = {
  MENS_FOOTBALL: "mfb",
  MENS_BASKETBALL: "mbb",
  WOMENS_BASKETBALL: "wbb"
};

export const formatDate = date => {
  if (!date) return null;

  return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
};

export const formatDateTime = date => {
  if (!date) return null;

  return moment(date, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY h:mm:ss a");
};

export const formatCurrency = value => {
  if (value === null) return "";

  return "$" + value.toLocaleString("en-US");
};

// These values should match what is found in /app/helpers/links_helper.rb
export const linkNames = {
  ECAG_FAQ: "ecag_faq",
  EMAIL_CONTACT_FORM: "email_contact_form",
  SCOUTING_SERVICES_APPROVAL: "scouting_services_approval"
};

export const status = {
  PENDING: 1,
  INFO_REQUESTED: 6,
  APPLICANT_RESPONDED: 7,
  STAFF_EDIT: 9,
  CHANGE_REQUEST: 8,
  CHANGE_REQUEST_APPROVED: 20
};

export const paymentStatus = {
  PENDING: "Pending",
  APPROVED: "Approved"
};

export const INBOX_STAFF_ID = -100;
