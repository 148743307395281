<template>
  <v-app class="background">
    <v-main>
      <v-container>
        <Loading />
        <Impersonate />
        <Header />
        <Nav />
        <router-view />

        <PaymentConfirm />
      </v-container>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Loading from "./components/layout/Loading.vue";
import Nav from "../frontend/components/layout/Nav.vue";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import store from "./store/store";
import PaymentConfirm from "./components/PaymentConfirm.vue";
import Impersonate from "./components/layout/Impersonate.vue";

export default {
  name: "app",
  store,
  components: {
    Loading,
    Nav,
    Header,
    Footer,
    PaymentConfirm,
    Impersonate
  },
  methods: {
    ...mapActions("listOfValues", ["fetchCommonLinks"]),
    ...mapActions("user", ["fetchUser"])
  },
  async mounted() {
    await this.fetchCommonLinks();
    await this.fetchUser();
  }
};
</script>

<style lang="scss">
@import "nprogress/nprogress";
@import url("stylesheets/ncaa.scss");
</style>
